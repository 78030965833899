import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

import { FormItem } from '../../components/FormItem';
import { EMPTY_PLACEHOLDER } from '../../constants';
import { useFormContext } from '../../context/FormsContext';
import styles from './Hearings.module.css';

export const judgeSchema = z.object({
    firstName: z.string().min(3, 'Required'),
    lastName: z.string().min(3, 'Required'),
    title: z.string().optional(),
});

export type JudgeType = z.infer<typeof judgeSchema>;

interface JudgeProps {
    initialValues?: JudgeType;
    onJudge: (judge?: JudgeType) => void;
    editMode?: boolean;
}

export const Judge = ({ initialValues, editMode, onJudge }: JudgeProps) => {
    const { onSubmitListener } = useFormContext();
    const {
        control,
        formState: { errors },
        watch,
        handleSubmit,
    } = useForm<JudgeType>({
        resolver: zodResolver(judgeSchema),
        defaultValues: initialValues,
    });

    useEffect(() => {
        return onSubmitListener('hearings', () => {
            return new Promise((resolve) => {
                const validateFunc = () => {
                    resolve({ type: 'validate', result: true });
                };
                handleSubmit(validateFunc, validateFunc)();
            });
        });
    }, []);

    useEffect(() => {
        const { unsubscribe } = watch((data) => {
            const result = judgeSchema.safeParse(data);

            result.success ? onJudge(result.data) : onJudge();
        });

        return unsubscribe;
    }, []);

    return (
        <>
            <Controller
                name="title"
                control={control}
                render={({ field }) => (
                    <FormItem
                        errorMessage={errors.title?.message}
                        className={styles.row}
                        editMode={editMode}
                        label="Judge Title"
                        value={field.value}
                        renderValue={(value) => value || EMPTY_PLACEHOLDER}
                        onChange={(value) => {
                            field.onChange({
                                target: { value },
                            });
                        }}
                    />
                )}
            />
            <Controller
                name="firstName"
                control={control}
                render={({ field }) => (
                    <FormItem
                        errorMessage={errors?.firstName?.message}
                        className={styles.row}
                        editMode={editMode}
                        label="Judge First Name"
                        value={field.value}
                        renderValue={(value) => value || EMPTY_PLACEHOLDER}
                        onChange={(value) => {
                            field.onChange({
                                target: { value },
                            });
                        }}
                    />
                )}
            />
            <Controller
                name="lastName"
                control={control}
                render={({ field }) => (
                    <FormItem
                        errorMessage={errors?.lastName?.message}
                        className={styles.row}
                        editMode={editMode}
                        label="Judge Last Name"
                        value={field.value}
                        renderValue={(value) => value || EMPTY_PLACEHOLDER}
                        onChange={(value) => {
                            field.onChange({
                                target: { value },
                            });
                        }}
                    />
                )}
            />
        </>
    );
};
