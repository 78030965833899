import { CASE_FORMS } from '../../../../../constants/localStorageConstants';
import { deeplyReplaceValues } from '../../../../../helpers';
import { stringifyDate } from '../../../../../helpers/datetime';
import { PartialRecord } from '../../../../../types';
import { courtCaseMapper } from '../sections/CourtCaseForm/mapper';
import { defendantsMapper } from '../sections/Defendants/mapper';
import { hearingsMapper } from '../sections/Hearings/mapper';
import { seizuresMapper } from '../sections/Seizures/mapper';
import { SectionFormInfo, SectionKey } from './types';

const getLocalStorageKey = (id: string) => {
    return `${CASE_FORMS}-${id}`;
};

export const saveCaseForm = (id: string, sectionKey: SectionKey, payload: any) => {
    let data = getCaseForms(id);
    if (!data) {
        localStorage.setItem(
            getLocalStorageKey(id),
            JSON.stringify({
                [sectionKey]: { status: 'saved', payload },
            })
        );
        return;
    }
    data[sectionKey] = { status: 'saved', payload };
    localStorage.setItem(getLocalStorageKey(id), JSON.stringify(data));
};

export const removeAll = (id: string) => {
    localStorage.removeItem(getLocalStorageKey(id));
};

export const removeCaseForm = (id: string, sectionKey: SectionKey) => {
    let data = getCaseForms(id);
    if (!data) {
        return;
    }
    delete data[sectionKey];
    localStorage.setItem(getLocalStorageKey(id), JSON.stringify(data));
};

export const getCaseForms = (id: string): PartialRecord<SectionKey, SectionFormInfo> | undefined => {
    const data = localStorage.getItem(getLocalStorageKey(id));

    if (!data) {
        return;
    }

    try {
        // @ts-ignore
        const parsedData = JSON.parse(data) as PartialRecord<SectionKey, SectionFormInfo>;
        // @ts-ignore
        parsedData['court-case'] = {
            ...parsedData['court-case'],
            payload: courtCaseMapper(parsedData['court-case']?.payload),
        };
        // @ts-ignore
        parsedData['defendants'] = {
            ...parsedData['defendants'],
            payload: defendantsMapper(parsedData['defendants']?.payload),
        };
        // @ts-ignore
        parsedData['seizures'] = {
            ...parsedData['seizures'],
            payload: seizuresMapper(parsedData['seizures']?.payload),
        };
        // @ts-ignore
        parsedData['hearings'] = {
            ...parsedData['hearings'],
            payload: hearingsMapper(parsedData['hearings']?.payload),
        };

        return parsedData;
    } catch {
        return;
    }
};

export const mergeCaseForms = (
    formsA: PartialRecord<SectionKey, SectionFormInfo>,
    formsB?: PartialRecord<SectionKey, SectionFormInfo>
) => {
    if (!formsB) {
        return formsA;
    }

    const result = { ...formsA };
    Object.entries(formsB).forEach(([key, value]) => {
        result[key] = {
            ...formsA[key],
            ...value,
        };
    });

    return result;
};

export const formatAllDates = (data?: any) => {
    if (!data) {
        return;
    }

    return deeplyReplaceValues(data, null, (currentValue: any) => {
        return currentValue instanceof Date ? stringifyDate(currentValue) : currentValue;
    });
};
