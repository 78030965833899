import { Box, Typography } from '@mui/material';

import { BailEvent } from '../../../../../types/BailEvent';
import styles from '../../../CourtCaseDialog.module.css';
import { BailEventItem } from './BailEventItem';

interface BailComponentParts {
    baileventSet: BailEvent[];
}

export const BailComponent = ({ baileventSet }: BailComponentParts) => {
    return (
        <>
            <Box className={styles.defendantsItem}>
                <div className={styles.defendantsHeader}>
                    <Typography className={styles.defendantsTableText} variant="body1">
                        Bail Events
                    </Typography>
                    <div className={styles.defendantsHeaderLine} />
                </div>
                {baileventSet.map((item: BailEvent, index: number) => (
                    <BailEventItem item={item} key={index} />
                ))}
            </Box>
        </>
    );
};
