import { format, parse } from 'date-fns';

export const stringifyDate = (date?: Date) => {
    if (date?.toString() === 'Invalid Date') {
        return format(new Date(), 'yyyy-MM-dd');
    }

    return format(date || new Date(), 'yyyy-MM-dd');
};

export const parseDate = (dateString?: string) => {
    if (!dateString) {
        return;
    }

    return parse(dateString, 'yyyy-MM-dd', new Date());
};
