import { includes, indexOf } from 'lodash';
import { useCallback } from 'react';

export type ColorAccessorProps = {
    chartId: string;
    hoverSeries: string | null;
    selectedSeries: string[];
    groupAccessor?: (key: string) => string;
};

export default function useColorAccessor({
    chartId,
    hoverSeries,
    selectedSeries,
    groupAccessor = (key: string) => key,
}: ColorAccessorProps) {
    const colorAccessor = useCallback(
        (key: string) => {
            const group = groupAccessor(key);
            let fill =
                selectedSeries.length > 0 ? `url(#${chartId}-gradient-blur)` : `url(#${chartId}-gradient-default)`;
            if (includes(selectedSeries, group)) {
                fill = `url(#${chartId}-gradient-active-${indexOf(selectedSeries, group)})`;
            }
            if (hoverSeries === group) {
                fill = `url(#${chartId}-gradient-hover)`;
            }
            return fill;
        },
        [hoverSeries, selectedSeries, chartId]
    );

    return colorAccessor;
}
