import { Text, View } from '@react-pdf/renderer';

import { IndictmentCharge } from '../../../../../types';
import { formatNullOrBoolean } from '../../../index';
import { getWidthByXs } from '../../index';
import { styles } from '../../styles';

interface IndictmentComponentProps {
    indictmentCharges: IndictmentCharge[];
}

export const IndictmentComponent = ({ indictmentCharges }: IndictmentComponentProps) => {
    return (
        <View style={styles.defendantsItem}>
            <View style={styles.defendantsHeader}>
                <View style={styles.defendantsTableTextBlock}>
                    <Text style={styles.defendantsTableText}>Charges</Text>
                </View>
                <View style={styles.defendantsHeaderLine} />
            </View>
            {indictmentCharges.map((item: IndictmentCharge, index: number) => {
                const { guiltyPlea, proposedFine } = item;
                return (
                    <View
                        key={index}
                        style={[
                            styles.rowsBlockWrapper,
                            index + 1 === indictmentCharges.length ? styles.rowsBlockWrapperWithoutBorder : {},
                        ]}
                    >
                        <View style={[styles.tableRow, styles.tableRowWithoutBorder]}>
                            <View style={[styles.cellItem, { width: `${getWidthByXs(3)}%` }]}>
                                <Text style={[styles.cellText, styles.contentHeaderText]}>Legislation</Text>
                                <Text style={[styles.cellText, styles.cellTextValue]}>Legislation</Text>
                            </View>
                            <View style={[styles.cellItem, { width: `${getWidthByXs(3)}%` }]}>
                                <Text style={[styles.cellText, styles.contentHeaderText]}>Plea</Text>
                                <Text style={[styles.cellText, styles.cellTextValue]}>
                                    {formatNullOrBoolean(guiltyPlea, 'Guilty', 'Not Guilty')}
                                </Text>
                            </View>
                            <View style={[styles.cellItem, { width: `${getWidthByXs(3)}%` }]}>
                                <Text style={[styles.cellText, styles.contentHeaderText]}>Date of Outcome</Text>
                                <Text style={[styles.cellText, styles.cellTextValue]}>--</Text>
                            </View>
                            <View style={[styles.cellItem, { width: `${getWidthByXs(3)}%` }]}>
                                <Text style={[styles.cellText, styles.contentHeaderText]}>Custodial sentence</Text>
                                <Text style={[styles.cellText, styles.cellTextValue]}>--</Text>
                            </View>
                        </View>
                        <View style={[styles.tableRow, styles.tableRowWithoutBorder]}>
                            <View style={[styles.cellItem, { width: `${getWidthByXs(3)}%` }]}>
                                <Text style={[styles.cellText, styles.contentHeaderText]}>Fine</Text>
                                <Text style={[styles.cellText, styles.cellTextValue]}>
                                    {`${proposedFine ? proposedFine + 'MWK' : '--'}`}
                                </Text>
                            </View>
                            <View style={[styles.cellItem, { width: `${getWidthByXs(3)}%` }]}>
                                <Text style={[styles.cellText, styles.contentHeaderText]}>Community service</Text>
                                <Text style={[styles.cellText, styles.cellTextValue]}>--</Text>
                            </View>
                            <View style={[styles.cellItem, { width: `${getWidthByXs(3)}%` }]}>
                                <Text style={[styles.cellText, styles.contentHeaderText]}>Suspended sentence</Text>
                                <Text style={[styles.cellText, styles.cellTextValue]}>--</Text>
                            </View>
                            <View style={[styles.cellItem, { width: `${getWidthByXs(3)}%` }]}>
                                <Text style={[styles.cellText, styles.contentHeaderText]}>Run</Text>
                                <Text style={[styles.cellText, styles.cellTextValue]}>--</Text>
                            </View>
                        </View>
                    </View>
                );
            })}
        </View>
    );
};
