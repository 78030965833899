import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import {
    Box,
    List,
    ListItemButton,
    ListItemText,
    Pagination as MuiPagination,
    PaginationItem,
    PaginationRenderItemParams,
    Popover,
    Typography,
} from '@mui/material';
import {
    gridPageCountSelector,
    gridPageSelector,
    gridPageSizeSelector,
    gridRowCountSelector,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid-premium';
import cn from 'classnames';
import React from 'react';

import styles from './Table.module.css';

const pageSizes = [5, 10, 25, 50, 100];

export const Pagination = () => {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
        <Box className={styles.paginationContainer} display="flex" justifyContent="center" padding={2}>
            <MuiPagination
                className={styles.pagination}
                showFirstButton
                showLastButton
                color="primary"
                count={pageCount}
                page={page + 1}
                onChange={(event, value) => apiRef.current.setPage(value - 1)}
                renderItem={(item) =>
                    item.type === 'page' ? (
                        <PageComponent {...item} />
                    ) : (
                        <PaginationItem slots={{ last: SkipNextIcon, first: SkipPreviousIcon }} {...item} />
                    )
                }
            />
        </Box>
    );
};

export const TablePagination = () => {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
    const total = useGridSelector(apiRef, gridRowCountSelector);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'page-size-popover' : undefined;

    const changePageSize = (pageSize: number) => {
        return () => {
            handleClose();
            apiRef.current.setPageSize(pageSize);
        };
    };

    return (
        <Box
            className={styles.paginationContainer}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            padding={2}
        >
            <Typography className={styles.paginationInfoText}>
                Showing {page * pageSize + 1} - {page * pageSize + pageSize} of{' '}
                {new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(total)}
            </Typography>
            <MuiPagination
                className={styles.pagination}
                showFirstButton
                showLastButton
                color="primary"
                count={pageCount}
                page={page + 1}
                onChange={(event, value) => apiRef.current.setPage(value - 1)}
                renderItem={(item) =>
                    item.type === 'page' ? (
                        <PageComponent {...item} />
                    ) : (
                        <PaginationItem slots={{ last: SkipNextIcon, first: SkipPreviousIcon }} {...item} />
                    )
                }
            />
            <Box display="flex" alignItems="center" className={styles.paginationInfo}>
                <Typography className={styles.paginationInfoText}>Show</Typography>
                <button onClick={handleClick} className={cn(styles.pageSizeButton, styles.paginationInfoText)}>
                    <ArrowDropDownIcon />
                    <span>{pageSize}</span>
                </button>
                <Typography className={styles.paginationInfoText}>Results</Typography>
            </Box>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <List>
                    {pageSizes.map((size) => (
                        <ListItemButton selected={size === pageSize} onClick={changePageSize(size)} key={size}>
                            <ListItemText primary={`${size}`} />
                        </ListItemButton>
                    ))}
                </List>
            </Popover>
        </Box>
    );
};

const PageComponent = ({ selected, disabled, onClick, page }: PaginationRenderItemParams) => {
    return (
        <button
            disabled={disabled}
            className={cn(styles.pageButton, selected && styles.selectedPageButton)}
            onClick={onClick}
        >
            {page}
        </button>
    );
};
