import { gql } from '@apollo/client';

const CREATE_ORGANIZATION = gql`
    mutation createOrganization($organization: OrganizationInput!) {
        createOrganization(organization: $organization) {
            organization {
                id
                name
                country {
                    id
                    name
                }
            }
        }
    }
`;

export default CREATE_ORGANIZATION;
