import { LinearGradient } from '@visx/gradient';

import { Palette } from '../types';

const defaultPalette: Palette = {
    background: 'transparent',
    text: '#757575',
    grid: '#ddd',
    axes: '#757575',
    marks: {
        default: '#122945',
        blur: '#e9edf2',
        hover: '#f8d6d5',
        active: ['#122945', '#f75151', '#3bbc97', '#a0b9d0', '#414042'],
    },
};

export default function Gradients({ chartId, palette = defaultPalette }: { chartId: string; palette?: Palette }) {
    const TO_OPACITY = 0.64;

    if (typeof palette.marks === 'string') {
        return <LinearGradient id={chartId + '-gradient-default'} from={palette.marks} to={palette.marks} />;
    } else {
        const { marks } = palette;

        return (
            <>
                <LinearGradient
                    id={chartId + '-gradient-default'}
                    from={marks.default}
                    to={marks.default}
                    toOpacity={TO_OPACITY}
                />
                <LinearGradient
                    id={chartId + '-gradient-blur'}
                    from={marks.blur}
                    to={marks.blur}
                    toOpacity={TO_OPACITY}
                />
                <LinearGradient
                    id={chartId + '-gradient-hover'}
                    to={marks.hover}
                    from={marks.hover}
                    toOpacity={TO_OPACITY}
                />
                {typeof marks.active === 'string' ? (
                    <LinearGradient
                        id={chartId + '-gradient-active-0'}
                        from={marks.active}
                        to={marks.active}
                        toOpacity={TO_OPACITY}
                    />
                ) : (
                    marks.active.map((hex: string, i: number) => (
                        <LinearGradient
                            key={i}
                            id={chartId + `-gradient-active-${i}`}
                            from={hex}
                            to={hex}
                            toOpacity={TO_OPACITY}
                        />
                    ))
                )}
            </>
        );
    }
}
