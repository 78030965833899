import { Box, Stack, Typography, styled } from '@mui/material';

import MapIcon from '../../static/icons/svg/filled/africa.svg';
import ChartIcon from '../../static/icons/svg/filled/capacity building.svg';

const StyledBox = styled(Box)(({ theme }) => ({
    height: 360,
    width: 360,
    cursor: 'pointer',
    padding: theme.spacing(4),
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.dark,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(4),
}));

const IconBox = styled(Box)(({ theme }) => ({
    height: 144,
    width: 144,
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.background.default,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

export type VisualizationFieldsProps = {
    visualization: string | undefined;
    handleVisualization: (value: string) => void;
};

export default function VisualizationFields({ visualization, handleVisualization }: VisualizationFieldsProps) {
    return (
        <Stack spacing={2} direction="row" justifyContent="center">
            <StyledBox
                onClick={() => handleVisualization('CHART')}
                style={visualization === 'CHART' ? { border: '2px solid' } : { border: '2px solid transparent' }}
            >
                <IconBox>
                    <img src={ChartIcon} width={80} height="auto" alt="chat icon" />
                </IconBox>
                <Box sx={{ width: '100%' }}>
                    <Typography variant="h5">Chart</Typography>
                </Box>
            </StyledBox>
            <StyledBox
                onClick={() => handleVisualization('MAP')}
                style={visualization === 'MAP' ? { border: '2px solid' } : { border: '2px solid transparent' }}
            >
                <IconBox>
                    <img src={MapIcon} width={80} height="auto" alt="map icon" />
                </IconBox>
                <Box sx={{ width: '100%' }}>
                    <Typography variant="h5">Map</Typography>
                </Box>
            </StyledBox>
        </Stack>
    );
}
