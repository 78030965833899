import { gql } from '@apollo/client';

const GET_USER = gql`
    query user {
        user {
            id
            email
            name
            firstName
            lastName
            isAdmin
            isSuperuser
            organization {
                id
                name
                country {
                    id
                    name
                    centroid
                    administrativeLevelNames
                    administrativeLevel1Regions {
                        id
                        name
                        type
                        geometry
                    }
                    administrativeLevel2Regions {
                        id
                        name
                        type
                        geometry
                    }
                    regions {
                        id
                        name
                        type
                        geometry
                    }
                    courts {
                        id
                        name
                        tier
                    }
                }
                trackedSpecies {
                    id
                    commonName
                }
                sharedOrganizations {
                    id
                    name
                }
            }
            permissions {
                id
                name
                codename
            }
            availableRoutes @client {
                label
                icon
                href
                disabled
                menuTrigger
                routes {
                    label
                    icon
                    href
                    disabled
                }
            }
        }
    }
`;

export default GET_USER;
