import { Box, Typography } from '@mui/material';

import { IndictmentCharge } from '../../../../../types';
import styles from '../../../CourtCaseDialog.module.css';
import { IndictmentChargeItem } from './IndictmentChargeItem';

interface IndictmentComponentProps {
    indictmentCharges: IndictmentCharge[];
}

export const IndictmentComponent = ({ indictmentCharges }: IndictmentComponentProps) => {
    return (
        <>
            <Box className={styles.defendantsItem}>
                <div className={styles.defendantsHeader}>
                    <Typography className={styles.defendantsTableText} variant="body1">
                        Charges
                    </Typography>
                    <div className={styles.defendantsHeaderLine} />
                </div>
                {indictmentCharges.map((item: IndictmentCharge, index: number) => (
                    <IndictmentChargeItem item={item} key={index} />
                ))}
            </Box>
        </>
    );
};
