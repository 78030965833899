import { Grid } from '@mui/material';
import cn from 'classnames';

import { Loader, LoaderProps } from '../index';
import styles from './AbsouteLoader.module.css';

interface AbsouteLoaderProps extends LoaderProps {
    className?: string;
}

export const AbsoluteLoader = ({ className, size }: AbsouteLoaderProps) => {
    return (
        <Grid
            container
            className={cn(styles.loader, className)}
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <Grid item>
                <Loader size={size} />
            </Grid>
        </Grid>
    );
};
