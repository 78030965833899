import { Button } from '@c4ads/c4blocks';
import { Box, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { removeCaseForm } from '../../context/caseFormUtils';
import { SectionKey } from '../../context/types';
import styles from './FormErrorBoundary.module.css';

interface FormErrorBoundaryProps extends PropsWithChildren {
    id: string;
    sectionKey: SectionKey;
}

export const FormErrorBoundary = ({ id, children, sectionKey }: FormErrorBoundaryProps) => {
    const onClick = () => {
        removeCaseForm(id, sectionKey);
        document.location.reload();
    };

    return (
        <ErrorBoundary
            fallback={
                <Box className={styles.container}>
                    <Typography>Something went wrong.</Typography>
                    <Typography>
                        The form data may have been saved incorrectly. You can try to fix it and click on the{' '}
                        <Button onClick={onClick} color="primary" variant="contained">
                            Clear form data
                        </Button>
                    </Typography>
                </Box>
            }
        >
            {children}
        </ErrorBoundary>
    );
};
