import { Box } from '@mui/material';
import cn from 'classnames';
import React, { useState } from 'react';
import { Control, Controller, FieldErrors, FieldValues, Path } from 'react-hook-form';

import { CURRENCIES } from '../../../../../../constants/local';
import { EMPTY_PLACEHOLDER } from '../../constants';
import { FormItem } from '../FormItem';
import { FormSelect } from '../FormSelect';
import { TextField } from '../TextField';
import styles from './CurrencyValueInput.module.css';

const currencyOptions = Object.entries(CURRENCIES).map(([code]) => ({
    value: code,
    label: code,
}));

interface CurrencyValueInputProps<T extends FieldValues> {
    errors: FieldErrors<T>;
    control: Control<T>;
    editMode?: boolean;
    valueInputConfig: { label: string; name: Path<T>; getErrorMessage: (errors: FieldErrors<T>) => string | undefined };
    currencyInputConfig: { name: Path<T> };
}

export const CurrencyValueInput = <T extends FieldValues>({
    valueInputConfig,
    currencyInputConfig,
    control,
    editMode,
    errors,
}: CurrencyValueInputProps<T>) => {
    const [inputFocused, setInputFocused] = useState(false);
    const [currencyFocused, setCurrencyFocused] = useState(false);

    return (
        <Controller
            name={valueInputConfig.name}
            control={control}
            render={({ field }) => (
                <FormItem
                    errorMessage={valueInputConfig.getErrorMessage(errors)}
                    editMode={editMode}
                    label={valueInputConfig.label}
                    value={field.value}
                    renderValue={(value) => {
                        const valueCurrency = control._formValues[currencyInputConfig.name];

                        if (value && valueCurrency) {
                            return value.toLocaleString('en-US', {
                                style: 'currency',
                                currency: valueCurrency,
                            });
                        }

                        return EMPTY_PLACEHOLDER;
                    }}
                    renderInput={(value) => {
                        return (
                            <Box display="flex" alignItems="center" className={styles.inputContainer}>
                                <div
                                    className={cn(
                                        styles.inputContainerFieldset,
                                        (inputFocused || currencyFocused) && styles.inputContainerFocus
                                    )}
                                />
                                <TextField
                                    onFocus={() => setInputFocused(true)}
                                    onBlur={() => setInputFocused(false)}
                                    className={styles.textField}
                                    inputProps={{
                                        type: 'number',
                                        min: 0,
                                        step: 0.01,
                                        max: 2 ** 32,
                                    }}
                                    errorMessage={valueInputConfig.getErrorMessage(errors)}
                                    value={value}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        field.onChange({
                                            target: {
                                                value: value ? Number(value) : undefined,
                                            },
                                        });
                                    }}
                                />
                                <Controller
                                    name={currencyInputConfig.name}
                                    control={control}
                                    render={({ field }) => (
                                        <FormSelect
                                            onFocus={() => setCurrencyFocused(true)}
                                            onBlur={() => setCurrencyFocused(false)}
                                            className={styles.select}
                                            label={null}
                                            options={currencyOptions}
                                            value={field.value}
                                            onChange={(value) => {
                                                field.onChange({
                                                    target: {
                                                        value,
                                                    },
                                                });
                                            }}
                                        />
                                    )}
                                />
                            </Box>
                        );
                    }}
                />
            )}
        />
    );
};
