import { useQuery } from '@apollo/client';
import { PropsWithChildren, createContext, useContext } from 'react';

import { GET_LOOKUPS } from '../../api/apollo/query';
import { CaseFormsLookupsResponse, Lookups } from '../../types';

interface QueryBuilderContextInterface {
    lookups?: Lookups;
    loading: boolean;
}

const QueryBuilderContext = createContext<QueryBuilderContextInterface>({ loading: true });

export const useQueryBuilderContext = () => useContext(QueryBuilderContext);

export const QueryBuilderProvider = ({ children }: PropsWithChildren) => {
    const { data: lookupsResponse, loading } = useQuery<CaseFormsLookupsResponse>(GET_LOOKUPS);

    return (
        <QueryBuilderContext.Provider value={{ loading, lookups: lookupsResponse?.lookups }}>
            {children}
        </QueryBuilderContext.Provider>
    );
};
