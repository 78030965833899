import { Button } from '@c4ads/c4blocks';
import cn from 'classnames';
import { ReactNode } from 'react';

import styles from './IconButton.module.css';

interface IconButtonProps {
    icon?: ReactNode;
    className?: string;
    onClick?: () => void;
}

export const IconButton = ({ icon, className, onClick }: IconButtonProps) => {
    return (
        <Button className={cn(styles.button, className)} onClick={onClick}>
            {icon}
        </Button>
    );
};
