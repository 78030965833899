import { gql } from '@apollo/client';

const UPDATE_USER_PERMISSION = gql`
    mutation updateUserPermission($user: UserInput!, $codename: String!) {
        updateUserPermission(user: $user, codename: $codename) {
            user {
                email
                isAdmin
                isSuperuser
                permissions {
                    codename
                }
            }
        }
    }
`;

export default UPDATE_USER_PERMISSION;
