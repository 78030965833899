import { SvgIcon } from '@mui/material';

export default function RhinoHornIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="m55.45092997,61.58031154C23.37477498,43.20982625,7.41000639,12.29900519,2.31601031.69476522,1.82383018-.4264324.09660158-.11777239.05124266,1.09912693c-.45559851,12.22289771,1.51847087,44.60258477,31.38098102,71.19030462.33876267.30161319.83440622.38862344,1.26123409.22081697l22.61413942-8.89070093c.90920855-.35745341.98762988-1.5556947.14333278-2.03923605Z" />
            <path d="m61.63275202,28.00334573C43.67619636,20.00180697,34.37426485,6.86472094,30.79664769.68215291c-.65265959-1.12787705-2.35739178-.78690557-2.51022136.50720196-.81562697,6.90644406-.56564448,22.02652881,12.84398674,38.09309408.39814714.47703452,1.08364374.62361522,1.64231762.35164726l18.89019358-9.19593296c1.02301383-.49801324,1.00910847-1.97170838-.03017224-2.43481751Z" />
        </SvgIcon>
    );
}
