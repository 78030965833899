import { ApolloQueryResult } from '@apollo/client';
import { LoginCredentials } from '@c4ads/c4auth';
import { createContext } from 'react';

import { User } from '../../types/user';

export type LoginCallback = (
    res: {
        error: boolean;
        detail: string | null;
        response?: { status: number; data: any };
    },
    userResponse?: Promise<ApolloQueryResult<{ user?: User }>>
) => void;

export interface AuthContextInterface {
    isAuthenticated: boolean;
    isLoading: boolean;
    user: User | undefined;
    login: (data: LoginCredentials, callback?: LoginCallback) => void;
    logout: () => void;
    resetPassword: (email: string) => void;
    signup: (data) => Promise<boolean>;
    updateUser: () => void;
}

const initialAuthContext: AuthContextInterface = {
    isAuthenticated: false,
    isLoading: false,
    user: undefined,
    login: () => {},
    logout: () => {},
    resetPassword: () => {},
    signup: () => Promise.resolve(false),
    updateUser: () => {},
};

const AuthContext = createContext<AuthContextInterface>(initialAuthContext);

AuthContext.displayName = 'AuthContext';

export default AuthContext;
