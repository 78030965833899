import { Box } from '@mui/material';
import cn from 'classnames';
import { PropsWithChildren } from 'react';

import styles from './FormTemplates.module.css';

interface FormRowProps extends PropsWithChildren {
    className?: string;
    adaptive?: boolean;
    gridTemplateColumns?: string;
}

export const FormRow = ({ gridTemplateColumns, className, children, adaptive = true }: FormRowProps) => {
    return (
        <Box
            style={{ gridTemplateColumns }}
            className={cn(adaptive ? styles.formRow : styles.nonAdaptiveFormRow, className)}
        >
            {children}
        </Box>
    );
};

export const FormRowAction = ({ children }: PropsWithChildren) => {
    return <Box className={styles.formRowAction}>{children}</Box>;
};
