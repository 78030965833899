import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { DropDown } from '../../../../../../../components/DropDown';
import { ErrorMessage } from '../../../../../../../components/Messages';
import { AddButton } from '../../../components/AddButton';
import { Label } from '../../../components/FormItem/Label';
import { List } from '../../../components/ListComponents';
import { ChargeComponent, ChargeType } from './Charge';
import styles from './Charges.module.css';

interface ChargesProps {
    defaultValues?: Array<Partial<ChargeType>>;
    editMode?: boolean;
    onChange: (value: Array<Partial<ChargeType> | undefined>) => void;
}

interface ChargeItem {
    key: string;
    value?: Partial<ChargeType>;
}

const prepareCharges = (charges?: Partial<ChargeType>[]) => {
    return charges
        ? charges.map((item) => {
              return item ? { key: item.id ?? uuidv4(), value: item } : { key: uuidv4() };
          })
        : [];
};

export const Charges = ({ defaultValues, editMode, onChange: propOnChange }: ChargesProps) => {
    const [open, setOpen] = useState(false);
    const [chargeItems, setChargeItems] = useState<ChargeItem[]>(prepareCharges(defaultValues));
    const [errors, setErrors] = useState<string[]>([]); // Charges keys

    useEffect(() => {
        propOnChange(chargeItems?.map((item) => item.value) || []);
    }, [chargeItems]);

    const addNew = () => {
        setChargeItems((prev) => prev.concat([{ key: uuidv4() }]));
    };

    const onDelete = (key: string) => {
        return () => {
            setChargeItems((prev) => prev.filter((item) => item.key !== key));
        };
    };

    const onChange = (key: string) => {
        return (value?: Partial<ChargeType>) => {
            setChargeItems((prev) => {
                const cloned = prev.slice();
                const index = cloned.findIndex((item) => item.key === key);

                if (index !== -1) {
                    cloned[index].value = value;
                }
                return cloned;
            });
        };
    };

    const onError = (key: string) => {
        return (error: boolean) => {
            setErrors((prev) => {
                return prev.filter((item) => item !== key).concat(error ? [key] : []);
            });
        };
    };

    const dropdownOnOpen = () => {
        setOpen(true);
    };

    return (
        <DropDown
            open={open}
            titleContainerClassName={styles.titleContainer}
            className={styles.chargesDropdown}
            trigger={(onClick) => (
                <Box display="flex" alignItems="center" justifyContent="space-between" className={styles.trigger}>
                    <Box display="flex" alignItems="center">
                        <Label onClick={onClick} className={styles.triggerLabel} content="Charges" />
                        {!!errors.length && <ErrorMessage marginLeft={8} message="Some of the charges contain error" />}
                    </Box>
                    {editMode && open && <AddButton title="+ Add new" stopPropagation onClick={addNew} />}
                </Box>
            )}
            onActiveItem={() => {
                setOpen(true);
            }}
            onDisabledItem={() => {
                setOpen(false);
            }}
            content={
                <Box display="flex" flexDirection="column">
                    <List>
                        {chargeItems.map((item) => (
                            <ChargeComponent
                                key={item.key}
                                defaultValues={item.value}
                                onDelete={onDelete(item.key)}
                                editMode={editMode}
                                onChange={onChange(item.key)}
                                onError={onError(item.key)}
                                onOpen={dropdownOnOpen}
                            />
                        ))}
                    </List>
                </Box>
            }
        />
    );
};
