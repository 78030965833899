import { useQuery } from '@apollo/client';
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Icon,
    IconButton,
    ListItemTextProps,
    TableCell as MuiTableCell,
    Stack,
    Typography,
    styled,
    tableCellClasses,
} from '@mui/material';
import { pdf } from '@react-pdf/renderer';
import { useState } from 'react';

import GET_COURT_CASE from '../../api/apollo/query/GetCourtCase';
import { downloadBlob } from '../../helpers';
import { CourtCase, Location } from '../../types';
import { FullScreenLoader } from '../Loader/FullScreenLoader';
import styles from './CourtCaseDialog.module.css';
import { CourtCaseDialogPdf } from './CourtCaseDialogPdf';
import { CaseDetailsComponents } from './components/CaseDetails';
import { DefendantsComponent } from './components/Defendants';
import { HearingsComponent } from './components/Hearings';
import { SeizureComponent } from './components/Seizure';

export const TableCell = styled(MuiTableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: theme.palette.primary.main,
        textTransform: 'uppercase',
        fontSize: '.75rem',
    },
    [`&.${tableCellClasses.body}`]: {
        //   fontSize: 14,
    },
}));

export const formatLocationName = (location: Location | null): string => {
    if (location) {
        return `${location?.name ? location.name + ', ' : ''}${
            location?.administrativeLevel1?.name ? location.administrativeLevel1.name : '--'
        }`;
    }
    return ' --';
};

export const formatNullOrBoolean = (value: boolean | null, trueValue: string, falseValue: string): string => {
    if (value === null) {
        return '--';
    }
    return value ? trueValue : falseValue;
};

export const listItemTextProps: Partial<ListItemTextProps> = {
    primaryTypographyProps: {
        variant: 'overline',
        color: 'primary',
    },
    secondaryTypographyProps: {
        variant: 'body1',
    },
};

const CourtCaseDialog = ({
    openCourtCaseId,
    setOpenCourtCaseId,
}: {
    openCourtCaseId: string | null;
    setOpenCourtCaseId: (value: string | null) => void;
}) => {
    const [courtCase, setCourtCase] = useState<CourtCase | null>(null);
    const [pdfRendering, setPdfRendering] = useState<boolean>(false);
    useQuery(GET_COURT_CASE, {
        variables: { id: openCourtCaseId },
        skip: !openCourtCaseId,
        onCompleted: ({ courtCase }) => setCourtCase(courtCase),
    });

    const handleClose = () => {
        setOpenCourtCaseId(null);
        setCourtCase(null);
    };

    const handleDownload = async () => {
        setPdfRendering(true);
        const doc = <CourtCaseDialogPdf courtCase={courtCase} />;

        const blob = await pdf(doc).toBlob();
        downloadBlob(blob, `Court-Case-Report-${new Date().toUTCString()}.pdf`);
        setPdfRendering(false);
    };

    return (
        <Dialog
            className={styles.courtCaseDialog}
            open={Boolean(openCourtCaseId)}
            onClose={handleClose}
            fullWidth
            maxWidth="lg"
            scroll="paper"
        >
            {pdfRendering && <FullScreenLoader />}

            <DialogTitle
                sx={{
                    px: 8,
                    pt: 6,
                    pb: 4,
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <Typography variant="h5" className={styles.title} color="primary">
                    Court Case Record
                </Typography>
                <Box>
                    <IconButton onClick={handleDownload}>
                        <Icon color="primary">download</Icon>
                    </IconButton>
                    <IconButton onClick={handleClose}>
                        <Icon color="primary">close</Icon>
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent
                dividers
                sx={{
                    px: 6,
                    py: 4,
                }}
            >
                <Stack spacing={3} sx={{ minWidth: '1200px' }}>
                    <CaseDetailsComponents courtCase={courtCase} />
                    <Box>
                        <SeizureComponent courtCase={courtCase} />
                    </Box>
                    <Box>
                        <DefendantsComponent courtCase={courtCase} />
                    </Box>
                    <Box>
                        <HearingsComponent courtCase={courtCase} />
                    </Box>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default CourtCaseDialog;
