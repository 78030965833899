import { createContext } from 'react';
import { Layouts } from 'react-grid-layout';

import { ChartConfig } from '../../../types/ChartConfig';
import { DashboardContextType } from '../../../types/DashboardContextType';
import { DashboardConfig } from '../../../types/dashboardConfig';

const DashboardContext = createContext<DashboardContextType>({
    dashboardConfig: { charts: {}, layouts: { lg: [] } as Layouts } as DashboardConfig,
    handleEditConfig: (config: ChartConfig) => {},
    handleUpdateChartConfig: (id: string, newConfig: ChartConfig) => {},
    handleRemoveChart: (id: string) => {},
    onExport: () => {},
});

export default DashboardContext;
