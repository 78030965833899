import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useContext, useEffect, useState } from 'react';

import { CourtCaseFilters } from '../../../../../../types/CourtCaseFilters';
import { AdvancedFilterContext } from '../../AdvancedFilterContext';
import { FilterComponentGeneralInterface } from '../FilterComponent';

const getDefaultValue = (field: string, defaultFiltersValue: CourtCaseFilters) => {
    if (defaultFiltersValue[field] !== undefined) {
        return defaultFiltersValue[field] ? 'true' : 'false';
    } else {
        return 'any';
    }
};

export const BooleanInput = ({ onFilterFunction, field }: FilterComponentGeneralInterface) => {
    const { defaultFiltersValue } = useContext(AdvancedFilterContext);
    const [state, setState] = useState<string>(getDefaultValue(field, defaultFiltersValue));

    useEffect(() => {
        onFilterFunction(field, field, () => {
            if (state === 'any') {
                return {};
            } else {
                return { [field]: state === 'true' };
            }
        });
    }, [state]);

    const handleChange = (event: SelectChangeEvent) => {
        setState(event.target.value as string);
    };

    return (
        <>
            <FormControl size="small">
                <Select value="is">
                    <MenuItem value="is">is</MenuItem>
                </Select>
            </FormControl>
            <FormControl size="small">
                <Select onChange={handleChange} value={state} label="Value">
                    <MenuItem value="any">any</MenuItem>
                    <MenuItem value="true">true</MenuItem>
                    <MenuItem value="false">false</MenuItem>
                </Select>
            </FormControl>
        </>
    );
};
