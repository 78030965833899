import Box from '@mui/material/Box';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Lottie from 'lottie-react';

import styles from './PlaceHolder.module.css';
import animation from './animation.json';

const StyledBox = styled(Box)(({ theme }) => ({
    position: 'absolute',
    left: 'calc(50% - 200px)',
    top: 'calc(50% - 200px - 92px)',
    width: 400,
    height: 400,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: theme.spacing(1),
    color: '#122945',
    opacity: 0.8,
}));

const StyledIcon = styled(Icon)(({ theme }) => ({
    fontSize: 144,
}));

const Placeholder = ({
    title,
    message,
    onClick,
    icon,
}: {
    title: string;
    icon?: string;
    message?: string;
    onClick?: () => void;
}) => (
    <StyledBox
        onClick={onClick}
        sx={{
            cursor: onClick ? 'pointer' : 'default',
            '&:hover': {
                bgcolor: onClick ? 'action.hover' : 'transparent',
            },
        }}
    >
        {icon ? <StyledIcon>{icon}</StyledIcon> : <Lottie animationData={animation} loop={true} autoPlay={true} />}
        <Typography className={styles.title} variant="h5" gutterBottom>
            {title}
        </Typography>
        <Typography className={styles.text}>{message}</Typography>
    </StyledBox>
);

export default Placeholder;
