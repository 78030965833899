import { useQuery } from '@apollo/client';
import { Autocomplete } from '@c4ads/c4blocks';
import { Typography } from '@mui/material';
import { compact, flatMap, uniq } from 'lodash';
import { useCallback, useState } from 'react';
import { Controller } from 'react-hook-form';

import { GET_LOOKUPS } from '../../../../api/apollo/query/GetLookups';
import { Species } from '../../../../types';
import styles from '../../filterFields.module.css';

const UNIT_MAP: { [k: string]: string[] } = {
    accessory: ['Firearm', 'Ammunition', 'Snare', 'Vehicle', 'Knife', 'Axe'],
    plantae: ['Roundwood', 'Logs', 'Planks', 'Charcoal'],
    animalia: ['Live', 'Carcass'],
    mammalia: ['Skin', 'Claw', 'Head', 'Foot', 'Claw', 'Bone', 'Tail', 'Tooth'],
    aves: ['Feather', 'Head', 'Foot', 'Claw', 'Bone', 'Tail'],
    reptilia: ['Scales', 'Head', 'Foot', 'Claw', 'Bone', 'Tail', 'Tooth'],
    actinopterygii: ['Scales'],
    mollusca: ['Shell'],
    elephantidae: ['Ivory - Raw', 'Ivory - Processed'],
    rhinocerotidae: ['Horn'],
    cervidae: ['Horn'],
    pholidota: ['Scales'],
    testudinidae: ['Shell'],
};

type CommodityOptionType = { speciesId: string; unit: string };

export default function CommoditySelect({ user, control }) {
    const [options, setOptions] = useState<CommodityOptionType[]>([]);

    const handleCommodityOptions = (species: Species[]) => {
        const newOptions: CommodityOptionType[] = compact(
            flatMap(species, (s) => {
                return flatMap(Object.values(s), (v) => {
                    if (typeof v === 'string') {
                        return uniq(UNIT_MAP[v.toLowerCase()] || []).map((unit) => ({ speciesId: s.id, unit: unit }));
                    }
                });
            })
        );
        setOptions(newOptions);
    };

    const { data } = useQuery(GET_LOOKUPS, {
        skip: !user,
        onCompleted: (data) => handleCommodityOptions(data?.lookups?.species || []),
    });

    const getOptionLabel = useCallback(
        ({ speciesId, unit }: CommodityOptionType) => {
            if (data) {
                const commonName = data.lookups.species.find(({ id }) => id === speciesId)?.commonName;
                return `${commonName} ${unit}`;
            }
            return '';
        },
        [data]
    );

    const groupByLabel = useCallback(
        (speciesId: string) => {
            if (data) {
                return data.lookups.species.find(({ id }) => id === speciesId)?.commonName;
            }
            return '';
        },
        [data]
    );

    const getOptionId = (option: CommodityOptionType) => `${option.speciesId}-${option.unit}`;

    return (
        <Controller
            name="filters.commodities"
            control={control}
            render={({ field }) => (
                <Autocomplete
                    label={
                        <Typography variant="overline" color="#9e9e9e">
                            Commodities
                        </Typography>
                    }
                    className={styles.autocomplete}
                    placeholder="All"
                    autocompleteProps={{
                        multiple: true,
                        options: options,
                        isOptionEqualToValue: (optionA, optionB) => getOptionId(optionA) === getOptionId(optionB),
                        getOptionLabel: (o) => getOptionLabel(o),
                        getOptionKey: getOptionId,
                        groupBy: (option: CommodityOptionType) => groupByLabel(option.speciesId),
                    }}
                    {...field}
                />
            )}
        />
    );
}
