import { Typography } from '@mui/material';
import cn from 'classnames';
import React, { ReactNode } from 'react';

import styles from './Messages.module.css';

interface ErrorMessageProps {
    message?: ReactNode;
    className?: string;
    marginLeft?: number;
}

export const ErrorMessage = ({ message, className, marginLeft }: ErrorMessageProps) => {
    return (
        <Typography style={{ marginLeft }} className={cn(styles.errorMessage, className)}>
            {message}
        </Typography>
    );
};
