import { FormControl, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import { DateTime } from 'luxon';
import { useContext, useEffect, useState } from 'react';

import { AdvancedFilterContext } from '../../AdvancedFilterContext';
import { FilterComponentGeneralInterface } from '../FilterComponent';

export interface DateComparisonFilterProps extends FilterComponentGeneralInterface {
    // parseValue: (value: string | undefined) => DateTime;
}

const operators = {
    GT: '>',
    GTE: '>=',
    LT: '<',
    LTE: '<=',
};

const parseDefaultDate = (date?: string): DateTime | undefined => {
    if (!date) {
        return;
    }

    const jsDate = new Date(date);
    if (jsDate.toString() === 'Invalid Date') {
        return;
    }

    return DateTime.fromJSDate(jsDate);
};
// eslint-disable-next-line
export const DateInput = <T,>({ onFilterFunction, field }: DateComparisonFilterProps) => {
    const { defaultFiltersValue } = useContext(AdvancedFilterContext);
    // @ts-ignore
    const defaultOperation = defaultFiltersValue?.[field]?.operation;
    // @ts-ignore
    const defaultValue = defaultFiltersValue?.[field]?.value;

    const [state, setState] = useState<string>(defaultOperation || 'GT');
    const [value, setValue] = useState<DateTime | undefined>(parseDefaultDate(defaultValue));

    useEffect(() => {
        onFilterFunction(field, field, () => {
            return {
                [field]: {
                    operation: state,
                    value: value,
                },
            };
        });
    }, [state, value]);

    const handleChange = (event: SelectChangeEvent) => {
        setState(event.target.value as string);
    };

    const onChangeValue = (value: Dayjs | null) => {
        if (value) {
            const result = DateTime.fromJSDate(value.toDate());
            setValue(result);
        } else {
            setValue(undefined);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <FormControl size="small">
                <Select onChange={handleChange} value={state}>
                    {Object.keys(operators).map((key) => (
                        <MenuItem key={key} value={key}>
                            {operators[key]}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl size="small">
                <DatePicker
                    label=""
                    value={value ? dayjs(value.toJSDate()) : null}
                    onChange={(date) => {
                        onChangeValue(date);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                />
            </FormControl>
        </LocalizationProvider>
    );
};
