import React, { useContext, useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useOutletContext } from 'react-router';

import { User } from '../../../../types/user';
import { AccountContext } from '../../AccountContext';
import { AccountOutletContextInterface } from '../../index';

export interface SuperUserOutletInterface {
    user: User;
    search: string;
}

export const SuperUser = () => {
    const { user } = useOutletContext<AccountOutletContextInterface>();
    const { setContainerMaxWidth } = useContext(AccountContext);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        setContainerMaxWidth(undefined);

        return () => {
            setContainerMaxWidth('md');
        };
    }, []);

    useEffect(() => {
        if (!(user.isSuperuser || user.isAdmin)) {
            navigate('/account', { replace: true });
        }

        if (location.pathname === '/account/groups' && !user.isSuperuser) {
            navigate('/account', { replace: true });
        }
    }, [user, location]);

    return <Outlet context={{ user }} />;
};
