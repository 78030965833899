import { Box, Container, Typography } from '@mui/material';
import cn from 'classnames';
import React, { ReactNode, useState } from 'react';
import { Outlet, Route } from 'react-router';

import { useAuth } from '../../api/auth';
import Footer from '../../components/Footer';
import { User } from '../../types/user';
import styles from './Account.module.css';
import { AccountContext } from './AccountContext';
import OrganizationManager from './components/OrganizationManager';
import { ProfileRoute } from './components/Profile';
import { SuperUser } from './components/SuperUser';
import { UsersTable } from './components/SuperUser/Users';

export interface AccountOutletContextInterface {
    user: User;
}

export const Account = () => {
    const { user } = useAuth();
    const [title, setTitle] = useState<string | ReactNode>();
    //eslint-disable-next-line
    const [_containerMaxWidth, setContainerMaxWidth] = useState<'md' | undefined>('md');

    if (user) {
        return (
            <>
                <Container className={styles.container}>
                    <div className={cn(styles.titleWrapper)}>
                        {typeof title === 'string' ? (
                            <Box>
                                <br />
                                <div className={styles.titleContainer}>
                                    <Typography className={styles.title} variant="h4" sx={{ m: 1 }}>
                                        {title}
                                    </Typography>
                                </div>
                            </Box>
                        ) : (
                            title
                        )}
                    </div>
                    <AccountContext.Provider
                        value={{
                            setTitle,
                            setContainerMaxWidth,
                        }}
                    >
                        <div className={styles.accountContentWrapper}>
                            <Outlet context={{ user }} />
                        </div>
                    </AccountContext.Provider>
                </Container>
                <Footer />
            </>
        );
    }

    return null;
};

const AccountRoute = {
    routeProps: {
        path: '/account',
        element: <Account />,
        children: (
            <>
                <Route {...ProfileRoute.routeProps} />
                <Route path="organization" element={<OrganizationManager />} />
                <Route element={<SuperUser />}>
                    <Route path="users" element={<UsersTable />} />
                </Route>
            </>
        ),
    },
    name: 'Account',
};

export default AccountRoute;
