import { gql } from '@apollo/client';

const DELETE_USER = gql`
    mutation deleteUser($user: UserInput!) {
        deleteUser(user: $user) {
            ok
        }
    }
`;

export default DELETE_USER;
