import { useQuery } from '@apollo/client';
import SearchIcon from '@mui/icons-material/Search';
import { Box, TextField, Typography } from '@mui/material';
import { GridSortModel } from '@mui/x-data-grid-premium';
import { debounce } from 'lodash';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { GET_CASE_FORMS } from '../../api/apollo/query';
import { useAuth } from '../../api/auth';
import { CustomTable } from '../../components/CustomTable';
import { CaseFormType } from '../../types';
import styles from './CaseForms.module.css';
import columns from './columns';
import { CaseFormsToolbar } from './components/CaseFormsToolbar';

const CaseForms = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [rows, setRows] = useState<CaseFormType[]>([]);
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'lastEditedAt',
            sort: 'desc',
        },
    ]);

    useEffect(() => {
        if (!user?.permissions?.map((item) => item.codename).includes('case_form_editor')) {
            navigate('/dashboards');
        }
    }, [user]);

    const { data, loading } = useQuery(GET_CASE_FORMS);

    useEffect(() => {
        if (data) {
            setRows(data.caseForms);
        }
    }, [data]);

    const filteredRows = useMemo(() => {
        return rows.filter((row) => {
            const filters = [row.title.toLowerCase().indexOf(search.toLowerCase()) !== -1];

            return filters.filter(Boolean).length > 0;
        });
    }, [rows, search]);

    const onRowClick = (row: CaseFormType) => {
        navigate(`/case-forms/${row.id}`);
    };

    const onSearch = useMemo(() => {
        let searchStr = '';
        const handler = debounce(() => {
            setSearch(searchStr);
        }, 300);

        return (e: ChangeEvent<HTMLInputElement>) => {
            searchStr = e.target.value;
            handler();
        };
    }, []);

    return (
        <Box display="flex" flexDirection="column" className={styles.tableContainer}>
            <Box display="flex" justifyContent="space-between" alignItems="start">
                <Typography className={styles.title}>Case Forms</Typography>
                <CaseFormsToolbar caseForms={rows} />
            </Box>
            <Box display="flex" justifyContent="flex-end">
                <TextField
                    autoFocus
                    placeholder="Search..."
                    onChange={onSearch}
                    className={styles.search}
                    InputProps={{
                        startAdornment: <SearchIcon className={styles.searchIcon} />,
                    }}
                    variant="outlined"
                />
            </Box>
            <CustomTable
                tablePagination
                sortModel={sortModel}
                onSortModelChange={setSortModel}
                columns={columns}
                rows={filteredRows}
                loading={loading}
                pagination={{
                    pageSize: pageSize,
                    page: page,
                    onPage: setPage,
                    onPageSize: setPageSize,
                }}
                onRowClick={onRowClick}
            />
        </Box>
    );
};

const CaseFormsRoute = {
    routeProps: {
        path: '/case-forms',
        element: <CaseForms />,
    },
    name: 'Case Forms',
};

export default CaseFormsRoute;
