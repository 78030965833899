import { Button } from '@c4ads/c4blocks';
import cn from 'classnames';
import React, { ReactNode, useCallback } from 'react';

import styles from './AddButton.module.css';

interface AddButtonProps {
    title: ReactNode;
    onClick?: () => void;
    className?: string;
    stopPropagation?: boolean;
    absolute?: boolean;
}

export const AddButton = ({ onClick, className, title, stopPropagation, absolute }: AddButtonProps) => {
    const currentOnClick = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            if (stopPropagation) {
                e.stopPropagation();
            }

            onClick && onClick();
        },
        [stopPropagation]
    );

    return (
        <Button
            onClick={currentOnClick}
            className={cn(styles.addButton, absolute ? styles.absolute : styles.relative, className)}
        >
            {title}
        </Button>
    );
};
