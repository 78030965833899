import {
    Box,
    FormControl,
    Icon,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';

export type AnnotationLineFieldProps = {
    axis: 'x' | 'y';
    index: number;
    value: number;
    color: string;
    removeLine: (axis: 'x' | 'y', index: number) => void;
    updateLine: (axis: 'x' | 'y', index: number, { value, color }: { value?: number; color?: string }) => void;
};

function AnnotationLineField({ axis, index, value, color, updateLine, removeLine }: AnnotationLineFieldProps) {
    const handleValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (!value) {
            updateLine(axis, index, { value: 0 });
        }

        if (parseFloat(value)) {
            updateLine(axis, index, { value: parseFloat(value) });
        }
    };

    const colorOptions = [
        { value: '#122945', label: 'Dark Blue' },
        { value: '#225B7B', label: 'Blue' },
        { value: '#A0B9D0', label: 'Light Blue' },
        { value: '#B2EDD9', label: 'Light Green' },
        { value: '#3BBC97', label: 'Green' },
        { value: '#EF8479', label: 'Light Red' },
        { value: '#F75151', label: 'Red' },
    ];

    return (
        <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <TextField label="Value" value={value} onChange={handleValue} />
            <FormControl>
                <InputLabel id="color-select-label">Color</InputLabel>
                <Select
                    labelId="color-select-label"
                    label="Color"
                    size="small"
                    value={color}
                    onChange={(e) => updateLine(axis, index, { color: e.target.value })}
                    sx={{
                        '& .MuiInputBase-input': {
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                        },
                    }}
                >
                    {colorOptions.map(({ label, value }) => (
                        <MenuItem key={value} value={value} sx={{ display: 'flex', gap: 1 }}>
                            <Box sx={{ height: 16, width: 16, bgcolor: value, borderRadius: 1 }} />
                            {label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <IconButton size="small" onClick={() => removeLine(axis, index)}>
                <Icon>clear</Icon>
            </IconButton>
        </Box>
    );
}

export default function AnnotationFields({ visualization, control, setValue, renderValue, watch }) {
    const selectedXLines = watch('xLines');
    const selectedYLines = watch('yLines');

    const addLine = (axis: 'x' | 'y') => {
        const prevLines = axis === 'x' ? selectedXLines : selectedYLines;
        const newLines = [...(prevLines || []), { value: 0, color: '#122945' }];
        setValue(`${axis}Lines`, newLines);
    };

    const updateLine = (axis: 'x' | 'y', index: number, data: { value?: number; color?: string }) => {
        const prevLines = axis === 'x' ? selectedXLines : selectedYLines;
        const newLines = prevLines.map((l, i) => {
            return i === index ? { ...l, ...data } : l;
        });
        setValue(`${axis}Lines`, newLines);
    };

    const removeLine = (axis: 'x' | 'y', index: number) => {
        const prevLines = axis === 'x' ? selectedXLines : selectedYLines;
        const newLines = prevLines.filter((l, i) => i !== index);
        setValue(`${axis}Lines`, newLines);
    };

    return (
        <>
            <Controller
                name="xLines"
                control={control}
                render={({ field }) => (
                    <FormControl>
                        <Stack gap={1}>
                            <Stack direction="row" alignItems="center" justifyContent="space-between">
                                <Typography variant="overline" color="#9e9e9e">
                                    Horizontal Lines
                                </Typography>
                                <IconButton onClick={() => addLine('x')}>
                                    <Icon>add</Icon>
                                </IconButton>
                            </Stack>
                            <Stack gap={2}>
                                {selectedXLines?.map((line, i) => (
                                    <AnnotationLineField
                                        axis="x"
                                        index={i}
                                        {...line}
                                        removeLine={removeLine}
                                        updateLine={updateLine}
                                    />
                                ))}
                            </Stack>
                        </Stack>
                    </FormControl>
                )}
            />
        </>
    );
}
