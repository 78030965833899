import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { CaseFormType, Lookups, PartialRecord } from '../../../../types';
import { CaseFormHearingsPresent } from '../../../../types/CaseFormHearingsPresent';
import { defaultLookups } from './context/constants';
import { SectionFormInfo, SectionKey } from './context/types';

type Mode = 'edit' | 'saved' | 'sync';
type Id = string;

interface AutoSaveInterface {
    autoSaved: boolean;
    active?: boolean;
}

type SectionFormsStatusStateInterface = PartialRecord<SectionKey, { isValid: boolean }>;

const FORMS_AUTO_SAVE = 'formsAutosave';

interface FormsStateInterface {
    sectionForms: PartialRecord<SectionKey, SectionFormInfo>;
    sectionFormsStatusTrigger: boolean;
    sectionFormsStatusState: SectionFormsStatusStateInterface;
    sectionFormsInited?: string;
    localStateIsDifferent: boolean;
    isLoading: boolean;

    lookups: Lookups;
    lookupsIsLoading: boolean;
    hearings: CaseFormHearingsPresent[];
    hearingsLoading: boolean;

    editMode: Mode;
    originalSectionForms: PartialRecord<SectionKey, SectionFormInfo>;
    caseForm?: CaseFormType;
    autoSaved: AutoSaveInterface;
    sectionFormsToRemove: PartialRecord<SectionKey, Id[]>;

    updateSectionFormsStatusState: () => void;
    setOriginalSectionForms: (originalSectionForms: PartialRecord<SectionKey, SectionFormInfo>) => void;
    setSectionFormsStatusState: (sectionKey: SectionKey, isValid: boolean) => void;
    setSectionForms: (id: string, sectionForms: PartialRecord<SectionKey, SectionFormInfo>) => void;
    setSectionForm: (sectionKey: SectionKey, payload: any) => void;
    setLocalStateIsDifferent: (isDifferent: boolean) => void;
    setIsLoading: (isLoading: boolean) => void;

    setLookups: (params: { lookups?: Lookups; lookupsIsLoading?: boolean }) => void;
    setHearings: (params: { hearings?: CaseFormHearingsPresent[]; hearingsLoading?: boolean }) => void;

    setEditMode: (editMode: Mode) => void;
    setCaseForm: (caseForm: CaseFormType) => void;
    setAutoSave: (autoSaved: boolean) => void;
    toggleAutoSave: (active: boolean) => void;
    setSectionFormToRemove: (sectionKey: SectionKey, formId: Id) => void;
}

export const useFormsStore = create<FormsStateInterface>()(
    immer((set) => ({
        sectionForms: {},
        sectionFormsStatusState: {
            'court-case': { isValid: true },
            defendants: { isValid: true },
            seizures: { isValid: true },
            hearings: { isValid: true },
        },
        originalSectionForms: {},
        localStateIsDifferent: false,
        isLoading: false,
        sectionFormsStatusTrigger: false,

        lookups: defaultLookups,
        lookupsIsLoading: true,
        hearings: [],
        hearingsLoading: true,

        editMode: 'sync',
        autoSaved: {
            autoSaved: false,
            active: false, // localStorage.getItem(FORMS_AUTO_SAVE) === 'true',
        },
        sectionFormsToRemove: {},

        setOriginalSectionForms: (originalSectionForms) => {
            set((state) => {
                state.originalSectionForms = originalSectionForms;
            });
        },
        updateSectionFormsStatusState: () => {
            set((state) => {
                state.sectionFormsStatusState = { ...state.sectionFormsStatusState };
                state.sectionFormsStatusTrigger = !state.sectionFormsStatusTrigger;
            });
        },
        setSectionFormsStatusState: (sectionKey, isValid) => {
            set((state) => {
                state.sectionFormsStatusState[sectionKey] = { isValid };
            });
        },
        setSectionForms: (id, sectionForms) => {
            set((state) => {
                state.sectionForms = sectionForms;
                state.sectionFormsInited = id;
            });
        },
        setSectionForm: (sectionKey: SectionKey, payload: any) => {
            set((state) => {
                state.sectionForms[sectionKey] = {
                    status: 'editing',
                    payload,
                };
            });
        },
        setLocalStateIsDifferent: (isDifferent) => {
            set((state) => {
                state.localStateIsDifferent = isDifferent;
            });
        },
        setIsLoading: (isLoading) => {
            set((state) => {
                state.isLoading = isLoading;
            });
        },

        setLookups: ({ lookupsIsLoading, lookups }) => {
            set((state) => {
                state.lookupsIsLoading = lookupsIsLoading ?? state.lookupsIsLoading;
                state.lookups = lookups ?? state.lookups;
            });
        },
        setHearings: ({ hearingsLoading, hearings }) => {
            set((state) => {
                state.hearingsLoading = hearingsLoading ?? state.hearingsLoading;
                state.hearings = hearings ?? state.hearings;
            });
        },

        setEditMode: (editMode) => {
            set((state) => {
                state.editMode = editMode;
            });
        },
        setCaseForm: (caseForm) => {
            set((state) => {
                state.caseForm = caseForm;
            });
        },
        toggleAutoSave: (active) => {
            set((state) => {
                localStorage.setItem(FORMS_AUTO_SAVE, `${active}`);

                state.autoSaved = {
                    ...state.autoSaved,
                    active,
                };
            });
        },
        setAutoSave: (autoSaved: boolean) => {
            set((state) => {
                state.autoSaved = {
                    ...state.autoSaved,
                    autoSaved,
                };
            });
        },
        setSectionFormToRemove: (sectionKey: SectionKey, formId: string) => {
            set((state) => {
                let array = state.sectionFormsToRemove[sectionKey] || [];
                array = array.filter((item) => item !== sectionKey).concat([formId]);

                state.sectionFormsToRemove[sectionKey] = array;
            });
        },
    }))
);
