import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import cn from 'classnames';
import React, { ReactNode, useEffect, useState } from 'react';

import { ErrorMessage } from '../Messages';
import styles from './ControlledAccordion.module.css';

type AccordionItemTitle = ReactNode | ((onClick: () => void) => ReactNode);

interface AccordionItem<T> {
    key: T;
    title: AccordionItemTitle;
    id?: string;
    extraRightComponent?: ReactNode;
    content?: ReactNode;
}

export interface ControlledAccordionProps<T> {
    items: AccordionItem<T>[];
    activeItem?: T;
    activeItems?: T[];
    onActiveItem?: (item: T) => void;
    onDisabledItem?: (item: T) => void;
    expandIcon?: ReactNode;
    expandIconPosition?: 'right' | 'left';
    hideUnderline?: boolean;
    className?: string;
    summaryClassName?: string;
    titleContainerClassName?: string;
    detailsClassName?: string;
    errors?: { key: string; errorMessage: string }[];
}

export const ControlledAccordion = <T,>({
    className,
    summaryClassName,
    detailsClassName,
    items,
    onActiveItem,
    onDisabledItem,
    activeItem,
    activeItems,
    expandIcon,
    expandIconPosition = 'right',
    hideUnderline,
    titleContainerClassName,
    errors,
}: ControlledAccordionProps<T>) => {
    const [currentActiveItems, setCurrentActiveItems] = useState<T[]>(activeItems || []);
    const [currentActiveItem, setCurrentActiveItem] = useState<T | undefined>(activeItem);

    useEffect(() => {
        setCurrentActiveItems(activeItems || []);
    }, [activeItems]);

    useEffect(() => {
        setCurrentActiveItem(activeItem);
    }, [activeItem]);

    const handleChange = (key: T) => {
        const expanded = currentActiveItems.includes(key);
        const newActiveItem = expanded ? undefined : key;

        setCurrentActiveItem(newActiveItem);
        setCurrentActiveItems(
            expanded ? currentActiveItems.filter((item) => item !== key) : currentActiveItems.concat([key])
        );
        expanded ? onDisabledItem?.(key) : onActiveItem?.(key);
    };

    const getErrorMessage = (key: string) => {
        const error = errors?.find((error) => error.key === key);
        return error?.errorMessage;
    };

    const isActive = (key: T) => {
        return key === currentActiveItem || currentActiveItems.includes(key);
    };

    const getAccordionItemTitle = (accordionItem: AccordionItem<T>) => {
        let titleComponent: ReactNode | null;
        switch (typeof accordionItem.title) {
            case 'string':
                titleComponent = (
                    <Typography onClick={() => handleChange(accordionItem.key)} className={styles.accordionTitle}>
                        {accordionItem.title}
                    </Typography>
                );
                break;
            case 'object':
                titleComponent = accordionItem.title;
                break;
            case 'function':
                titleComponent = accordionItem.title(() => handleChange(accordionItem.key));
                break;
            default:
                titleComponent = null;
        }

        return titleComponent;
    };

    return (
        <Box className={className}>
            {items.map((accordionItem) => (
                <Accordion
                    key={accordionItem.key as string}
                    expanded={isActive(accordionItem.key)}
                    className={styles.accordion}
                >
                    <AccordionSummary
                        id={accordionItem.id}
                        className={cn(styles.accordionSummary, summaryClassName)}
                        expandIcon={null}
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            className={styles.titleWrapper}
                        >
                            <Box
                                display="flex"
                                alignItems="center"
                                className={cn(styles.titleContainer, titleContainerClassName)}
                            >
                                {expandIconPosition === 'left' && (
                                    <Box
                                        onClick={() => handleChange(accordionItem.key)}
                                        className={cn(
                                            styles.expandIconContainer,
                                            isActive(accordionItem.key) && styles.expandIconContainerOpen
                                        )}
                                    >
                                        {expandIcon || <ExpandMoreIcon />}
                                    </Box>
                                )}
                                {getAccordionItemTitle(accordionItem)}
                                {expandIconPosition === 'right' && (
                                    <Box
                                        onClick={() => handleChange(accordionItem.key)}
                                        className={cn(
                                            styles.expandIconContainer,
                                            isActive(accordionItem.key) && styles.expandIconContainerOpen
                                        )}
                                    >
                                        {expandIcon || <ExpandMoreIcon />}
                                    </Box>
                                )}
                                {getErrorMessage(accordionItem.key as string) && (
                                    <ErrorMessage message={getErrorMessage(accordionItem.key as string)} />
                                )}
                                {!hideUnderline && <span className={styles.accordionTitleUnderline} />}
                            </Box>
                            {accordionItem.extraRightComponent}
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails className={cn(styles.accordionDetails, detailsClassName)}>
                        {accordionItem.content}
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    );
};
