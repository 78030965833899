import { Box, Typography } from '@mui/material';

import { Sentence } from '../../../../../types';
import styles from '../../../CourtCaseDialog.module.css';
import { SentenceItem } from './SentenceItem';

interface SentenceComponentProps {
    sentenceSet: Sentence[];
}

export const SentenceComponent = ({ sentenceSet }: SentenceComponentProps) => {
    return (
        <Box className={styles.defendantsItem}>
            <div className={styles.defendantsHeader}>
                <Typography className={styles.defendantsTableText} variant="body1">
                    Sentence
                </Typography>
                <div className={styles.defendantsHeaderLine} />
            </div>
            {sentenceSet.map((item: Sentence, index: number) => (
                <SentenceItem item={item} key={index} />
            ))}
        </Box>
    );
};
