import { styled } from '@mui/material/styles';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { Suspense } from 'react';

import ChartRenderer from '../ChartRenderer';
import { ChartProps } from '../types';

const StyledDiv = styled('div')(({ theme }) => ({
    height: 'calc(100% - 32px)',
}));

const ChartContent = (chartProps: ChartProps) => {
    return (
        <Suspense fallback="Loading charts...">
            <StyledDiv id="chart-content">
                <ParentSize>
                    {({ width, height }) => <ChartRenderer {...chartProps} width={width} height={height} />}
                </ParentSize>
            </StyledDiv>
        </Suspense>
    );
};

export default ChartContent;
