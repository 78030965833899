import { zodResolver } from '@hookform/resolvers/zod';
import React, { memo, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

import { CustomRadioGroup } from '../../../../../../../components/RadioGroup';
import { formatDate } from '../../../../utils';
import { CurrencyValueInput } from '../../../components/CurrencyValueInput';
import { DeleteButton } from '../../../components/DeleteButton';
import { FormCheckbox } from '../../../components/FormCheckbox';
import { FormDatePicker } from '../../../components/FormDatePicker';
import { FormItem } from '../../../components/FormItem';
import { FormRow, FormRowAction } from '../../../components/FormTemplates/FormRow';
import { useFormContext } from '../../../context/FormsContext';
import { useFormsStore } from '../../../store';

export const bailEventSchema = z.object({
    id: z.coerce.string().optional(),
    date: z.coerce.date(),
    bondAmount: z
        .number()
        .min(0)
        .max(2 ** 32)
        .nullable(),
    bondAmountCurrency: z.string(),
    bailGranted: z.boolean(),
    absconded: z.boolean(),
});

export type BailEventType = z.infer<typeof bailEventSchema>;

interface BailEventComponentProps {
    editMode?: boolean;
    defaultValues?: Partial<BailEventType>;
    onDelete: () => void;
    onChange: (value?: Partial<BailEventType>) => void;
    onError?: (error: boolean) => void;
    onOpen?: () => void;
}

const bailGrantedOptions = [
    { label: 'Granted', value: 'granted' },
    { label: 'Not Granted', value: 'not_granted' },
];

export const BailEventComponent = memo(
    ({ editMode, defaultValues, onDelete, onChange, onError, onOpen }: BailEventComponentProps) => {
        const { onSubmitListener } = useFormContext();
        const { currency } = useFormsStore((state) => state.lookups);
        const {
            control,
            formState: { errors },
            handleSubmit,
            watch,
        } = useForm<z.infer<typeof bailEventSchema>>({
            resolver: zodResolver(bailEventSchema),
            defaultValues: {
                bailGranted: false,
                bondAmountCurrency: defaultValues?.bondAmountCurrency ?? currency,
                ...defaultValues,
            },
        });

        useEffect(() => {
            onError?.(!!Object.keys(errors).length);
        }, [JSON.stringify(errors)]);

        useEffect(() => {
            const { unsubscribe } = watch((data) => {
                onChange(data);
            });

            return unsubscribe;
        }, []);

        useEffect(() => {
            return onSubmitListener('defendants', () => {
                return new Promise((resolve) => {
                    handleSubmit(
                        () => {
                            resolve({ type: 'validate', result: true });
                        },
                        () => {
                            onOpen?.();
                            resolve({ type: 'validate', result: true });
                        }
                    )();
                });
            });
        }, []);

        return (
            <FormRow gridTemplateColumns="repeat(4, 1fr) auto">
                <Controller
                    name="date"
                    control={control}
                    render={({ field }) => (
                        <FormItem
                            errorMessage={errors.date?.message}
                            editMode={editMode}
                            label="Date"
                            value={field.value}
                            renderValue={formatDate}
                            renderInput={(value) => (
                                <FormDatePicker
                                    value={value}
                                    errorMessage={errors.date?.message}
                                    onChange={(value) => {
                                        field.onChange({ target: { value } });
                                    }}
                                />
                            )}
                        />
                    )}
                />
                <CurrencyValueInput
                    control={control}
                    errors={errors}
                    editMode={editMode}
                    valueInputConfig={{
                        label: 'Bond Amount',
                        name: 'bondAmount',
                        getErrorMessage: (errors) => errors.bondAmount?.message,
                    }}
                    currencyInputConfig={{ name: 'bondAmountCurrency' }}
                />
                <Controller
                    name="bailGranted"
                    control={control}
                    render={({ field }) => (
                        <FormItem
                            editMode={editMode}
                            label="Bail Granted"
                            value={field.value}
                            renderValue={(value) => {
                                return value ? bailGrantedOptions[0].label : bailGrantedOptions[1].label;
                            }}
                            renderInput={(value) => {
                                return (
                                    <CustomRadioGroup
                                        value={value ? bailGrantedOptions[0].value : bailGrantedOptions[1].value}
                                        onChange={(value) => {
                                            field.onChange({
                                                target: { value: value === bailGrantedOptions[0].value },
                                            });
                                        }}
                                        options={bailGrantedOptions}
                                    />
                                );
                            }}
                        />
                    )}
                />
                <Controller
                    name="absconded"
                    control={control}
                    render={({ field }) => (
                        <FormItem
                            editMode={editMode}
                            label="Absconded"
                            value={field.value}
                            renderValue={(value) => {
                                return value ? 'Yes' : 'No';
                            }}
                            renderInput={(value) => {
                                return (
                                    <FormCheckbox
                                        value={value ? bailGrantedOptions[0].value : bailGrantedOptions[1].value}
                                        onChange={(value) => {
                                            field.onChange({
                                                target: { value },
                                            });
                                        }}
                                    />
                                );
                            }}
                        />
                    )}
                />

                {editMode && (
                    <FormRowAction>
                        <DeleteButton color="gray" onClick={onDelete} />
                    </FormRowAction>
                )}
            </FormRow>
        );
    },
    /*
        This optimization is possible because the props we pass do not necessarily need to be always up-to-date;
        For such components, initialization or rare update is sufficient
     */
    (prevProps, nextProps) => prevProps.editMode === nextProps.editMode
);
