import { zodResolver } from '@hookform/resolvers/zod';
import { Box } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

import { formatDate } from '../../../utils';
import { FormAutocomplete } from '../../components/Autocomplete';
import { FormCheckbox } from '../../components/FormCheckbox';
import { FormDatePicker } from '../../components/FormDatePicker';
import { FormItem } from '../../components/FormItem';
import { FormRow } from '../../components/FormTemplates/FormRow';
import { EMPTY_PLACEHOLDER } from '../../constants';
import { useFormContext } from '../../context/FormsContext';
import { useFormsStore } from '../../store';
import styles from './CourtCaseForm.module.css';

const schema = z.object({
    id: z.coerce.string().optional(),
    docket: z.string().min(1, {
        message: 'Docket is required field.',
    }),
    name: z.string().min(2, 'Required'),
    startDate: z.coerce.date(),
    endDate: z.coerce.date(),
    isConcluded: z.boolean(),
    courtId: z.coerce.string().min(1, {
        message: 'Court is required field.',
    }),
});

const mockedData = {
    name: '',
    courtId: undefined,
    docket: undefined,
    isConcluded: false,
};

export const CourtCaseForm = () => {
    const { onSubmitListener, setSectionStatus } = useFormContext();
    const payload = useFormsStore((state) => state.sectionForms['court-case']?.payload);
    const courts = useFormsStore((state) => state.lookups.courts);
    const setSectionForm = useFormsStore((state) => state.setSectionForm);
    const contextEditMode = useFormsStore((state) => state.editMode);
    const {
        control,
        formState: { errors, ...data },
        handleSubmit,
        watch,
    } = useForm<z.infer<typeof schema>>({
        resolver: zodResolver(schema),
        defaultValues: payload || mockedData,
    });
    const startDate = watch('startDate');
    const endDate = watch('endDate');

    useEffect(() => {
        const subscription = watch((value) => {
            setSectionForm('court-case', value);
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
        return onSubmitListener('court-case', () => {
            return new Promise((resolve) => {
                handleSubmit(
                    (data) => {
                        resolve({ type: 'payload', result: data });
                    },
                    () => {
                        resolve({ type: 'payload', result: false });
                    }
                )();
            });
        });
    }, []);

    useEffect(() => {
        console.log(errors, data);
        const errorsCount = Object.keys(errors).length;
        setSectionStatus('court-case', errorsCount === 0);
    }, [JSON.stringify(errors)]);

    const editMode = useMemo(() => contextEditMode === 'edit', [contextEditMode]);

    const getCourt = (id: string) => courts.find((item) => item.id === id);

    return (
        <Box display="flex" flexDirection="column" className={styles.wrapper}>
            <FormRow>
                <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                        <FormItem
                            errorMessage={errors.name?.message}
                            className={styles.name}
                            editMode={editMode}
                            label="Name"
                            value={field.value}
                            renderValue={(value) => value || ''}
                            onChange={(value) => {
                                field.onChange({
                                    target: { value },
                                });
                            }}
                        />
                    )}
                />
                <Controller
                    name="courtId"
                    control={control}
                    render={({ field }) => (
                        <FormItem
                            errorMessage={errors.courtId?.message}
                            editMode={editMode}
                            label="Court"
                            value={field.value}
                            onChange={(value) => {
                                field.onChange({
                                    target: { value },
                                });
                            }}
                            renderValue={(value) => getCourt(value)?.name || EMPTY_PLACEHOLDER}
                            renderInput={(value) => (
                                <FormAutocomplete
                                    disableVirtualization
                                    disablePortal={false}
                                    label={null}
                                    errorMessage={errors.courtId?.message}
                                    options={courts}
                                    getOptionLabel={(option) => option.name || EMPTY_PLACEHOLDER}
                                    value={getCourt(value)}
                                    onChange={(_data, value) => {
                                        field.onChange({ target: { value: value?.id } });
                                    }}
                                />
                            )}
                        />
                    )}
                />
                <Controller
                    name="docket"
                    control={control}
                    render={({ field }) => (
                        <FormItem
                            errorMessage={errors.docket?.message}
                            editMode={editMode}
                            label="Case Reference Number"
                            value={field.value}
                            renderValue={(value) => value || ''}
                            onChange={(value) => {
                                field.onChange({
                                    target: { value },
                                });
                            }}
                        />
                    )}
                />
            </FormRow>
            <FormRow>
                <Controller
                    name="startDate"
                    control={control}
                    render={({ field }) => (
                        <FormItem
                            editMode={editMode}
                            errorMessage={errors.startDate?.message}
                            label="Start Date"
                            value={field.value}
                            renderValue={formatDate}
                            renderInput={(value) => (
                                <FormDatePicker
                                    maxDate={endDate}
                                    errorMessage={errors.startDate?.message}
                                    value={value}
                                    onChange={(value) => {
                                        field.onChange({ target: { value } });
                                    }}
                                />
                            )}
                        />
                    )}
                />
                <Controller
                    name="isConcluded"
                    control={control}
                    render={({ field }) => (
                        <FormItem
                            editMode={editMode}
                            label="Case Concluded"
                            value={field.value}
                            renderInput={(value) => (
                                <FormCheckbox
                                    disabled={!editMode}
                                    checked={value}
                                    onChange={(value) => {
                                        field.onChange({ target: { value } });
                                    }}
                                />
                            )}
                            renderValue={(value) => (value ? 'Yes' : 'No')}
                        />
                    )}
                />
                <Controller
                    name="endDate"
                    control={control}
                    render={({ field }) => (
                        <FormItem
                            editMode={editMode}
                            errorMessage={errors.endDate?.message}
                            label="End Date"
                            value={field.value}
                            renderValue={formatDate}
                            renderInput={(value) => (
                                <FormDatePicker
                                    minDate={startDate}
                                    errorMessage={errors.endDate?.message}
                                    value={value}
                                    onChange={(value) => {
                                        field.onChange({ target: { value } });
                                    }}
                                />
                            )}
                        />
                    )}
                />
            </FormRow>
        </Box>
    );
};
