import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import { ChartProps } from '../types';

const ControlBar = styled(Paper)(({ theme }) => ({
    height: theme.spacing(4),
    padding: theme.spacing(0, 1),
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
    borderRadius: theme.spacing(0.5, 0.5, 0, 0),
    cursor: 'grab',
}));

const ActionBox = styled(Box)(({ theme }) => ({
    // padding: theme.spacing(1,2,2),
}));

export type ChartHeaderProps = Pick<ChartProps, 'actions'>;

export default function ChartHeader({ actions }: ChartHeaderProps) {
    return (
        <ControlBar>
            <ActionBox>{actions}</ActionBox>
        </ControlBar>
    );
}
