import { Box, Stack, Typography } from '@mui/material';
import * as _ from 'lodash';
import { useMemo } from 'react';

export default function Legend({ data, axes, width, height, colorMarkup }) {
    const isRate = axes.left.label.endsWith('RATE');

    const rows = useMemo(() => {
        const noData = _.chain(data.features)
            .filter(({ properties }) => !properties.data)
            .map((f) => ({ ...f, properties: { ...f.properties, data: isRate ? '--' : 0 } }))
            .value();

        const all = _.chain(data.features)
            .filter(({ properties }) => properties.data)
            .sortBy('properties.data')
            .reverse()
            .map((f) => ({
                ...f,
                properties: { ...f.properties, data: isRate ? `${f.properties.data.toFixed(1)}%` : f.properties.data },
            }))
            .concat(noData)
            .value();
        return all;
    }, [data, colorMarkup, isRate]);

    return (
        <Stack
            spacing={1}
            sx={{
                width: width,
                maxWidth: 360,
                px: 2,
                height: height,
                overflow: 'auto',
            }}
        >
            {rows.map(({ id, properties }) => (
                <Box key={id} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Box
                            sx={{
                                width: 16,
                                height: 16,
                                border: 'solid',
                                borderRadius: 1,
                                borderWidth: 1,
                                borderColor: colorMarkup[id],
                                bgcolor: colorMarkup[id],
                                flex: 'none',
                            }}
                        />

                        <Typography variant="caption" color="primary">
                            {properties.label}
                        </Typography>
                    </Box>

                    <Typography variant="caption" color="primary">
                        {properties.data}
                    </Typography>
                </Box>
            ))}
        </Stack>
    );
}
