import { z } from 'zod';

import { CASE_FORMS_SECTIONS_STATE } from '../../../../../constants/localStorageConstants';
import { SectionKey } from '../context/types';

interface SectionsStorageInterface {
    lastActiveKey?: SectionKey;
    activeKeys: SectionKey[];
}

const values: readonly [string, ...string[]] = [
    'sentencing',
    'hearings',
    'bail-events',
    'charges',
    'defendants',
    'seizures',
    'court-case',
]; // SectionKey type
const schema = z.object({
    lastActiveKey: z.enum(values).optional(),
    activeKeys: z.enum(values).array(),
});

const getStorageKey = (id: string) => {
    return `${CASE_FORMS_SECTIONS_STATE}-${id}`;
};

const getStorageState = (key: string): SectionsStorageInterface => {
    try {
        const data = JSON.parse(localStorage.getItem(key) ?? '');

        return schema.parse(data) as SectionsStorageInterface;
    } catch {
        return {
            activeKeys: [],
        };
    }
};

export const sectionsStateStorage = (id: string) => {
    const localStorageKey = getStorageKey(id);

    const openSection = (key?: SectionKey) => {
        const state = getStorageState(localStorageKey);

        state.lastActiveKey = key;
        state.activeKeys = state.activeKeys.filter((activeKey) => activeKey !== key).concat(key ? [key] : []);

        localStorage.setItem(localStorageKey, JSON.stringify(state));
    };

    const closeSection = (key?: SectionKey) => {
        const state = getStorageState(localStorageKey);

        if (key && state.lastActiveKey === key) {
            state.lastActiveKey = undefined;
        }
        state.activeKeys = state.activeKeys.filter((activeKey) => activeKey !== key);

        localStorage.setItem(localStorageKey, JSON.stringify(state));
    };

    const closeAllSections = () => {
        const data: SectionsStorageInterface = { activeKeys: [] };
        localStorage.setItem(localStorageKey, JSON.stringify(data));
    };

    const getLastActiveSection = () => {
        const { lastActiveKey } = getStorageState(localStorageKey);

        return lastActiveKey;
    };

    const getActiveSections = () => {
        const { activeKeys } = getStorageState(localStorageKey);

        return activeKeys;
    };

    return {
        openSection,
        closeSection,
        closeAllSections,
        getLastActiveSection,
        getActiveSections,
    };
};
