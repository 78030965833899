import { Avatar, AvatarGroup } from '@c4ads/c4blocks';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { Box, Tooltip } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';

import { ColumnHeaderName } from '../../components/CustomTable/ColumnHeaderName';
import { RowColumn } from '../../components/CustomTable/RowColumn';
import { CaseFormStatuses } from '../../constants/local';
import { tableHelpers } from '../../helpers/tableHelpers';
import { CaseFormType, CaseFormUser } from '../../types';
import styles from './CaseForms.module.css';

interface UserProps {
    user: CaseFormUser;
}

interface StatusProps {
    status: string;
}

const User = ({ user }: UserProps) => {
    const name = [user.firstName, user.lastName].filter(Boolean).join(' ');
    return (
        <AvatarGroup max={1} className={styles.avatarGroup}>
            <Tooltip key={user.email} title={name}>
                <Avatar className={styles.userAvatar}>{name.trim().slice(0, 1)}</Avatar>
            </Tooltip>
        </AvatarGroup>
    );
};

const Status = ({ status }: StatusProps) => {
    return (
        <Box display="flex" alignItems="center" className={styles.status}>
            {status === CaseFormStatuses.Draft && (
                <>
                    <BorderColorOutlinedIcon className={styles.statusIcon} />
                    <RowColumn>Draft</RowColumn>
                </>
            )}
            {status === CaseFormStatuses.Review_Requested && (
                <>
                    <WarningAmberRoundedIcon className={styles.statusIcon} />
                    <RowColumn>Review Requested</RowColumn>
                </>
            )}
            {status === CaseFormStatuses.Changes_Requested && (
                <>
                    <WarningAmberRoundedIcon className={styles.statusIcon} />
                    <RowColumn>Changes Requested</RowColumn>
                </>
            )}
            {status === CaseFormStatuses.Approved && (
                <>
                    <CheckRoundedIcon className={styles.statusIcon} />
                    <RowColumn>Approved</RowColumn>
                </>
            )}
        </Box>
    );
};

const columns: GridColDef<CaseFormType>[] = [
    {
        field: 'status',
        type: 'string',
        headerAlign: 'left',
        align: 'left',
        groupable: false,
        aggregable: false,
        width: 140,
        flex: 1,
        renderHeader: () => <ColumnHeaderName>Status</ColumnHeaderName>,
        renderCell: ({ value }) => {
            const result = value || '';

            return result ? <Status status={result} /> : tableHelpers.renderString(result.replaceAll('_', ' '));
        },
    },
    {
        field: 'title',
        type: 'string',
        headerAlign: 'left',
        align: 'left',
        groupable: false,
        aggregable: false,
        width: 140,
        flex: 1,
        renderHeader: () => <ColumnHeaderName>Title</ColumnHeaderName>,
        renderCell: ({ value }) => {
            return value ? <RowColumn>{value}</RowColumn> : tableHelpers.renderString(value);
        },
    },
    {
        field: 'lastEditedBy',
        type: 'string',
        headerAlign: 'center',
        align: 'center',
        groupable: false,
        aggregable: false,
        width: 200,
        flex: 1,
        valueGetter: ({ row }) => row.lastEditedBy || row.createdBy,
        renderCell: ({ value }) => (value ? <User user={value} /> : '--'),
        renderHeader: () => <ColumnHeaderName>Last Edited By</ColumnHeaderName>,
    },
    {
        field: 'lastEditedAt',
        type: 'date',
        headerAlign: 'center',
        align: 'left',
        groupable: false,
        aggregable: false,
        width: 140,
        flex: 1,
        valueGetter: ({ row }) => row.lastEditedAt,
        valueFormatter: tableHelpers.dateTimeFormatter(),
        renderHeader: () => <ColumnHeaderName>Last Edited At</ColumnHeaderName>,
        renderCell: ({ value }) => <RowColumn>{value}</RowColumn>,
    },
    {
        field: 'lastReviewedBy',
        headerAlign: 'center',
        align: 'center',
        groupable: false,
        aggregable: false,
        width: 140,
        flex: 1,
        valueGetter: ({ row }) => row.lastReviewedBy,
        renderCell: ({ value }) => (value ? <User user={value} /> : '--'),
        renderHeader: () => <ColumnHeaderName>Last Reviewed By</ColumnHeaderName>,
    },
    {
        field: 'lastReviewedAt',
        type: 'date',
        headerAlign: 'center',
        align: 'center',
        groupable: false,
        width: 140,
        flex: 1,
        valueGetter: ({ row }) => row.lastReviewedAt || '--',
        valueFormatter: tableHelpers.dateTimeFormatter(),
        renderHeader: () => <ColumnHeaderName>Last Reviewed At</ColumnHeaderName>,
        renderCell: ({ value }) => <RowColumn>{value}</RowColumn>,
    },
];

export default columns;
