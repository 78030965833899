import { gql } from '@apollo/client';

const UPDATE_USER = gql`
    mutation updateUser($user: UserInput!) {
        updateUser(user: $user) {
            user {
                id
                email
                name
                firstName
                lastName
                isAdmin
                isSuperuser
                organization {
                    id
                    name
                    country {
                        id
                        name
                    }
                }
                permissions {
                    id
                    name
                    codename
                }
            }
        }
    }
`;

export default UPDATE_USER;
