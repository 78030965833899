import { AnimatedBarSeries, AnimatedBarStack } from '@visx/xychart';

import { MarkProps } from '../types';

interface BarStackProps extends MarkProps {
    selectedSeries: string[];
}

export default function BarStack({ data, colorAccessor, selectedSeries }: BarStackProps) {
    return (
        <AnimatedBarStack order="none" enableEvents={true}>
            {data.map(({ key, label, data }: { key: string; label: string; data: any[] }) => (
                <AnimatedBarSeries
                    key={key}
                    data={data}
                    dataKey={label}
                    xAccessor={(d: any) => d.label}
                    yAccessor={(d: any) => d.data}
                    colorAccessor={(d: any) => colorAccessor(label)}
                />
            ))}
        </AnimatedBarStack>
    );
}
