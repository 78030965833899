import { useQuery } from '@apollo/client';
import { DataGrid, Switch } from '@c4ads/c4blocks';
import { Box } from '@mui/material';
import { GridColDef, GridRenderCellParams, useGridApiRef } from '@mui/x-data-grid-premium';

/*
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
*/
import { find } from 'lodash';

import { GET_USERS } from '../../api/apollo/query';
import { User } from '../../types/user';

const PermissionToggleCell = ({ params, codename }: { params: GridRenderCellParams; codename: string }) => {
    /* const apiRef = useGridApiContext(); */
    const checked = find(params.row.permissions, { codename: codename });

    /*
    const togglePermission = (event: React.SyntheticEvent) => {
        apiRef.current.setEditCellValue;
    };
    */

    return <Switch checked={checked} />;
};

const columns: GridColDef[] = [
    {
        field: 'email',
        type: 'string',
        headerName: 'Email',
        headerAlign: 'center',
        align: 'center',
        width: 144,
    },
    {
        field: 'firstName',
        type: 'string',
        headerName: 'First Name',
        headerAlign: 'center',
        align: 'center',
        width: 144,
    },
    {
        field: 'lastName',
        type: 'string',
        headerName: 'Last Name',
        headerAlign: 'center',
        align: 'center',
        width: 144,
    },
    {
        field: 'isAdmin',
        type: 'boolean',
        headerName: 'Admin',
        headerAlign: 'center',
        align: 'center',
        width: 144,
    },
    {
        field: 'viewCourtCases',
        type: 'boolean',
        headerName: 'View Court Cases',
        headerAlign: 'center',
        align: 'center',
        width: 144,
        renderCell: (params) => <PermissionToggleCell params={params} codename="view_courtcase" />,
    },
    {
        field: 'viewDefendants',
        type: 'boolean',
        headerName: 'View Defendants',
        headerAlign: 'center',
        align: 'center',
        width: 144,
        renderCell: (params) => <PermissionToggleCell params={params} codename="view_defendant" />,
    },
    {
        field: 'viewSeizedCommodities',
        type: 'boolean',
        headerName: 'View Seizures',
        headerAlign: 'center',
        align: 'center',
        width: 144,
        renderCell: (params) => <PermissionToggleCell params={params} codename="view_seizure" />,
    },
];

export default function UserTable({ user }: { user: User }) {
    const { data, loading } = useQuery(GET_USERS, { skip: !user.isAdmin });
    const apiRef = useGridApiRef();

    return (
        <Box sx={{ height: 400, width: '100%' }}>
            <DataGrid
                apiRef={apiRef}
                columns={columns}
                rows={data?.users || []}
                checkboxSelection={false}
                loading={loading}
                hideFooterSelectedRowCount
                hideFooterPagination
            />
        </Box>
    );
}
