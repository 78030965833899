import { CircularProgress } from '@mui/material';
import React from 'react';

export interface LoaderProps {
    size?: number;
}

export const Loader = ({ size }: LoaderProps) => {
    return <CircularProgress size={size} />;
};
