import { zodResolver } from '@hookform/resolvers/zod';
import { debounce } from 'lodash';
import React, { memo, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

import { FormAutocomplete } from '../../../components/Autocomplete';
import { DeleteButton } from '../../../components/DeleteButton';
import { FormItem } from '../../../components/FormItem';
import { FormRow, FormRowAction } from '../../../components/FormTemplates/FormRow';
import { DEBOUNCE_WAIT, EMPTY_PLACEHOLDER } from '../../../constants';
import { useFormContext } from '../../../context/FormsContext';
import { useFormsStore } from '../../../store';
import styles from './Officers.module.css';

export type OfficerType = Partial<z.infer<typeof officerSchema>>;

interface OfficerComponentProps {
    editMode?: boolean;
    defaultValues?: OfficerType;
    onDelete: () => void;
    onChange: (value?: OfficerType) => void;
}

export const officerSchema = z.object({
    id: z.coerce.string().optional(),
    firstName: z.string().min(2, 'Required'),
    lastName: z.string().min(2, 'Required'),
    rank: z.string().optional(),
    agencyId: z.coerce.string().optional(),
});

export const OfficerComponent = memo(
    ({ editMode, defaultValues, onDelete, onChange }: OfficerComponentProps) => {
        const { onSubmitListener } = useFormContext();
        const agencies = useFormsStore((state) => state.lookups.agencies);
        const {
            control,
            formState: { errors },
            handleSubmit,
            watch,
        } = useForm<z.infer<typeof officerSchema>>({
            resolver: zodResolver(officerSchema),
            defaultValues,
        });

        useEffect(() => {
            let currentData: OfficerType;
            const onChangeDebounce = debounce(() => {
                onChange(currentData);
            }, DEBOUNCE_WAIT);

            const { unsubscribe } = watch((data) => {
                currentData = data as OfficerType;
                onChangeDebounce();
            });

            return unsubscribe;
        }, []);

        useEffect(() => {
            return onSubmitListener('seizures', () => {
                return new Promise((resolve) => {
                    const validateFunc = () => {
                        resolve({ type: 'validate', result: true });
                    };
                    handleSubmit(validateFunc, validateFunc)();
                });
            });
        }, []);

        const getAgency = (id?: string) => {
            return agencies.find((agency) => agency.id === id);
        };

        return (
            <FormRow adaptive={false}>
                <Controller
                    name="firstName"
                    control={control}
                    render={({ field }) => (
                        <FormItem
                            errorMessage={errors.firstName?.message}
                            className={styles.row}
                            editMode={editMode}
                            label="First Name"
                            value={field.value}
                            renderValue={(value) => value || EMPTY_PLACEHOLDER}
                            onChange={(value) => {
                                field.onChange({
                                    target: { value },
                                });
                            }}
                        />
                    )}
                />
                <Controller
                    name="lastName"
                    control={control}
                    render={({ field }) => (
                        <FormItem
                            errorMessage={errors.lastName?.message}
                            className={styles.row}
                            editMode={editMode}
                            label="Last Name"
                            value={field.value}
                            renderValue={(value) => value || EMPTY_PLACEHOLDER}
                            onChange={(value) => {
                                field.onChange({
                                    target: { value },
                                });
                            }}
                        />
                    )}
                />
                <Controller
                    name="rank"
                    control={control}
                    render={({ field }) => (
                        <FormItem
                            errorMessage={errors.rank?.message}
                            className={styles.name}
                            editMode={editMode}
                            label="Rank"
                            value={field.value}
                            renderValue={(value) => value || EMPTY_PLACEHOLDER}
                            onChange={(value) => {
                                field.onChange({
                                    target: { value },
                                });
                            }}
                        />
                    )}
                />
                <Controller
                    name="agencyId"
                    control={control}
                    render={({ field }) => (
                        <FormItem
                            errorMessage={errors.agencyId?.message}
                            editMode={editMode}
                            label="Agency"
                            value={field.value}
                            onChange={(value) => {
                                field.onChange({
                                    target: { value },
                                });
                            }}
                            renderValue={(value) => getAgency(value)?.name || EMPTY_PLACEHOLDER}
                            renderInput={(value) => (
                                <FormAutocomplete
                                    disablePortal={false}
                                    errorMessage={errors.agencyId?.message}
                                    label={null}
                                    options={agencies}
                                    getOptionLabel={(option) => option.name || EMPTY_PLACEHOLDER}
                                    value={getAgency(value)}
                                    onChange={(_e, newValue) => {
                                        field.onChange({ target: { value: newValue?.id } });
                                    }}
                                />
                            )}
                        />
                    )}
                />
                {editMode && (
                    <FormRowAction>
                        <DeleteButton color="gray" onClick={onDelete} />
                    </FormRowAction>
                )}
            </FormRow>
        );
    },
    /*
        This optimization is possible because the props we pass do not necessarily need to be always up-to-date;
        For such components, initialization or rare update is sufficient
     */
    (prevProps, nextProps) => prevProps.editMode === nextProps.editMode
);
