import { zodResolver } from '@hookform/resolvers/zod';
import { Box } from '@mui/material';
import cn from 'classnames';
import { debounce, sortBy } from 'lodash';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

import { LongTextView } from '../../../../../../components/LongTextView';
import { CustomRadioGroup } from '../../../../../../components/RadioGroup';
import { formatDate } from '../../../utils';
import { FormAutocomplete } from '../../components/Autocomplete';
import { FormCheckbox } from '../../components/FormCheckbox';
import { FormDatePicker } from '../../components/FormDatePicker';
import { FormItem } from '../../components/FormItem';
import { FormSeparator } from '../../components/FormSeparator';
import { MultiFormTemplate } from '../../components/FormTemplates';
import { EmptyFormBlockWrapper } from '../../components/FormTemplates/EmptyFormBlockWrapper';
import { FormRow } from '../../components/FormTemplates/FormRow';
import { NotesField } from '../../components/NotesField';
import { PhoneNumberInput } from '../../components/PhoneNumberInput';
import { DEBOUNCE_WAIT, EMPTY_PLACEHOLDER } from '../../constants';
import { useFormContext } from '../../context/FormsContext';
import { useFormsStore } from '../../store';
import { BailEvents } from './BailEvents';
import { bailEventSchema } from './BailEvents/BailEvent';
import { Charges } from './Charges';
import { chargeSchema } from './Charges/Charge';
import styles from './Defendants.module.css';
import { Sentencing } from './Sentencing';

interface DefendantFormProps {
    defaultValues?: z.infer<typeof schema>;
    onChange?: (result: z.infer<typeof schema>, updateStatus?: boolean) => void;
    onDelete?: () => void;
    onValid?: (isValid: boolean) => void;
}

const outcomeOptions: { name: string; id: string }[] = [
    { name: 'Dismissed', id: 'Dismissed' },
    { name: 'Absconded', id: 'Absconded' },
    { name: 'Acquitted', id: 'Acquitted' },
    { name: 'Convicted', id: 'Convicted' },
    { name: 'Warned', id: 'Warned' },
];

const traffickerTierOptions = [
    { name: 'Local', id: 'Local' },
    { name: 'Regional', id: 'Regional' },
    { name: 'National', id: 'National' },
    { name: 'International', id: 'International' },
];

export const schema = z.object({
    id: z.coerce.string().optional(),
    firstName: z.string().min(3, 'Required'),
    lastName: z.string().min(3, 'Required'),
    birthdate: z.coerce.date().optional(),
    sex: z.enum(['Male', 'Female']).optional(),
    administrativeLevel2Id: z.string().optional().nullable(),
    nationality: z.array(z.string()).optional(),
    streetAddress: z.string().optional().nullable(),
    phoneNumber: z.string().max(16).optional().nullable(),
    passportNo: z.string().optional().nullable(),
    nationalId: z.string().optional().nullable(),
    traffickerTier: z.enum(['Local', 'Regional', 'National', 'International']).optional(),
    arrestDate: z.coerce.date().optional(),
    pleaBargain: z.boolean(),
    outcomeDate: z.coerce.date().optional(),
    outcome: z.enum(['Dismissed', 'Absconded', 'Acquitted', 'Convicted', 'Warned']).optional().nullable(),
    notes: z.string().optional(),
    bailEvents: z.array(bailEventSchema),
    charges: z.array(chargeSchema),
});

export const sentencingScheme = z.object({
    fine: z.number().optional().nullable(),
    suspendedFine: z.number().optional().nullable(),
    custodialMonths: z.number().optional().nullable(),
    suspendedMonths: z.number().optional().nullable(),
    communityServiceHours: z.number().optional().nullable(),
    appealMade: z.boolean(),
    appealGranted: z.boolean(),
});

const totalSchema = schema.merge(sentencingScheme.partial());
export type DefendantSchemaType = z.infer<typeof totalSchema>;

export const Defendant = memo(
    ({ defaultValues, onChange, onDelete: propOnDelete, onValid }: DefendantFormProps) => {
        const { onSubmitListener } = useFormContext();
        const { administrativeLevel2s, countries: nationalities } = useFormsStore((state) => state.lookups);
        const lookupsIsLoading = useFormsStore((state) => state.lookupsIsLoading);
        const contextEditMode = useFormsStore((state) => state.editMode);
        const [sentencingSubsectionIsActive, setSentencingSubsectionIsActive] = useState<boolean>(false);
        const {
            control,
            formState: { errors },
            handleSubmit,
            watch,
            setValue,
            clearErrors,
        } = useForm<DefendantSchemaType>({
            resolver: zodResolver(
                sentencingSubsectionIsActive
                    ? schema.merge(sentencingScheme.required())
                    : schema.merge(sentencingScheme.partial())
            ),
            defaultValues,
        });
        const outcome = watch('outcome');

        const sexOptions = [
            { label: 'Female', value: 'Female' },
            { label: 'Male', value: 'Male' },
        ];

        useEffect(() => {
            const errorsCount = Object.keys(errors).length;

            onValid?.(errorsCount === 0);
        }, [JSON.stringify(errors)]);

        useEffect(() => {
            if (!outcome) {
                setValue('outcomeDate', undefined);
            }
            if (outcome === 'Convicted') {
                setSentencingSubsectionIsActive(true);
            } else {
                setSentencingSubsectionIsActive(false);
            }
        }, [outcome, setValue]);

        useEffect(() => {
            clearErrors([
                'fine',
                'suspendedFine',
                'custodialMonths',
                'suspendedMonths',
                'communityServiceHours',
                'appealMade',
                'appealGranted',
            ]);
        }, [sentencingSubsectionIsActive]);

        useEffect(() => {
            let currentData: DefendantSchemaType;
            const onChangeDebounce = debounce(() => {
                onChange && onChange(currentData);
            }, DEBOUNCE_WAIT);

            const { unsubscribe } = watch((data) => {
                currentData = data as DefendantSchemaType;
                onChangeDebounce();
            });

            return unsubscribe;
        }, [watch]);

        useEffect(() => {
            return onSubmitListener('defendants', () => {
                return new Promise((resolve) => {
                    handleSubmit(
                        (data) => {
                            resolve({ type: 'payload', result: data });
                        },
                        () => {
                            resolve({ type: 'payload', result: false });
                        }
                    )();
                });
            });
        }, []);

        const editMode = useMemo(() => contextEditMode === 'edit', [contextEditMode]);

        const getNationality = (nationalityId?: string | null) => {
            return nationalities.find((item) => item.id === nationalityId);
        };

        const getNationalities = (ids: string[]) => {
            return nationalities.filter((item) => ids.includes(item.id));
        };

        const getAdministrativeLevel2 = (levelId?: string | null) => {
            return administrativeLevel2s.values.find((item) => item.id === levelId);
        };

        const getOutcome = (outcomeId?: string | null) => {
            return outcomeOptions.find((item) => item.id === outcomeId);
        };

        const getTraffickerTier = (traffickerTierId?: string | null) => {
            return traffickerTierOptions.find((item) => item.id === traffickerTierId);
        };

        const onDelete = () => {
            propOnDelete && propOnDelete();
        };

        return (
            <MultiFormTemplate
                editMode={editMode}
                hideNotesSeparator
                headerButtonIsAbsolute
                FormBlockWrapper={EmptyFormBlockWrapper}
                applyFormBlockWrapperTo={[2, 4, 5]}
                header={
                    <FormRow className={styles.defendantHeader}>
                        {' '}
                        <Controller
                            name="firstName"
                            control={control}
                            render={({ field }) => (
                                <FormItem
                                    errorMessage={errors.firstName?.message}
                                    className={styles.row}
                                    editMode={editMode}
                                    label="First Name"
                                    value={field.value}
                                    renderValue={(value) => value || EMPTY_PLACEHOLDER}
                                    onChange={(value) => {
                                        field.onChange({
                                            target: { value },
                                        });
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name="lastName"
                            control={control}
                            render={({ field }) => (
                                <FormItem
                                    errorMessage={errors.lastName?.message}
                                    className={styles.row}
                                    editMode={editMode}
                                    label="Last Name"
                                    value={field.value}
                                    renderValue={(value) => value || EMPTY_PLACEHOLDER}
                                    onChange={(value) => {
                                        field.onChange({
                                            target: { value },
                                        });
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name="birthdate"
                            control={control}
                            render={({ field }) => (
                                <FormItem
                                    errorMessage={errors.birthdate?.message}
                                    editMode={editMode}
                                    className={styles.row}
                                    label="Birthdate"
                                    value={field.value}
                                    renderValue={formatDate}
                                    renderInput={(value) => (
                                        <FormDatePicker
                                            errorMessage={errors.birthdate?.message}
                                            value={value}
                                            maxDate={new Date()}
                                            onChange={(value) => {
                                                field.onChange({ target: { value } });
                                            }}
                                        />
                                    )}
                                />
                            )}
                        />
                        <Controller
                            name="sex"
                            control={control}
                            render={({ field }) => (
                                <FormItem
                                    editMode={editMode}
                                    label="Sex"
                                    className={styles.row}
                                    value={field.value}
                                    renderValue={(value) => {
                                        return value?.slice(0, 1)?.toUpperCase() || EMPTY_PLACEHOLDER;
                                    }}
                                    renderInput={(value) => (
                                        <CustomRadioGroup
                                            value={value || undefined}
                                            onChange={(value) => {
                                                field.onChange({ target: { value } });
                                            }}
                                            options={sexOptions}
                                        />
                                    )}
                                />
                            )}
                        />
                        {/* {errors.sex && <FormHelperText color="error">{errors.sex.message}</FormHelperText>} */}
                        <Controller
                            name="arrestDate"
                            control={control}
                            render={({ field }) => (
                                <FormItem
                                    errorMessage={errors.arrestDate?.message}
                                    editMode={editMode}
                                    label="Arrest Date"
                                    value={field.value}
                                    renderValue={formatDate}
                                    renderInput={(value) => (
                                        <FormDatePicker
                                            errorMessage={errors.arrestDate?.message}
                                            value={value}
                                            maxDate={new Date()}
                                            onChange={(value) => {
                                                field.onChange({ target: { value } });
                                            }}
                                        />
                                    )}
                                />
                            )}
                        />
                        <Controller
                            name="traffickerTier"
                            control={control}
                            render={({ field }) => (
                                <FormItem
                                    editMode={editMode}
                                    label="Trafficker Tier"
                                    errorMessage={errors.traffickerTier?.message}
                                    value={field.value}
                                    onChange={(value) => {
                                        field.onChange({
                                            target: { value },
                                        });
                                    }}
                                    renderValue={(value) => getTraffickerTier(value)?.name || EMPTY_PLACEHOLDER}
                                    renderInput={(value) => (
                                        <FormAutocomplete
                                            disablePortal={false}
                                            errorMessage={errors.traffickerTier?.message}
                                            label={null}
                                            options={traffickerTierOptions}
                                            getOptionLabel={(option) => option.name || EMPTY_PLACEHOLDER}
                                            value={getTraffickerTier(value)}
                                            onChange={(_e, newValue) => {
                                                field.onChange({ target: { value: newValue?.id } });
                                            }}
                                        />
                                    )}
                                />
                            )}
                        />
                    </FormRow>
                }
                formBlocks={[
                    <>
                        <PhoneNumberInput
                            name="phoneNumber"
                            label="Phone number"
                            control={control}
                            editMode={editMode}
                            errorMessage={errors.phoneNumber?.message}
                        />
                        <Controller
                            name="nationality"
                            control={control}
                            render={({ field }) => (
                                <FormItem
                                    errorMessage={errors.nationality?.message}
                                    isLoading={lookupsIsLoading}
                                    editMode={editMode}
                                    label="Nationality"
                                    value={field.value}
                                    onChange={(value) => {
                                        field.onChange({
                                            target: { value },
                                        });
                                    }}
                                    renderValue={(value) => value?.map((v) => getNationality(v)?.name || '')}
                                    renderInput={(value) => (
                                        <FormAutocomplete
                                            disablePortal={false}
                                            multiple
                                            errorMessage={errors.nationality?.message}
                                            label={null}
                                            options={sortBy(
                                                nationalities.filter(({ id }) => !['ATA', 'ATF'].includes(id)),
                                                'name'
                                            )}
                                            getOptionKey={(option) => option.id}
                                            getOptionLabel={(option) => option.name || EMPTY_PLACEHOLDER}
                                            value={getNationalities(value || [])}
                                            onChange={(_e, newValue) => {
                                                field.onChange({
                                                    target: { value: newValue?.map((item) => item.id) || [] },
                                                });
                                            }}
                                        />
                                    )}
                                />
                            )}
                        />
                        <Controller
                            name="passportNo"
                            control={control}
                            render={({ field }) => (
                                <FormItem
                                    errorMessage={errors.passportNo?.message}
                                    editMode={editMode}
                                    label="Passport No."
                                    value={field.value}
                                    renderValue={(value) => value || EMPTY_PLACEHOLDER}
                                    onChange={(value) => {
                                        field.onChange({
                                            target: { value },
                                        });
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name="nationalId"
                            control={control}
                            render={({ field }) => (
                                <FormItem
                                    errorMessage={errors.nationalId?.message}
                                    editMode={editMode}
                                    label="National ID No."
                                    value={field.value}
                                    renderValue={(value) => value || EMPTY_PLACEHOLDER}
                                    onChange={(value) => {
                                        field.onChange({
                                            target: { value },
                                        });
                                    }}
                                />
                            )}
                        />
                    </>,
                    <>
                        <Controller
                            name="streetAddress"
                            control={control}
                            render={({ field }) => (
                                <FormItem
                                    errorMessage={errors.streetAddress?.message}
                                    editMode={editMode}
                                    label="Street Address"
                                    value={field.value}
                                    renderValue={(value) => value || EMPTY_PLACEHOLDER}
                                    onChange={(value) => {
                                        field.onChange({
                                            target: { value },
                                        });
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name="administrativeLevel2Id"
                            control={control}
                            render={({ field }) => (
                                <FormItem
                                    errorMessage={errors.administrativeLevel2Id?.message}
                                    isLoading={lookupsIsLoading}
                                    editMode={editMode}
                                    label={administrativeLevel2s.localName}
                                    value={field.value}
                                    onChange={(value) => {
                                        field.onChange({
                                            target: { value },
                                        });
                                    }}
                                    renderValue={(value) => getAdministrativeLevel2(value)?.name || EMPTY_PLACEHOLDER}
                                    renderInput={(value) => (
                                        <FormAutocomplete
                                            disablePortal={false}
                                            errorMessage={errors.administrativeLevel2Id?.message}
                                            label={null}
                                            options={administrativeLevel2s.values}
                                            getOptionLabel={(option) => option.name || EMPTY_PLACEHOLDER}
                                            value={getAdministrativeLevel2(value)}
                                            onChange={(_e, newValue) => {
                                                field.onChange({ target: { value: newValue?.id } });
                                            }}
                                        />
                                    )}
                                />
                            )}
                        />
                    </>,
                    <>
                        <Controller
                            name="charges"
                            control={control}
                            render={({ field }) => (
                                <Charges
                                    onChange={(value) => {
                                        field.onChange({
                                            target: {
                                                value,
                                            },
                                        });
                                    }}
                                    editMode={editMode}
                                    defaultValues={defaultValues?.charges}
                                />
                            )}
                        />
                        <FormSeparator bold />
                    </>,
                    <>
                        <Controller
                            name="outcome"
                            control={control}
                            render={({ field }) => (
                                <FormItem
                                    errorMessage={errors.outcome?.message}
                                    editMode={editMode}
                                    label="Outcome"
                                    value={field.value}
                                    onChange={(value) => {
                                        field.onChange({
                                            target: { value },
                                        });
                                    }}
                                    renderValue={(value) => getOutcome(value)?.name || EMPTY_PLACEHOLDER}
                                    renderInput={(value) => (
                                        <FormAutocomplete
                                            disablePortal={false}
                                            errorMessage={errors.outcome?.message}
                                            label={null}
                                            options={outcomeOptions}
                                            getOptionLabel={(option) => option.name || EMPTY_PLACEHOLDER}
                                            value={getOutcome(value)}
                                            onChange={(_e, newValue) => {
                                                field.onChange({ target: { value: newValue?.id } });
                                            }}
                                        />
                                    )}
                                />
                            )}
                        />
                        <Controller
                            name="outcomeDate"
                            control={control}
                            disabled={!outcome}
                            render={({ field }) => (
                                <FormItem
                                    errorMessage={errors.outcomeDate?.message}
                                    editMode={editMode}
                                    label="Outcome Date"
                                    value={field.value}
                                    renderValue={formatDate}
                                    renderInput={(value) => (
                                        <FormDatePicker
                                            errorMessage={errors.outcomeDate?.message}
                                            value={value}
                                            onChange={(value) => {
                                                field.onChange({ target: { value } });
                                            }}
                                            disabled={!outcome}
                                        />
                                    )}
                                />
                            )}
                        />
                        <Controller
                            name="pleaBargain"
                            control={control}
                            render={({ field }) => (
                                <FormItem
                                    editMode={editMode}
                                    label="Plea Bargain"
                                    value={field.value}
                                    renderValue={(value) => {
                                        return value ? 'Yes' : 'No';
                                    }}
                                    renderInput={(value) => (
                                        <FormCheckbox
                                            checked={value}
                                            onChange={(value) => {
                                                field.onChange({ target: { value } });
                                            }}
                                        />
                                    )}
                                />
                            )}
                        />
                    </>,
                    <>
                        <Box className={cn(!sentencingSubsectionIsActive && styles.disabledFilter)}>
                            <Sentencing
                                errors={errors}
                                control={control}
                                disableSentencing={!sentencingSubsectionIsActive}
                                editMode={editMode}
                            />
                        </Box>
                        <FormSeparator bold />
                    </>,
                    <>
                        <Controller
                            name="bailEvents"
                            control={control}
                            render={({ field }) => (
                                <BailEvents
                                    onChange={(value) => {
                                        field.onChange({
                                            target: {
                                                value,
                                            },
                                        });
                                    }}
                                    editMode={editMode}
                                    defaultValues={defaultValues?.bailEvents}
                                />
                            )}
                        />
                        <FormSeparator bold />
                    </>,
                ]}
                notes={
                    <Controller
                        name="notes"
                        control={control}
                        render={({ field }) => (
                            <FormItem
                                errorMessage={errors.notes?.message}
                                className={styles.notesFormItem}
                                editMode={editMode}
                                label="Notes"
                                value={field.value}
                                renderValue={(value) => <LongTextView>{value || ''}</LongTextView>}
                                renderInput={(value) => (
                                    <NotesField
                                        onChange={(e) => {
                                            field.onChange({
                                                target: { value: e.target.value },
                                            });
                                        }}
                                        value={value}
                                        errorMessage={errors.notes?.message}
                                    />
                                )}
                            />
                        )}
                    />
                }
                onDelete={onDelete}
            />
        );
    },
    /*
        This optimization is possible because the props we pass do not necessarily need to be always up-to-date;
        For such components, initialization is sufficient.
     */
    () => true
);
