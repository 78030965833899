import { AppBar, Button, NavigationTabs, UserMenu } from '@c4ads/c4blocks';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Drawer } from '@mui/material';
import { createContext, useContext, useState } from 'react';

import { useAuth } from '../../api/auth';
import app_logo from '../../static/logos/WDP Default B White.svg';
import styles from './Header.module.css';

type HeaderContextType = {
    hide: boolean;
    setHide: (value: boolean) => void;
};

const HeaderContext = createContext<HeaderContextType>({
    hide: false,
    setHide: (value: boolean) => {},
});

export { HeaderContext };
export type { HeaderContextType };

export default function Header() {
    const { hide } = useContext(HeaderContext);
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const { isAuthenticated, isLoading, user, logout } = useAuth();

    const closeDrawer = () => {
        setDrawerOpen(false);
    };

    const openDrawer = () => {
        setDrawerOpen(true);
    };

    return (
        <AppBar hide={hide} elevation={1} position="static">
            <img src={app_logo} id="app-logo" alt="Wildlife Crimes Partner Platform" />
            <div className={styles.desktopHeader}>
                <Box sx={{ display: 'flex', alignItems: 'center', height: 40 }}>
                    <NavigationTabs routes={user?.availableRoutes} />
                    <UserMenu
                        isAuthenticated={isAuthenticated}
                        isLoading={isLoading}
                        user={user}
                        handleLogout={() => {
                            logout();
                        }}
                    />
                </Box>
            </div>
            <div className={styles.mobileHeader}>
                {isAuthenticated ? (
                    <Button onClick={openDrawer}>
                        <MenuIcon fontSize="large" className={styles.menuIcon} />
                    </Button>
                ) : (
                    <Button variant="contained" href="/login">
                        Log In
                    </Button>
                )}

                <Drawer
                    sx={{
                        '& .MuiPaper-root': {
                            backgroundColor: '#122945',
                        },
                    }}
                    anchor="left"
                    open={drawerOpen}
                    onClose={closeDrawer}
                >
                    <Box className={styles.drawerContent}>
                        <NavigationTabs onClick={closeDrawer} orientation="vertical" routes={user?.availableRoutes} />
                        <UserMenu
                            isAuthenticated={isAuthenticated}
                            isLoading={isLoading}
                            user={user}
                            handleLogout={() => {
                                logout();
                            }}
                            onClose={closeDrawer}
                        />
                    </Box>
                </Drawer>
            </div>
        </AppBar>
    );
}
