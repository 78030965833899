import { Box } from '@mui/material';
import cn from 'classnames';
import { PropsWithChildren } from 'react';

import styles from './ListComponents.module.css';

interface ListProps extends PropsWithChildren {
    className?: string;
}

export const List = ({ children, className }: ListProps) => {
    return (
        <Box display="flex" flexDirection="column" className={cn(styles.list, className)}>
            {children}
        </Box>
    );
};
