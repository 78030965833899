import { FormControl, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { ReactNode, useContext, useEffect, useState } from 'react';

import { AdvancedFilterContext } from '../../AdvancedFilterContext';
import { FilterComponentGeneralInterface } from '../FilterComponent';

export interface ComparisonFilterProps<T> extends FilterComponentGeneralInterface {
    parseValue: (value: string | undefined) => T;
    inputType: 'string' | 'number';
    renderInput?: (onChange: (value: string) => void) => ReactNode;
}

const operators = {
    GT: '>',
    GTE: '>=',
    LT: '<',
    LTE: '<=',
};

export const ComparisonInput = <T,>({
    onFilterFunction,
    field,
    parseValue,
    renderInput,
    inputType,
}: ComparisonFilterProps<T>) => {
    const { defaultFiltersValue } = useContext(AdvancedFilterContext);
    // @ts-ignore
    const defaultOperation = defaultFiltersValue?.[field]?.operation;
    // @ts-ignore
    const defaultValue = defaultFiltersValue?.[field]?.value;

    const [state, setState] = useState<string>(defaultOperation || 'GT');
    const [value, setValue] = useState<string>(defaultValue !== undefined ? String(defaultValue) : '');

    useEffect(() => {
        onFilterFunction(field, field, () => {
            return {
                [field]: {
                    operation: state,
                    value: parseValue(value),
                },
            };
        });
    }, [state, value]);

    const handleChange = (event: SelectChangeEvent) => {
        setState(event.target.value as string);
    };

    const onChangeValue = (value: string) => {
        setValue(value);
    };

    return (
        <>
            <FormControl size="small">
                <Select onChange={handleChange} value={state}>
                    {Object.keys(operators).map((key) => (
                        <MenuItem key={key} value={key}>
                            {operators[key]}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl size="small">
                {renderInput ? (
                    renderInput(onChangeValue)
                ) : inputType === 'number' ? (
                    <TextField
                        label="Value"
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        value={value}
                        onChange={(e) => {
                            onChangeValue(e.target.value);
                        }}
                    />
                ) : (
                    <TextField
                        label="Value"
                        value={value}
                        onChange={(e) => {
                            onChangeValue(e.target.value);
                        }}
                    />
                )}
            </FormControl>
        </>
    );
};
