import { Box } from '@mui/material';
import React, { PropsWithChildren } from 'react';

import { Loader } from '../../../../../../components/Loader';
import styles from './TableBox.module.css';

interface TableBoxProps extends PropsWithChildren {
    isLoading?: boolean;
}

export const TableBox = ({ children, isLoading }: TableBoxProps) => {
    return (
        <Box className={styles.tableBox}>
            {isLoading && (
                <div className={styles.loaderContainer}>
                    <Loader />
                </div>
            )}
            {children}
        </Box>
    );
};
