import { useQuery } from '@apollo/client';
import { Dataset } from '@c4ads/c4blocks';

import { GET_CHART_DATA } from '../../api/apollo/query';
import { ChartConfig } from '../../types/ChartConfig';

export interface DataProviderProps {
    config: ChartConfig;
    children: (args: { dataset?: Dataset; loading?: any; error?: any }) => React.ReactNode | null;
}

const DataProvider = ({ config, children }: DataProviderProps) => {
    const { id, ...configParams } = config;
    const { xLines, yLines, ...params } = configParams;

    const { data, loading, error } = useQuery(GET_CHART_DATA, {
        variables: { params: params },
    });

    if (error) {
        return <>{children({ error })}</>;
    }

    return <>{children({ dataset: data?.dataset, loading: loading, error: error })}</>;
};

export default DataProvider;
