import { TickFormatter, TickLabelProps } from '@visx/axis';
import { TextProps } from '@visx/text/lib/Text';
import { Axis, DataContext } from '@visx/xychart';
import { every, lowerCase, sumBy } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback, useContext, useMemo } from 'react';

import { truncate } from '../../../helpers/stringHelpers';
import { MAX_CHART_LABEL_LENGTH } from '../constants';
import { AxesProps } from '../types';

const tickStroke: string = '#bdbdbd';

const tickLabelPropsLeft: TickLabelProps<any> = () => ({
    fill: '#757575',
    fontSize: 11,
});

const labelProps: Partial<TextProps> = {
    fill: '#757575',
    fontSize: 11,
    textAnchor: 'middle',
};

const bottomBarLabelFontSize = 11;

export default function Axes({ axes, data }: { axes?: AxesProps; data: any[] }) {
    const { xScale } = useContext(DataContext);

    const dateFormat = useMemo(() => {
        const interval = axes?.bottom?.interval || '';
        if (typeof interval === 'string') {
            switch (lowerCase(interval)) {
                case 'month':
                    return 'LLL y';
                case 'quarter':
                    return 'q y';
                case 'year':
                    return 'y';
                default:
                    return 'D';
            }
        }
        return 'D';
    }, [axes?.bottom]);

    const tickFormatBottom: TickFormatter<any> = useCallback(
        (d: number | string) => {
            const formatDateFromMillis = (d: number, format: string) => {
                const prefix = axes?.bottom?.interval === 'quarter' ? 'Q' : '';
                return prefix + DateTime.fromMillis(d).toFormat(format);
            };

            if (axes?.bottom?.isDate && typeof d === 'number') {
                return formatDateFromMillis(d, dateFormat);
            }

            if (typeof d === 'string') {
                return truncate(d, MAX_CHART_LABEL_LENGTH);
            }
        },
        [axes?.bottom, dateFormat]
    );

    const tickFormatLeft: TickFormatter<any> = useCallback(
        (d: any) => {
            if (typeof d === 'number') {
                if (d >= 1e9) {
                    return `${d / 1e9}B`;
                }
                if (d >= 1e6) {
                    return `${d / 1e6}M`;
                }
                if (d >= 1000) {
                    return `${d / 1000}K`;
                }
            }

            return `${d}`;
        },
        [axes?.left]
    );

    const isIdentitySeries = (data) => {
        const seriesLength = data[0].data.length;
        return every(data, (d) => d.data.length === seriesLength);
    };

    const numTicks: number | undefined = useMemo(() => {
        const isSingleSeries = !Array.isArray(data[0]?.data);
        const firstRecord = isSingleSeries ? data[0] : data[0].data[0];
        const isDiscrete = typeof firstRecord.label === 'string';
        const numTicks = isSingleSeries
            ? data.length
            : isIdentitySeries(data)
            ? data[0].data.length
            : sumBy(data, (d) => d.data.length);
        return isDiscrete ? numTicks : undefined;
    }, [data]);

    const tickLabelPropsBottom: TickLabelProps<any> = useCallback(() => {
        const monospaceCorrelationCoef = 0.6;
        const bottomLabels = data
            .map((item) => {
                return item.data.map((innerItem) => {
                    return innerItem.label;
                });
            })
            .flat(1)
            .filter(Boolean);

        const longestLabelLength = Math.max(...bottomLabels.map((label) => label.length)) || 0;
        const letterWidth = bottomBarLabelFontSize * monospaceCorrelationCoef;
        //@ts-ignore
        const xBandwidth = xScale?.bandwidth() || 0;
        const angle = longestLabelLength * letterWidth > xBandwidth ? -45 : 0;
        const textAnchor = longestLabelLength * letterWidth > xBandwidth ? 'end' : 'middle';

        return {
            fill: '#757575',
            fontSize: 11,
            angle,
            textAnchor,
            overflow: 'visible',
        } as Partial<TextProps>;
    }, [numTicks, xScale, data]);

    const bottomLabelOffset = useMemo(() => {
        const extend = numTicks && numTicks > 8;
        return extend ? 40 : undefined;
    }, [numTicks]);

    return (
        <>
            {axes?.left && (
                <Axis
                    orientation="left"
                    label={axes.left.label}
                    labelOffset={48}
                    tickFormat={tickFormatLeft}
                    tickStroke={tickStroke}
                    tickLabelProps={tickLabelPropsLeft}
                    labelProps={labelProps}
                />
            )}
            {axes?.bottom && (
                <Axis
                    orientation="bottom"
                    numTicks={numTicks}
                    tickFormat={tickFormatBottom}
                    tickStroke={tickStroke}
                    tickLineProps={{ strokeWidth: 1 }}
                    tickLabelProps={tickLabelPropsBottom}
                    labelProps={labelProps}
                    labelOffset={bottomLabelOffset}
                />
            )}
        </>
    );
}
