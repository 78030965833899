import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { Box, FormHelperText, InputAdornment, TextField as MUITextField, TextFieldProps } from '@mui/material';
import cn from 'classnames';
import { forwardRef } from 'react';

import styles from './TextField.module.css';

interface FormTextFieldProps {
    errorMessage?: string;
    className?: string;
}

export const TextField = forwardRef(
    ({ errorMessage, className, value, ...props }: TextFieldProps & FormTextFieldProps, ref: any) => {
        return (
            <Box>
                <MUITextField
                    ref={ref}
                    value={value ?? undefined}
                    {...props}
                    InputProps={{
                        ...props.InputProps,
                        endAdornment: errorMessage ? (
                            <InputAdornment position="end">
                                <WarningAmberRoundedIcon className={styles.errorIcon} />
                            </InputAdornment>
                        ) : (
                            props.InputProps?.endAdornment
                        ),
                    }}
                    className={cn(styles.textField, className, errorMessage && styles.error)}
                />
                {errorMessage && <FormHelperText className={styles.errorMessage}>{errorMessage}</FormHelperText>}
            </Box>
        );
    }
);
