import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Tooltip, Typography } from '@mui/material';
import React from 'react';

import styles from './BackButton.module.css';

interface BackButtonProps {
    tooltipContent?: string;
    buttonText: string;
    onClick?: () => void;
}

export const BackButton = ({ tooltipContent, buttonText, onClick }: BackButtonProps) => {
    return (
        <Tooltip title={tooltipContent ?? 'Back'} placement="bottom-end">
            <button className={styles.backContainer} onClick={onClick}>
                <ArrowBackIcon />
                <Typography className={styles.backTitle} variant="body2" sx={{ m: 1 }}>
                    {buttonText}
                </Typography>
            </button>
        </Tooltip>
    );
};
