import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import cn from 'classnames';
import { useState } from 'react';

import { TextField } from '../TextField';
import styles from './FormDatePicker.module.css';

interface FormDatePickerProps {
    errorMessage?: string;
}

export const FormDatePicker = ({
    value,
    onChange,
    className,
    errorMessage,
    ...props
}: Omit<DatePickerProps<any, any>, 'renderInput'> & FormDatePickerProps) => {
    const [open, setOpen] = useState(false);

    const toggleDatePicker = () => {
        setOpen(!open);
    };

    return (
        <DatePicker
            className={cn(styles.formDatePicker, className)}
            value={value}
            onChange={onChange}
            open={open}
            onClose={() => {
                setOpen(false);
            }}
            onOpen={() => {
                setOpen(true);
            }}
            renderInput={(params) => {
                return (
                    <TextField
                        onClick={toggleDatePicker}
                        errorMessage={errorMessage}
                        {...params}
                        inputProps={{
                            ...params.inputProps,
                            value: value ? params?.inputProps?.value : undefined,
                        }}
                    />
                );
            }}
            {...props}
        />
    );
};
