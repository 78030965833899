import { gql } from '@apollo/client';

const ADD_USER_TO_ORGANIZATION = gql`
    mutation addUserToOrganization($user: UserInput!, $organization: OrganizationInput!) {
        addUserToOrganization(user: $user, organization: $organization) {
            user {
                id
                name
                organization {
                    id
                    name
                }
            }
        }
    }
`;

export default ADD_USER_TO_ORGANIZATION;
