import { Avatar, AvatarGroup, Tooltip } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';

import { tableHelpers } from '../../../../helpers/tableHelpers';

const FactorCell = ({
    outcomeFactors = [],
}: {
    outcomeFactors: { impact: 'Aggravating' | 'Mitigating'; factor: string }[];
}) => {
    return outcomeFactors.length > 0 ? (
        <AvatarGroup max={4}>
            {outcomeFactors.map(({ impact, factor }, i) => (
                <Tooltip key={i} title={factor.replace('_', ' ')}>
                    <Avatar
                        sx={{
                            fontSize: 12,
                            height: 24,
                            width: 24,
                            bgcolor: impact === 'Aggravating' ? '#f75151' : '#05A07E',
                        }}
                    >
                        {factor.trim().slice(0, 1)}
                    </Avatar>
                </Tooltip>
            ))}
        </AvatarGroup>
    ) : null;
};

function nationalityGetter({ row }: GridValueGetterParams<string>) {
    if (!row.nationality) {
        return;
    }
    return row.nationality.map(({ name }) => name || '').join(', ');
}

function bailEventsGetter({ row }: GridValueGetterParams<string>) {
    if (!row.bailEvents) {
        return;
    }

    if (row.bailEvents.length === 0) {
        return;
    }
    return row.bailEvents.some(({ bailGranted }) => bailGranted === true);
}

function chargeCountGetter({ row }: GridValueGetterParams<number>) {
    if (!row.charges) {
        return;
    }
    return row.charges.length;
}

function guiltyCountGetter({ row }: GridValueGetterParams<number>) {
    if (!row.charges) {
        return;
    }
    return row.charges.filter(({ verdict }) => verdict === 'Guilty')?.length;
}

function outcomeFactorsGetter({ row }: GridValueGetterParams<string>) {
    if (!row.outcomeFactors) {
        return;
    }
    return row.outcomeFactors.map(({ impact, factor }) => `${factor} (${impact})`).join(' / ');
}

const columns: GridColDef[] = [
    {
        field: 'courtCase',
        headerName: 'Case Reference Number',
        headerAlign: 'center',
        width: 144,
        groupable: false,
        valueGetter: ({ row }) => row.courtCase?.docket || '--',
    },
    {
        field: 'arrestDate',
        type: 'date',
        headerName: 'Arrest Date',
        headerAlign: 'center',
        align: 'center',
        width: 144,
        groupable: false,
        valueFormatter: tableHelpers.dateFormatter,
    },
    {
        field: 'name',
        headerName: 'Name',
        headerAlign: 'center',
        width: 200,
        groupable: false,
        valueGetter: ({ row }: GridValueGetterParams<string>) => `${row.firstName || ''} ${row.lastName || ''}`.trim(),
    },
    {
        field: 'birthdate',
        type: 'date',
        headerName: 'Date of Birth',
        headerAlign: 'center',
        align: 'center',
        width: 200,
        groupable: false,
        valueFormatter: tableHelpers.dateFormatter,
    },
    {
        field: 'sex',
        headerName: 'Sex',
        headerAlign: 'center',
        align: 'center',
        width: 100,
        groupable: false,
    },
    {
        field: 'nationality',
        headerName: 'Nationality',
        headerAlign: 'center',
        width: 200,
        groupable: false,
        valueGetter: nationalityGetter,
    },
    {
        field: 'streetAddress',
        type: 'string',
        headerName: 'Address',
        headerAlign: 'left',
        width: 200,
        groupable: false,
    },
    {
        field: 'bailEvents',
        type: 'boolean',
        headerName: 'Bail',
        headerAlign: 'center',
        align: 'center',
        width: 100,
        groupable: false,
        valueGetter: bailEventsGetter,
        renderCell: ({ value }: GridRenderCellParams) => tableHelpers.renderBoolean(value),
    },
    {
        field: 'pleaBargain',
        type: 'boolean',
        headerName: 'Plea Bargain',
        headerAlign: 'center',
        width: 100,
        groupable: false,
        renderCell: ({ value }) => tableHelpers.renderBoolean(value),
    },
    {
        field: 'outcome',
        type: 'string',
        headerName: 'Outcome',
        headerAlign: 'center',
        width: 100,
        groupable: false,
    },
    {
        field: 'outcomeDate',
        type: 'date',
        headerName: 'Outcome Date',
        headerAlign: 'center',
        align: 'center',
        width: 144,
        groupable: false,
        valueFormatter: tableHelpers.dateFormatter,
    },
    {
        field: 'custodialMonths',
        type: 'number',
        headerName: 'Custodial Sentence (Months)',
        headerAlign: 'center',
        renderCell: ({ value }) => tableHelpers.renderNumber(value),
        groupable: false,
        width: 144,
    },
    {
        field: 'fine',
        type: 'number',
        headerName: 'Fine',
        headerAlign: 'center',
        renderCell: ({ row, value }) => tableHelpers.renderCurrency(value, row.fineCurrency),
        groupable: false,
        width: 144,
    },
    {
        field: 'communityServiceHours',
        type: 'number',
        headerName: 'Community Service (Hours)',
        headerAlign: 'center',
        renderCell: ({ value }) => tableHelpers.renderNumber(value),
        groupable: false,
        width: 144,
    },
    {
        field: 'suspendedMonths',
        type: 'number',
        headerName: 'Suspended Sentence (Months)',
        headerAlign: 'center',
        renderCell: ({ value }) => tableHelpers.renderNumber(value),
        groupable: false,
        width: 144,
    },
    {
        field: 'chargeCount',
        type: 'number',
        sortable: false,
        headerName: 'Num. Charges',
        headerAlign: 'center',
        width: 200,
        groupable: false,
        valueGetter: chargeCountGetter,
        renderCell: ({ value }) => tableHelpers.renderNumber(value),
    },
    {
        field: 'guiltyCount',
        type: 'number',
        sortable: false,
        headerName: 'Num. Guilty Charges',
        headerAlign: 'center',
        width: 200,
        groupable: false,
        valueGetter: guiltyCountGetter,
        renderCell: ({ value }) => tableHelpers.renderNumber(value),
    },
    {
        field: 'outcomeFactors',
        headerName: 'Outcome Factors',
        headerAlign: 'left',
        width: 200,
        groupable: false,
        valueGetter: outcomeFactorsGetter,
        renderCell: ({ row }: GridRenderCellParams<string>) => <FactorCell outcomeFactors={row.outcomeFactors} />,
    },
];

export default columns;
