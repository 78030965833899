import { useLazyQuery } from '@apollo/client';
import * as _ from 'lodash';
import { DateTime } from 'luxon';
import { usePostHog } from 'posthog-js/react';
import { ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Layout, Layouts } from 'react-grid-layout';
import { v4 as uuidv4 } from 'uuid';

import { GET_DASHBOARD_CONFIG } from '../../../api/apollo/query';
import { useAuth } from '../../../api/auth';
// import { Chart } from '@c4ads/c4blocks';
import Chart from '../../../components/Chart';
import ChartPropProvider from '../../../components/ChartPropProvider';
import DataProvider from '../../../components/DataProvider';
import { captureCreateChart, captureRemoveChart } from '../../../posthog';
import { ChartConfig } from '../../../types/ChartConfig';
import { DashboardConfig } from '../../../types/dashboardConfig';
import { DashboardsContext } from '../DashboardsContext';
import { getDashboardDataFromLocalStorage, parseDashboardConfig } from '../dashboardsLocalStorageManager';
import styles from './Dashboard.module.css';

export const emptyDashboard: DashboardConfig = {
    charts: {},
    layouts: {
        lg: [],
        md: [],
        sm: [],
    } as Layouts,
};

export default function useDashboard(
    id: string,
    onAbortFunction: (callback: () => void) => void,
    onUpdate?: (dashboardConfig: DashboardConfig) => void
) {
    const posthog = usePostHog();
    const abortRef = useRef(new AbortController());
    const { user } = useAuth();
    const [editConfig, setEditConfig] = useState<ChartConfig | Partial<ChartConfig> | null>(null);
    const { setIsLoading, setSavedStatus, closeTab } = useContext(DashboardsContext);
    const [refetch, { error, data: remoteDashboardData, loading, called }] = useLazyQuery(GET_DASHBOARD_CONFIG, {
        variables: {
            id,
        },
        context: {
            fetchOptions: {
                signal: abortRef.current.signal,
            },
        },
        fetchPolicy: 'network-only',
    });
    const [dashboardConfig, setDashboardConfig] = useState<DashboardConfig>(emptyDashboard);
    const [ready, setReady] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(id, true);

        refetch()
            .then(({ data }) => {
                const remoteDashboardConfig = data?.dashboardConfig;

                if (!remoteDashboardConfig) {
                    abortRef.current.abort();
                    abortRef.current = new AbortController();
                    closeTab(id);
                    return;
                }

                const config = parseDashboardConfig(remoteDashboardConfig.config);
                if (!config) {
                    return;
                }
                if (user === undefined) {
                    return;
                }
                const localDashboard = getDashboardDataFromLocalStorage(id, user.id);

                if (!localDashboard) {
                    setDashboardConfig(config);
                    setSavedStatus(id, true);
                    return;
                }

                setSavedStatus(id, JSON.stringify(localDashboard.config) === remoteDashboardConfig.config);

                if (JSON.stringify(localDashboard.config) !== remoteDashboardConfig.config) {
                    setDashboardConfig(localDashboard.config);
                    return;
                }
                Object.keys(config).forEach((key) => {
                    switch (key) {
                        case 'start':
                        case 'end':
                            config[key] = config[key] ? DateTime.fromISO(config[key]) : null;
                            break;
                    }
                });
                setDashboardConfig(config);
            })
            .catch(() => {
                closeTab(id);
            })
            .finally(() => {
                setReady(true);
                setIsLoading(id, false);
            });
    }, [user]);

    useEffect(() => {
        onAbortFunction(() => {
            abortRef.current.abort();
            abortRef.current = new AbortController();
        });
    }, []);

    useEffect(() => {
        if (!called || !ready) {
            return;
        }

        if (!loading) {
            if (!user) {
                return;
            }

            const localStoredDashboard = getDashboardDataFromLocalStorage(id, user.id);

            if (localStoredDashboard) {
                const savedStatus = JSON.stringify(dashboardConfig) === remoteDashboardData.dashboardConfig.config;
                if (!savedStatus) {
                    onUpdate && onUpdate(dashboardConfig);
                }

                setSavedStatus(id, JSON.stringify(dashboardConfig) === remoteDashboardData.dashboardConfig.config);
            } else {
                if (!error) {
                    onUpdate && onUpdate(dashboardConfig);
                }
            }
        }
    }, [dashboardConfig, loading, ready, called, user, remoteDashboardData]);

    const handleEditConfig = (config: ChartConfig) => {
        setEditConfig(config);
    };

    //initialize an empty chart
    const handleInitChart = useCallback(() => {
        const newConfig: Partial<ChartConfig> = {
            x: 'YEAR',
            y: 'SEIZURES',
            operation: 'COUNT',
            groupby: null,
            filters: {
                start: null,
                end: null,
                courts: [],
                administrativeLevel1: [],
                regions: [],
                commodities: [],
                species: [],
                speciesGroup: [],
                protectionLevels: [],
            },
            mark: 'Bar',
            xLines: [],
            yLines: [],
        };
        setEditConfig(newConfig);
    }, []);

    //generate a new chart with the given configuration and default layout
    const handleCreateChart = (newConfig: ChartConfig) => {
        const chartId = uuidv4();
        const minW = ['ADMINISTRATIVE_LEVEL_1', 'REGION'].includes(newConfig.x) ? 1 : 2; //map variant
        const newLayout: Layout = {
            i: id,
            w: 2,
            minW: minW,
            h: 1,
            minH: 1,
            x: 0,
            y: 1000,
        };
        setDashboardConfig((prev) => ({
            charts: { ...prev.charts, [chartId]: { ...newConfig, id: chartId } },
            layouts: {
                lg: [...prev.layouts.lg, newLayout],
                md: [...prev.layouts.md, newLayout],
                sm: [...prev.layouts.sm, newLayout],
            },
        }));

        const { eventName, payload } = captureRemoveChart(chartId);
        posthog?.capture(eventName, payload);
    };

    //update an existing chart's variables
    const handleUpdateChartConfig = (id: string, newConfig: ChartConfig) => {
        setDashboardConfig((prev) => ({ ...prev, charts: { ...prev.charts, [id]: newConfig } }));
    };

    //save layout change to local storage
    const handleLayoutChange = (currentLayout: Layout[], allLayouts: Layouts) => {
        setDashboardConfig((prev) => ({ ...prev, layouts: allLayouts }));
    };

    //remove a chart from the dashboard
    const handleRemoveChart = (id: string) => {
        setDashboardConfig((prev) => ({
            charts: _.omit(prev.charts, id),
            layouts: _.mapValues(prev.layouts, (layout) => _.reject(layout, { i: id })),
        }));

        const { eventName, payload } = captureCreateChart(id);
        posthog?.capture(eventName, payload);
    };

    //remove all charts from the dashboard
    const handleResetDashboard = () => {
        setDashboardConfig(emptyDashboard);
    };

    //render chart blocks from the dashboard config and update local storage
    const chartBlocks: ReactNode | null = useMemo(() => {
        const { charts } = dashboardConfig;
        if (Object.values(charts).length > 0) {
            return Object.values(charts).map((chartConfig: ChartConfig) => (
                <div key={chartConfig.id} className={styles.dashboardChart}>
                    <DataProvider config={chartConfig}>
                        {({ dataset, loading, error }) => (
                            <ChartPropProvider config={chartConfig} dataset={dataset} loading={loading} error={error}>
                                {({ chartProps }) => <Chart {...chartProps} />}
                            </ChartPropProvider>
                        )}
                    </DataProvider>
                </div>
            ));
        } else {
            return null;
        }
    }, [dashboardConfig]);

    return {
        editConfig,
        setEditConfig,
        handleEditConfig,
        handleInitChart,
        handleCreateChart,
        handleUpdateChartConfig,
        handleLayoutChange,
        handleRemoveChart,
        handleResetDashboard,
        dashboardConfig,
        chartBlocks,
    };
}
