import { gql } from '@apollo/client';

export const GET_PERSONS = gql`
    query persons($input: PersonFilter) {
        persons(input: $input) {
            id
            firstName
            lastName
            birthdate
            sex
            roles {
                courtCase {
                    id
                    name
                    docket
                }
                role
            }
        }
    }
`;
