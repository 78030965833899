import { ResetPassword as ResetPasswordComponent } from '@c4ads/c4blocks';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Typography } from '@mui/material';
import { useNavigate } from 'react-router';

import { useAuth } from '../../api/auth';
import { openSnackBar } from '../../components/Snackbar';
import backgroundImg from '../../static/img/login_background.jpg';
import styles from './ResetPassword.module.css';

export default function ResetPassword() {
    const { resetPassword } = useAuth();
    const navigate = useNavigate();

    const handleResetPassword = (email: string) => {
        openSnackBar({
            message:
                'If you have an account, you should receive a message containing a link to reset your password shortly.',
            type: 'success',
        });
        resetPassword(email);
    };

    const backToLogin = () => {
        navigate('/login');
    };

    return (
        <>
            <ResetPasswordComponent
                title=""
                subtitle=""
                formContainerTitle={
                    <>
                        <Typography variant="h5">Reset password</Typography>
                        <IconButton onClick={backToLogin} className={styles.closeButton}>
                            <CloseIcon />
                        </IconButton>
                    </>
                }
                backgroundImageSrc={backgroundImg}
                onResetPassword={handleResetPassword}
                className={styles.resetPasswordForm}
                submitButtonTitle="Reset Password"
            />
        </>
    );
}
