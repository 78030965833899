import { camelCase, isArray, isObject, transform } from 'lodash';

export const downloadBlob = (blob: Blob, filename: string) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;

    a.click();
    a.remove();
    URL.revokeObjectURL(url);
};

export const stringFromArray = (arr: (string | undefined | null)[], separator: string): string => {
    const result = arr.filter((item) => !!item).join(separator);

    return result.length === 0 ? '--' : result;
};

export const deeplyReplaceValues = (obj: any, argKey: string | null, newValue: any | ((currentValue: any) => any)) => {
    if (typeof obj === 'object' && obj !== null) {
        for (let key in obj) {
            if (key === argKey || argKey === null) {
                obj[key] = typeof newValue === 'function' ? newValue(obj[key]) : newValue;
            }

            if (typeof obj[key] === 'object' && obj[key] !== null) {
                deeplyReplaceValues(obj[key], argKey, newValue);
            }
        }
    }
    return obj;
};

export const camelize = (obj: any) =>
    transform(obj, (acc, value, key, target) => {
        const camelKey = isArray(target) ? (key as string) : camelCase(key as string);

        // @ts-ignore
        acc[camelKey] = isObject(value) ? camelize(value) : value;
    });

export const truncateText = (text: string, maxLength: number) => {
    const slicedText = text.slice(0, maxLength);

    return text.length > slicedText.length ? `${slicedText}...` : slicedText;
};
