import { useQuery } from '@apollo/client';
import { GridEventListener, useGridApiRef } from '@mui/x-data-grid-premium';
import { useEffect, useState } from 'react';

import GET_DEFENDANTS from '../../../../api/apollo/query/GetDefendants';
import { useAuth } from '../../../../api/auth';
import { CustomDataGrid } from '../../../../components/CustomDataGrid';
import TableToolbar from '../../../../components/Table/TableToolbar';
import { DefaultTableProps } from '../../../../components/Table/types';
import demoDefendantData from '../../../../static/data/demo_defendants_data.json';
import columns from './columns';

const Defendants = ({ shrinkTitle, hideHeaders, showHeaders }: DefaultTableProps) => {
    const { user } = useAuth();
    const apiRef = useGridApiRef();
    const [rows, setRows] = useState<any[]>([]);

    const { loading } = useQuery(GET_DEFENDANTS, {
        variables: { country: user?.organization.country.id },
        onCompleted: ({ defendants }) => setRows(defendants),
        onError: () => setRows(demoDefendantData),
    });
    useEffect(() => {
        const handleEvent: GridEventListener<'rowsScroll'> = (params) => {
            params.top > 5 ? hideHeaders() : showHeaders();
        };

        apiRef.current.subscribeEvent('rowsScroll', handleEvent);
    }, [apiRef, hideHeaders, showHeaders]);

    return (
        <CustomDataGrid
            apiRef={apiRef}
            columns={columns}
            rows={rows}
            checkboxSelection={false}
            loading={loading}
            hideFooterSelectedRowCount
            hideFooterPagination
            components={{ Toolbar: TableToolbar }}
            componentsProps={{
                toolbar: {
                    title: 'Defendants',
                    showTitle: shrinkTitle,
                    csvExportEnabled:
                        !loading && user?.permissions?.map(({ codename }) => codename).includes('can_export_tables'),
                    tableApi: apiRef.current,
                },
            }}
        />
    );
};

export default Defendants;
