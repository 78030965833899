import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import styled from '@mui/material/styles/styled';
import { Link } from 'react-router-dom';

import c4ads_logo from '../../static/logos/c4ads_logo.png';
import styles from './Footer.module.css';

const StyledFooter = styled('footer')(({ theme }) => ({
    width: '100%',
    height: 92,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2, 4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& a': {
        fontSize: theme.typography.caption.fontSize,
        color: theme.palette.text.secondary,
        textDecoration: 'none',
    },
}));

const DescriptionBox = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(3),
    maxWidth: 600,
    alignItems: 'center',
}));

export default function Footer() {
    return (
        <StyledFooter className={styles.footerContainer}>
            <DescriptionBox className={styles.description}>
                <Link to="https://c4ads.org">
                    <img id="footer-logo" src={c4ads_logo} alt="c4ads logo" />
                </Link>
            </DescriptionBox>
            <Box>
                <Stack direction="row" spacing={4} className={styles.links}>
                    <Link to="#" className={styles.link}>
                        Privacy policy
                    </Link>
                    <Link to="#" className={styles.link}>
                        Terms and conditions
                    </Link>
                    <Link to="#" className={styles.link}>
                        EULA
                    </Link>
                </Stack>
                <Typography variant="body2" className={styles.organizationName}>
                    © 2023 Center for Advanced Defense Studies, Inc.
                </Typography>
            </Box>
        </StyledFooter>
    );
}
