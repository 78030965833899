import { Box } from '@mui/material';
import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { ControlledAccordion } from '../../../../components/ControlledAccordion';
import { AbsoluteLoader } from '../../../../components/Loader/AbsouteLoader';
import { NavigationSidebar } from '../../../../components/NavigationSidebar';
import { CaseFormType } from '../../../../types';
import { Comments } from './Comments';
import styles from './Forms.module.css';
import { AddButton } from './components/AddButton';
import { FormErrorBoundary } from './components/FormErrorBoundary';
import { FormsHeader } from './components/FormsHeader';
import { FormsContextProvider } from './context/FormsContext';
import { SectionKey } from './context/types';
import { CourtCaseForm } from './sections/CourtCaseForm';
import { Defendants } from './sections/Defendants';
import { Hearings } from './sections/Hearings';
import { Seizures } from './sections/Seizures';
import { useFormsStore } from './store';
import { sectionsStateStorage } from './utils/sectionsStateStorageUtils';

interface FormsProps {
    caseForm: CaseFormType;
}

interface SectionItem {
    key: SectionKey;
    id: string;
    href: string;
    label: string;
    title: string;
    content: ReactNode;
}

export const FormsContent = ({ caseForm }: FormsProps) => {
    const sectionsStorage = useRef(sectionsStateStorage(caseForm.id)).current;
    const sectionFormsStatusState = useFormsStore((state) => state.sectionFormsStatusState);
    const sectionFormsStatusTrigger = useFormsStore((state) => state.sectionFormsStatusTrigger);
    const isLoading = useFormsStore((state) => state.isLoading);
    const editMode = useFormsStore((state) => state.editMode);
    const sectionFormsInited = useFormsStore((state) => state.sectionFormsInited);
    const sidebarRef = useRef<HTMLDivElement>(null);
    const [lastActiveSection, setLastActiveSection] = useState(sectionsStorage.getLastActiveSection());
    const [activeSections, setActiveSections] = useState(sectionsStorage.getActiveSections());
    const [defendantNewKey, setDefendantNewKey] = useState<string>();
    const [seizureNewKey, setSeizureNewKey] = useState<string>();
    const [hearingNewKey, setHearingNewKey] = useState<string>();

    const scrollToSection = (key?: SectionKey) => {
        if (!key) {
            return;
        }

        const element = document.querySelector(`#${key}`);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }
    };

    useEffect(() => {
        setActiveSections((prev) => {
            const sectionsWithError = Object.entries(sectionFormsStatusState)
                .filter(([_section, value]) => {
                    return !value.isValid;
                })
                .map(([sectionKey]) => sectionKey);

            const mergedSections = new Set([...prev, ...sectionsWithError]);
            return Array.from(mergedSections) as SectionKey[];
        });
    }, [JSON.stringify(sectionFormsStatusState), sectionFormsStatusTrigger]);

    useEffect(() => {
        if (sectionFormsInited === caseForm.id) {
            scrollToSection(lastActiveSection);
        }
    }, [sectionFormsInited]);

    useEffect(() => {
        const onScroll = () => {
            let offset = 88 - (document?.scrollingElement?.scrollTop ?? 0);
            if (offset < 0) {
                offset = 0;
            }

            if (sidebarRef.current) {
                sidebarRef.current.style.maxHeight = `calc(100vh - ${offset}px)`;
            }
        };

        onScroll();
        document.addEventListener('scroll', onScroll);
        return () => {
            document.removeEventListener('scroll', onScroll);
        };
    }, []);

    const defendantAddNew = () => {
        setDefendantNewKey(uuidv4());
    };

    const seizureAddNew = () => {
        setSeizureNewKey(uuidv4());
    };

    const hearingAddNew = () => {
        setHearingNewKey(uuidv4());
    };

    const sections = useMemo(() => {
        return [
            {
                key: 'court-case',
                id: 'court-case',
                label: 'Court Case',
                title: 'Court Case',
                content: (
                    <FormErrorBoundary id={caseForm.id} sectionKey="court-case">
                        <CourtCaseForm />
                    </FormErrorBoundary>
                ),
            },
            {
                key: 'seizures',
                id: 'seizures',
                label: 'Seizures',
                title: 'Seizures',
                content: (
                    <FormErrorBoundary id={caseForm.id} sectionKey="seizures">
                        <Seizures seizureFormKey={seizureNewKey} />
                    </FormErrorBoundary>
                ),
                extraRightComponent: editMode === 'edit' && activeSections.includes('seizures') && (
                    <AddButton stopPropagation title="+ Add new" onClick={seizureAddNew} />
                ),
            },
            {
                key: 'defendants',
                id: 'defendants',
                label: 'Defendants',
                title: 'Defendants',
                content: (
                    <FormErrorBoundary id={caseForm.id} sectionKey="defendants">
                        <Defendants defendantFormKey={defendantNewKey} />
                    </FormErrorBoundary>
                ),
                extraRightComponent: editMode === 'edit' && activeSections.includes('defendants') && (
                    <AddButton stopPropagation title="+ Add new" onClick={defendantAddNew} />
                ),
            },
            {
                key: 'hearings',
                id: 'hearings',
                label: 'Hearings',
                title: 'Hearings',
                content: (
                    <FormErrorBoundary id={caseForm.id} sectionKey="hearings">
                        <Hearings hearingFormKey={hearingNewKey} />
                    </FormErrorBoundary>
                ),
                extraRightComponent: editMode === 'edit' && activeSections.includes('hearings') && (
                    <AddButton stopPropagation title="+ Add new" onClick={hearingAddNew} />
                ),
            },
        ] as SectionItem[];
    }, [editMode, activeSections, defendantNewKey, seizureNewKey, hearingNewKey]);

    const onActiveItem = (key: SectionKey) => {
        setActiveSections((prev) => {
            return prev.filter((sectionKey) => sectionKey !== key).concat([key]);
        });
        setLastActiveSection(key);
        sectionsStorage.openSection(key);
        scrollToSection(key);
    };

    const onDisabledItem = (key: SectionKey) => {
        setActiveSections((prev) => {
            return prev.filter((sectionKey) => sectionKey !== key);
        });
        sectionsStorage.closeSection(key);
    };

    const onActiveLink = (key: SectionKey | undefined) => {
        setActiveSections((prev) => {
            return prev.filter((item) => item !== key).concat(key ? [key] : []);
        });
        setLastActiveSection(key);
        sectionsStorage.openSection(key);
        scrollToSection(key);
    };

    const sectionErrors = useMemo(() => {
        return Object.entries(sectionFormsStatusState)
            .filter(([_section, { isValid }]) => !isValid)
            .map(([sectionKey]) => {
                return {
                    key: sectionKey,
                    errorMessage: 'This section contains missing or invalid fields.',
                };
            });
    }, [sectionFormsStatusState]);

    return (
        <Box display="flex" className={styles.container}>
            {isLoading && <AbsoluteLoader className={styles.loader} />}
            <Box display="flex" flexDirection="column" className={styles.sidebar} ref={sidebarRef}>
                <NavigationSidebar activeLink={lastActiveSection} links={sections} onActiveLink={onActiveLink} />
                <Comments id={caseForm.id} comments={caseForm.comments.slice()} />
            </Box>
            <Box className={styles.contentContainer}>
                <FormsHeader />
                {sectionFormsInited === caseForm.id && (
                    <ControlledAccordion
                        detailsClassName={styles.contentDetails}
                        className={styles.content}
                        items={sections}
                        activeItems={activeSections}
                        onActiveItem={onActiveItem}
                        onDisabledItem={onDisabledItem}
                        errors={sectionErrors}
                        hideUnderline
                    />
                )}
            </Box>
        </Box>
    );
};

export const Forms = ({ caseForm }: FormsProps) => {
    return (
        <FormsContextProvider id={caseForm.id} caseForm={caseForm}>
            <FormsContent caseForm={caseForm} />
        </FormsContextProvider>
    );
};
