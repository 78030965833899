import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { ReactNode, useEffect } from 'react';

import styles from './RadioGroup.module.css';

interface Option {
    label: ReactNode;
    value: string;
}

interface RadioGroupProps {
    options: Option[];
    onChange?: (option?: string) => void;
    value?: string;
    disabledOptions?: string[];
}

export const CustomRadioGroup = ({ onChange, options, value, disabledOptions }: RadioGroupProps) => {
    useEffect(() => {
        if (!value) {
            onChange && onChange(options[0]?.value);
        }
    }, []);

    return (
        <FormControl>
            <RadioGroup
                className={styles.radioGroup}
                row
                value={value ?? options[0]?.value}
                onChange={(_e, value) => {
                    onChange && onChange(value);
                }}
            >
                {options.map((option) => (
                    <FormControlLabel
                        disabled={(disabledOptions ?? []).includes(option.value)}
                        key={option.value}
                        value={option.value}
                        control={
                            <Radio
                                disabled={(disabledOptions ?? []).includes(option.value)}
                                color="primary"
                                size="small"
                            />
                        }
                        label={option.label}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
};
