import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import DELETE_USER from '../../../../../api/apollo/mutation/DeleteUser';
import { DeleteDialog } from './DeleteDialog';

interface DeleteUserDialogInterface {
    deleteUserID: string | undefined;
    open: boolean;
    setOpen: (open: boolean) => void;
    onClick: () => void;
}

export const DeleteUserDialog = ({ deleteUserID, open, setOpen, onClick }: DeleteUserDialogInterface) => {
    const [deleteUser] = useMutation(DELETE_USER);

    const onDelete = useCallback(() => {
        onClick();

        if (deleteUserID) {
            deleteUser({ variables: { user: { id: deleteUserID } } });
        }
        setOpen(false);
    }, [deleteUserID]);

    return (
        <DeleteDialog
            open={open}
            setOpen={setOpen}
            title="Remove User"
            description="Are you sure you want to remove this user? This action is irreversible."
            buttonTitle="Remove User"
            onDelete={onDelete}
        />
    );
};
