import { Avatar, AvatarGroup } from '@c4ads/c4blocks';
import { Tooltip } from '@mui/material';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

import { tableHelpers } from '../../../../helpers/tableHelpers';
import { Hearing } from '../../../../types/Hearing';
// import { Defendant } from '../../../../types';
import { Person } from '../../../../types/Person';

// import { DataGridHearing } from './index';

// const ProsecutorCell = ({ value }: { value: string[] }) => {
//     return (
//         <AvatarGroup max={4}>
//             {value.map((v, i) => {
//                 return (
//                     <Tooltip key={i} title={v}>
//                         <Avatar>{v[0]}</Avatar>
//                     </Tooltip>
//                 );
//             })}
//         </AvatarGroup>
//     );
// };

// const AgencyCell = ({ value }: { value: string[] }) => {
//     return (
//         <AvatarGroup max={4}>
//             {value.map((v, i) => {
//                 return (
//                     <Tooltip key={i} title={v}>
//                         <Avatar>{v[0]}</Avatar>
//                     </Tooltip>
//                 );
//             })}
//         </AvatarGroup>
//     );
// };

const DefendantsCell = ({ value }: { value: Partial<Person>[] | null }) => {
    // const defendants = value ? value.split(', ') : [];
    return (
        <AvatarGroup max={4}>
            {(value ?? []).map(({ firstName = '', lastName = '' }, i) => {
                return (
                    <Tooltip key={i} title={`${firstName} ${lastName}`}>
                        <Avatar>{firstName[0] + lastName[0]}</Avatar>
                    </Tooltip>
                );
            })}
        </AvatarGroup>
    );
};

const columns: GridColDef<Hearing>[] = [
    {
        field: 'docket',
        type: 'string',
        headerName: 'Docket',
        headerAlign: 'center',
        align: 'left',
        groupable: false,
        width: 140,
        valueGetter: ({ row }: GridValueGetterParams<string>) => row.courtCase?.docket,
    },
    {
        field: 'date',
        type: 'date',
        headerName: 'Date',
        headerAlign: 'center',
        align: 'center',
        groupable: false,
        width: 140,
        valueFormatter: tableHelpers.dateFormatter,
    },
    {
        field: 'administrativeLevel1',
        type: 'string',
        headerName: 'District',
        headerAlign: 'center',
        align: 'left',
        groupable: false,
        width: 200,
        valueGetter: ({ row }: GridValueGetterParams<string>) => row.court?.administrativeLevel1?.name || '--',
    },
    {
        field: 'courtName',
        type: 'string',
        headerName: 'Court',
        headerAlign: 'center',
        align: 'left',
        groupable: false,
        width: 140,
        valueGetter: ({ row }: GridValueGetterParams<string>) => row.court?.name,
    },
    {
        field: 'courtType',
        type: 'string',
        headerName: 'Court Type',
        headerAlign: 'center',
        align: 'left',
        groupable: false,
        width: 140,
        valueGetter: ({ row }: GridValueGetterParams<string>) => row.court?.tier,
    },
    {
        field: 'stage',
        type: 'string',
        headerName: 'Stage',
        headerAlign: 'center',
        align: 'left',
        groupable: false,
        width: 180,
        renderCell: ({ value }) => tableHelpers.renderString(value),
    },
    {
        field: 'happened',
        type: 'boolean',
        headerName: 'Happened',
        headerAlign: 'center',
        align: 'center',
        groupable: false,
        width: 140,
        renderCell: ({ value }) => tableHelpers.renderBoolean(value),
    },
    {
        field: 'judge',
        type: 'string',
        headerName: 'Magistrate',
        headerAlign: 'center',
        align: 'left',
        groupable: false,
        width: 140,
        valueGetter: ({ row }: GridValueGetterParams<string>) =>
            `${row.judge?.firstName} ${row.judge?.lastName} ${
                row.judge?.grade ? '(' + row.judge.grade + ')' : ''
            }`.trim(),
    },
    {
        field: 'courtClerk',
        type: 'string',
        headerName: 'Court Clerk',
        headerAlign: 'center',
        align: 'left',
        groupable: false,
        width: 140,
        valueGetter: ({ row }: GridValueGetterParams<string>) =>
            `${row.courtClerk?.firstName} ${row.courtClerk?.lastName}`.trim(),
    },
    {
        field: 'defendantsPresent',
        type: 'string',
        headerName: 'Defendants Present',
        headerAlign: 'center',
        align: 'left',
        groupable: false,
        width: 200,
        valueGetter: ({ row }) =>
            row.defendantsPresent?.map(({ firstName, lastName }) => `${firstName} ${lastName}`).join(' / '),
        renderCell: ({ row }) => <DefendantsCell value={row.defendantsPresent} />,
    },
    {
        field: 'defenseAttorneysPresent',
        type: 'string',
        headerName: 'Defense Attorney(s)',
        headerAlign: 'center',
        align: 'left',
        groupable: false,
        width: 140,
        valueGetter: ({ row }: GridValueGetterParams<string>) =>
            row.defenseAttorneysPresent
                ?.map(({ firstName, lastName }) => `${firstName} ${lastName}`.trim())
                .join(' / '),
        // renderCell: ({ value }) => <ProsecutorCell value={value} />,
    },
    {
        field: 'prosecutorsPresent',
        type: 'string',
        headerName: 'Prosecutor(s)',
        headerAlign: 'center',
        align: 'left',
        groupable: false,
        width: 140,
        valueGetter: ({ row }: GridValueGetterParams<string>) =>
            row.prosecutorsPresent?.map(({ firstName, lastName }) => `${firstName} ${lastName}`.trim()).join(' / '),
        // renderCell: ({ value }) => <ProsecutorCell value={value} />,
    },
    {
        field: 'notes',
        type: 'string',
        headerName: 'Notes',
        headerAlign: 'center',
        width: 200,
        groupable: false,
    },
];

export default columns;
