import { Box, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { z } from 'zod';

import { useFormContext } from '../../context/FormsContext';
import { useFormsStore } from '../../store';
import { Defendant, schema } from './Defendant';
import styles from './Defendants.module.css';

interface DefendantsFormProps {
    defendantFormKey?: string;
}

interface DefendantForm {
    key: string;
    payload?: z.infer<typeof schema>;
}

const preparePayload = (payload: any) => {
    if (!payload) {
        return [];
    }

    const currentPayload = payload as Record<string, z.infer<typeof schema>>;

    return Object.entries(currentPayload).map(([key, payload]) => {
        return {
            key,
            payload,
        };
    });
};

export const Defendants = ({ defendantFormKey }: DefendantsFormProps) => {
    const { setSectionStatus } = useFormContext();
    const payload = useFormsStore((state) => state.sectionForms.defendants?.payload);
    const setSectionForm = useFormsStore((state) => state.setSectionForm);
    const setSectionFormToRemove = useFormsStore((state) => state.setSectionFormToRemove);
    const [defendantForms, setDefendantForms] = useState<DefendantForm[]>(preparePayload(payload));
    const [errors, setErrors] = useState<Record<string, boolean>>({});
    const currentPayload = useRef(payload);

    useEffect(() => {
        const errorsCount = Object.values(errors).filter(Boolean).length;
        setSectionStatus('defendants', errorsCount === 0);
    }, [errors]);

    useEffect(() => {
        const keys = defendantForms.map((form) => form.key);

        setErrors((prev) => {
            const cloned = { ...prev };
            Object.keys(cloned).forEach((key) => {
                if (!keys.includes(key)) {
                    delete cloned[key];
                }
            });

            return cloned;
        });
    }, [defendantForms]);

    useEffect(() => {
        setDefendantForms(preparePayload(payload));
        currentPayload.current = payload;
    }, [payload]);

    useEffect(() => {
        if (defendantFormKey) {
            setSectionForm('defendants', { ...payload, [defendantFormKey]: {} });
        }
    }, [defendantFormKey]);

    const onChange = (key: string, result?: z.infer<typeof schema>) => {
        setSectionForm('defendants', { ...currentPayload.current, [key]: result });
    };

    const onDelete = (key: string) => {
        const clone = { ...currentPayload.current };
        if (clone?.id) {
            setSectionFormToRemove('defendants', clone.id);
        }

        delete clone[key];

        setSectionForm('defendants', clone);
    };

    return (
        <Box display="flex" flexDirection="column" className={styles.wrapper}>
            {!defendantForms.length && <Typography className={styles.noText}>No Defendants forms.</Typography>}
            {defendantForms.map((item) => (
                <Defendant
                    key={item.key}
                    defaultValues={item.payload}
                    onChange={(value) => {
                        onChange(item.key, value);
                    }}
                    onDelete={() => {
                        onDelete(item.key);
                    }}
                    onValid={(isValid) => {
                        setErrors((prev) => {
                            return { ...prev, [item.key]: !isValid };
                        });
                    }}
                />
            ))}
        </Box>
    );
};
