import { zodResolver } from '@hookform/resolvers/zod';
import React, { memo, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

import { FormAutocomplete } from '../../../components/Autocomplete';
import { DeleteButton } from '../../../components/DeleteButton';
import { FormCheckbox } from '../../../components/FormCheckbox';
import { FormItem } from '../../../components/FormItem';
import { FormRow, FormRowAction } from '../../../components/FormTemplates/FormRow';
import { EMPTY_PLACEHOLDER } from '../../../constants';
import { useFormContext } from '../../../context/FormsContext';
import { useFormsStore } from '../../../store';

export const chargeSchema = z.object({
    id: z.coerce.string().optional(),
    crimeId: z.coerce.string(),
    withdrawn: z.boolean(),
    plea: z.string().optional().nullable(),
    verdict: z.string().optional().nullable(),
});

export type ChargeType = z.infer<typeof chargeSchema>;

interface ChargeComponentProps {
    editMode?: boolean;
    defaultValues?: Partial<ChargeType>;
    onDelete: () => void;
    onChange: (value?: Partial<ChargeType>) => void;
    onError?: (error: boolean) => void;
    onOpen?: () => void;
}

const pleaOptions = [
    { name: 'Guilty', id: 'Guilty' },
    { name: 'Not Guilty', id: 'Not_Guilty' },
    { name: 'No Contest', id: 'No_Contest' },
];

const verdictOptions = [
    { name: 'Guilty', id: 'Guilty' },
    { name: 'Not Guilty', id: 'Not_Guilty' },
];

export const ChargeComponent = memo(
    ({ editMode, defaultValues, onDelete, onChange, onOpen, onError }: ChargeComponentProps) => {
        const { onSubmitListener } = useFormContext();
        const crimeOptions = useFormsStore((state) => state.lookups.crimes);
        const {
            control,
            formState: { errors },
            handleSubmit,
            watch,
        } = useForm<z.infer<typeof chargeSchema>>({
            resolver: zodResolver(chargeSchema),
            defaultValues,
        });

        useEffect(() => {
            onError?.(!!Object.keys(errors).length);
        }, [JSON.stringify(errors)]);

        useEffect(() => {
            const { unsubscribe } = watch((data) => {
                onChange(data);
            });

            return unsubscribe;
        }, []);

        useEffect(() => {
            return onSubmitListener('defendants', () => {
                return new Promise((resolve) => {
                    handleSubmit(
                        () => {
                            resolve({ type: 'validate', result: true });
                        },
                        () => {
                            onOpen?.();
                            resolve({ type: 'validate', result: true });
                        }
                    )();
                });
            });
        }, []);

        const getCrimeOption = (id?: string) => {
            return crimeOptions.find((item) => `${item?.id}` === `${id}`);
        };

        const getPleaOption = (id?: string | null) => {
            return pleaOptions.find((item) => item?.id === id);
        };

        const getVerdictOption = (id?: string | null) => {
            return verdictOptions.find((item) => item?.id === id);
        };

        return (
            <FormRow gridTemplateColumns="4fr 1fr 2fr 2fr auto">
                <Controller
                    name="crimeId"
                    control={control}
                    render={({ field }) => (
                        <FormItem
                            editMode={editMode}
                            label="Crime"
                            errorMessage={errors.crimeId?.message}
                            value={field.value}
                            onChange={(value) => {
                                field.onChange({
                                    target: { value },
                                });
                            }}
                            renderValue={(value) =>
                                getCrimeOption(value)
                                    ? `${getCrimeOption(value)?.legislation} (${getCrimeOption(value)?.part})`
                                    : EMPTY_PLACEHOLDER
                            }
                            renderInput={(value) => (
                                <FormAutocomplete
                                    disablePortal={false}
                                    errorMessage={errors.crimeId?.message}
                                    label={null}
                                    options={crimeOptions}
                                    getOptionLabel={(option) =>
                                        getCrimeOption(option.id)
                                            ? `${getCrimeOption(option.id)?.legislation} (${
                                                  getCrimeOption(option.id)?.part
                                              })`
                                            : EMPTY_PLACEHOLDER
                                    }
                                    value={getCrimeOption(value)}
                                    onChange={(_e, newValue) => {
                                        field.onChange({
                                            target: { value: newValue?.id },
                                        });
                                    }}
                                />
                            )}
                        />
                    )}
                />
                <Controller
                    name="withdrawn"
                    control={control}
                    render={({ field }) => (
                        <FormItem
                            editMode={editMode}
                            label="Withdrawn"
                            value={field.value}
                            renderValue={(value) => {
                                return value ? 'Yes' : 'No';
                            }}
                            renderInput={(value) => (
                                <FormCheckbox
                                    checked={value}
                                    onChange={(value) => {
                                        field.onChange({ target: { value } });
                                    }}
                                />
                            )}
                        />
                    )}
                />
                <Controller
                    name="plea"
                    control={control}
                    render={({ field }) => (
                        <FormItem
                            errorMessage={errors.plea?.message}
                            editMode={editMode}
                            label="Plea"
                            value={field.value}
                            onChange={(value) => {
                                field.onChange({
                                    target: { value },
                                });
                            }}
                            renderValue={(value) => value || EMPTY_PLACEHOLDER}
                            renderInput={(value) => (
                                <FormAutocomplete
                                    disablePortal={false}
                                    errorMessage={errors.plea?.message}
                                    label={null}
                                    options={pleaOptions}
                                    getOptionLabel={(option) => option.name || EMPTY_PLACEHOLDER}
                                    value={getPleaOption(value)}
                                    onChange={(_e, newValue) => {
                                        field.onChange({
                                            target: { value: newValue?.id },
                                        });
                                    }}
                                />
                            )}
                        />
                    )}
                />
                <Controller
                    name="verdict"
                    control={control}
                    render={({ field }) => (
                        <FormItem
                            errorMessage={errors.verdict?.message}
                            editMode={editMode}
                            label="Verdict"
                            value={field.value}
                            onChange={(value) => {
                                field.onChange({
                                    target: { value },
                                });
                            }}
                            renderValue={(value) => value || EMPTY_PLACEHOLDER}
                            renderInput={(value) => (
                                <FormAutocomplete
                                    disablePortal={false}
                                    errorMessage={errors.verdict?.message}
                                    label={null}
                                    options={verdictOptions}
                                    getOptionLabel={(option) => option.name || EMPTY_PLACEHOLDER}
                                    value={getVerdictOption(value)}
                                    onChange={(_e, newValue) => {
                                        field.onChange({
                                            target: { value: newValue?.id },
                                        });
                                    }}
                                />
                            )}
                        />
                    )}
                />
                {editMode && (
                    <FormRowAction>
                        <DeleteButton color="gray" onClick={onDelete} />
                    </FormRowAction>
                )}
            </FormRow>
        );
    },
    /*
        This optimization is possible because the props we pass do not necessarily need to be always up-to-date;
        For such components, initialization or rare update is sufficient
     */
    (prevProps, nextProps) => prevProps.editMode === nextProps.editMode
);
