import { useMutation } from '@apollo/client';
import { Button } from '@c4ads/c4blocks';
import { zodResolver } from '@hookform/resolvers/zod';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle, FormHelperText, IconButton, TextField } from '@mui/material';
import React, { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { CREATE_CASE_FORM } from '../../../../../api/apollo/mutation';
import { GET_CASE_FORMS } from '../../../../../api/apollo/query';
import { AbsoluteLoader } from '../../../../../components/Loader/AbsouteLoader';
import { StyledForm } from '../../../../../components/StyledForm';
import { CaseFormType } from '../../../../../types';
import styles from './CreateCaseForm.module.css';

interface CreateCaseFormProps {
    caseForms: CaseFormType[];
}

const schema = z.object({
    title: z.string().min(2, {
        message: 'Title must be at least 2 characters.',
    }),
});

export const CreateCaseFormButton = ({ caseForms }: CreateCaseFormProps) => {
    const [createCaseForm, { loading }] = useMutation<{ createCaseForm: { caseForm: CaseFormType | null } }>(
        CREATE_CASE_FORM,
        {
            refetchQueries: [
                {
                    query: GET_CASE_FORMS,
                },
            ],
        }
    );
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const {
        control,
        formState: { errors },
        setError,
        setValue,
        handleSubmit,
    } = useForm<z.infer<typeof schema>>({
        resolver: zodResolver(schema),
        defaultValues: {
            title: '',
        },
    });

    const onSubmit: SubmitHandler<any> = async ({ ...data }) => {
        setError('title', { type: 'connection', message: undefined });
        if (!checkAvailability(data.title)) {
            return;
        }

        const { data: result, errors } = await createCaseForm({
            variables: {
                input: {
                    title: data.title,
                },
            },
        });

        if (errors) {
            setError('title', { type: 'connection', message: 'Something went wrong try again' });
            return;
        }
        setValue('title', '');
        handleClose();
        navigate(`/case-forms/${result?.createCaseForm?.caseForm?.id}`);
    };

    const checkAvailability = (title: string) => {
        const titles = caseForms.map((item) => item.title);

        if (titles.includes(title)) {
            setError('title', { type: 'duplicate', message: 'Case form with the current name already exists' });
        } else {
            setError('title', {});
        }

        return !titles.includes(title);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const openDialog = () => {
        setOpen(true);
    };

    return (
        <>
            <Button color="primary" className={styles.btn} onClick={openDialog}>
                <AddIcon className={styles.btnIcon} sx={{ color: '#ffffff' }} />
                <span>Create Case Form</span>
            </Button>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
                {loading && <AbsoluteLoader />}
                <IconButton className={styles.closeIcon} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
                <DialogTitle>Create Case Form</DialogTitle>
                <DialogContent>
                    <StyledForm onSubmit={handleSubmit(onSubmit)}>
                        <Controller
                            name="title"
                            control={control}
                            rules={{
                                required: 'Title is required.',
                            }}
                            render={({ field }) => <TextField placeholder="Enter case form title" {...field} />}
                        />
                        {Object.keys(errors).filter((key) => !!errors[key].message).length !== 0 &&
                            Object.keys(errors).map((key) => (
                                <FormHelperText key={key} color="error">
                                    {errors[key]?.message as string}
                                </FormHelperText>
                            ))}
                        <Button type="submit" color="secondary" variant="contained">
                            Create
                        </Button>
                    </StyledForm>
                </DialogContent>
            </Dialog>
        </>
    );
};
