// @ts-nocheck
import FilterListIcon from '@mui/icons-material/FilterList';
import { Button, Fade, Typography } from '@mui/material';
import {
    GridCsvExportMenuItem,
    GridExcelExportMenuItem,
    GridPrintExportMenuItem,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExportContainer,
    GridToolbarQuickFilter,
} from '@mui/x-data-grid-premium';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { usePostHog } from 'posthog-js/react';
import { ReactNode } from 'react';

import { captureExportTable } from '../../../posthog';
import styles from './TableToolbar.module.css';

interface TableToolbarProps {
    title?: string;
    showTitle?: boolean;
    csvExportEnabled?: boolean;
    excelExportEnabled?: boolean;
    pdfExportEnabled?: boolean;
    onAdvancedFilterClick?: () => void;
    advancedFilterActive: boolean;
    extraComponent?: ReactNode;
    tableApi: GridApiPremium;
}

const TableToolbar = ({
    title,
    showTitle = false,
    csvExportEnabled = true,
    pdfExportEnabled = false,
    excelExportEnabled = true,
    onAdvancedFilterClick,
    advancedFilterActive,
    extraComponent,
    tableApi,
}: TableToolbarProps) => {
    const posthog = usePostHog();

    const csvOnExport = () => {
        const { eventName, payload } = captureExportTable(title, 'csv');
        posthog?.capture(eventName, payload);
        tableApi.exportDataAsCsv({ fileName: `Wildlife Defense Platform_${title}` });
    };

    const excelOnExport = () => {
        const { eventName, payload } = captureExportTable(title, 'excel');
        posthog?.capture(eventName, payload);
        tableApi.exportDataAsExcel({ fileName: `Wildlife Defense Platform_${title}` });
    };

    const pdfOnExport = () => {
        const { eventName, payload } = captureExportTable(title, 'pdf');
        posthog?.capture(eventName, payload);
        tableApi.exportDataAsPrint({ fileName: `Wildlife Defense Platform_${title}` });
    };

    return (
        <GridToolbarContainer sx={{ border: 'none', justifyContent: 'space-between', px: 2 }}>
            <GridToolbarQuickFilter />
            <Fade in={showTitle} timeout={{ enter: 150, exit: 0 }}>
                <Typography variant="subtitle1" sx={{ color: 'var(--color-primary)' }}>
                    {title}
                </Typography>
            </Fade>
            <div className={styles.tooltipBlock}>
                {onAdvancedFilterClick && (
                    <Button variant="text" sx={{ color: 'var(--color-primary)' }} onClick={onAdvancedFilterClick}>
                        <FilterListIcon className={styles.advancedFilterIcon} />
                        <span className={styles.advancedFilterText}>Advanced filter</span>
                        {advancedFilterActive && <span className={styles.dot} />}
                    </Button>
                )}
                {extraComponent}
                <GridToolbarDensitySelector placeholder="" sx={{ color: 'var(--color-primary)' }} />
                <GridToolbarColumnsButton placeholder="" sx={{ color: 'var(--color-primary)' }} />
                {csvExportEnabled && (
                    <GridToolbarExportContainer
                        placeholder=""
                        sx={{ color: 'var(--color-primary)' }}
                        disabled={!csvExportEnabled}
                    >
                        {csvExportEnabled && (
                            <GridCsvExportMenuItem
                                onClick={csvOnExport}
                                options={{
                                    fileName: `Wildlife Defense Platform_${title}`,
                                }}
                            />
                        )}
                        {excelExportEnabled && (
                            <GridExcelExportMenuItem
                                onClick={excelOnExport}
                                options={{
                                    fileName: `Wildlife Defense Platform_${title}`,
                                }}
                            />
                        )}
                        {pdfExportEnabled && (
                            <GridPrintExportMenuItem
                                onClick={pdfOnExport}
                                options={{
                                    fileName: `Wildlife Defense Platform_${title}`,
                                }}
                            />
                        )}
                    </GridToolbarExportContainer>
                )}
            </div>
        </GridToolbarContainer>
    );
};

export default TableToolbar;
