import { Select } from '@c4ads/c4blocks';
import {
    FormControl,
    MenuItem,
    ListSubheader as MuiListSubheader,
    ListSubheaderProps as MuiListSubheaderProps,
    Typography,
} from '@mui/material';
import { useEffect, useMemo } from 'react';
import { Controller } from 'react-hook-form';

import { DependentVariable } from '../../types/DependentVariable';
import { useQueryBuilderContext } from './context';

function ListSubheader(props: MuiListSubheaderProps & { muiSkipListHighlight: boolean }) {
    const { muiSkipListHighlight, ...other } = props;
    return <MuiListSubheader {...other} />;
}

const Y_OPTIONS: DependentVariable[] = [
    'COURT_CASES',
    'OUTSTANDING_COURT_CASES',
    'SEIZURES',
    'DEFENDANTS',
    'CHARGES',
    'JUDGEMENTS',
    'BAIL_RATE',
    'CONVICTIONS',
    'CONVICTION_RATE',
    'CUSTODIAL_SENTENCE_RATE',
    'DEFENSE_ATTORNEYS_PER_CASE',
    'CASE_DURATION',
    'FINE',
    'CUSTODIAL_MONTHS',
    'SUSPENDED_FINE',
    'SUSPENDED_MONTHS',
    'SEIZED_COMMODITIES',
    'SEIZURE_WEIGHT',
    'SEIZURE_VALUE',
];

export default function VariableFields({ visualization, control, setValue, renderValue, watch }) {
    const { lookups } = useQueryBuilderContext();
    const selectedX = watch('x');
    const selectedY = watch('y');

    const selectedOperation = watch('operation');

    const X_OPTIONS = useMemo(() => {
        const options: Array<{ isGroupHeader: boolean; value: string }> = [];

        if (visualization === 'MAP') {
            if (!['ADMINISTRATIVE_LEVEL_1', 'REGION'].includes(selectedX)) {
                setValue('x', 'ADMINISTRATIVE_LEVEL_1');
            }
            options.push({ isGroupHeader: false, value: 'ADMINISTRATIVE_LEVEL_1' });

            if ((lookups?.regions.values ?? []).length > 0) {
                options.push({ isGroupHeader: false, value: 'REGION' });
            }
        } else {
            if (selectedY !== 'OUTSTANDING_COURT_CASES') {
                options.push(
                    { isGroupHeader: true, value: 'Time' },
                    { isGroupHeader: false, value: 'MONTH' },
                    { isGroupHeader: false, value: 'QUARTER' },
                    { isGroupHeader: false, value: 'YEAR' }
                );
            } else {
                if (['Time', 'MONTH', 'QUARTER', 'YEAR'].includes(selectedX)) {
                    setValue('x', 'COURT');
                }
            }

            options.push(
                { isGroupHeader: true, value: 'Location' },
                { isGroupHeader: false, value: 'COURT' },
                { isGroupHeader: false, value: 'ADMINISTRATIVE_LEVEL_1' }
            );

            if ((lookups?.regions.values ?? []).length > 0) {
                options.push({ isGroupHeader: false, value: 'REGION' });
            }

            options.push(
                { isGroupHeader: true, value: 'Trophy' },
                { isGroupHeader: false, value: 'COMMODITY' },
                { isGroupHeader: false, value: 'SPECIES' },
                { isGroupHeader: false, value: 'SPECIES_GROUP' },
                { isGroupHeader: false, value: 'PROTECTION_LEVEL' }
            );
        }

        return options;
    }, [setValue, visualization, selectedY, selectedX, lookups]);

    useEffect(() => {
        switch (selectedX) {
            case 'SPECIES':
                setValue('filters.commodities', []);
                setValue('filters.speciesGroup', []);
                break;
            case 'COMMODITIES':
                setValue('filters.species', []);
                setValue('filters.speciesGroup', []);
                break;
            case 'SPECIES_GROUP':
                setValue('filters.commodities', []);
                setValue('filters.species', []);
                break;
        }
    }, [selectedX, setValue]);

    const getOperationOptions = (selectedX: string, selectedY: string): string[] => {
        switch (selectedY) {
            case 'SEIZURES':
            case 'DEFENDANTS':
            case 'COURT_CASES':
            case 'CHARGES':
                if (['DATE', 'MONTH', 'QUARTER', 'YEAR'].includes(selectedX)) {
                    return ['COUNT', 'CUMULATIVE_COUNT'];
                }
                return ['COUNT'];
            case 'DEFENSE_ATTORNEYS_PER_CASE':
            case 'CASE_DURATION':
            case 'CUSTODIAL_MONTHS':
            case 'FINE':
            case 'SUSPENDED_MONTHS':
            case 'SUSPENDED_FINE':
                return ['MIN', 'MAX', 'MEAN'];
            case 'CONVICTIONS':
                return ['TOTAL', 'CUMULATIVE_TOTAL'];
            case 'SEIZED_COMMODITIES':
            case 'SEIZURE_WEIGHT':
            case 'SEIZURE_VALUE':
                return ['TOTAL', 'CUMULATIVE_TOTAL', 'MIN', 'MAX', 'MEAN'];
            case 'BAIL_RATE':
            case 'CONVICTION_RATE':
            case 'CUSTODIAL_SENTENCE_RATE':
                return ['MEAN'];
            case 'OUTSTANDING_COURT_CASES':
                return ['TOTAL'];
            case 'JUDGEMENTS':
                return ['TOTAL', 'CUMULATIVE_TOTAL'];
            default:
                return [];
        }
    };

    const OPERATION_OPTIONS = useMemo(() => {
        const options = getOperationOptions(selectedX, selectedY);
        const distributionOptions = [
            'CASE_DURATION',
            'FINE',
            'CUSTODIAL_MONTHS',
            'SUSPENDED_FINE',
            'SUSPENDED_MONTHS',
            'SEIZED_COMMODITIES',
            'SEIZURE_WEIGHT',
            'SEIZURE_VALUE',
        ];

        distributionOptions.includes(selectedY) && options.push('DISTRIBUTION');

        return options;
    }, [selectedX, selectedY, setValue, visualization]);

    useEffect(() => {
        if (OPERATION_OPTIONS.length > 0 && selectedOperation && !OPERATION_OPTIONS.includes(selectedOperation)) {
            setValue('operation', OPERATION_OPTIONS[0]);
        }
    }, [OPERATION_OPTIONS, selectedOperation, setValue]);

    return (
        <>
            <Controller
                name="x"
                control={control}
                render={({ field }) => (
                    <FormControl>
                        <Typography variant="overline" color="#9e9e9e">
                            {visualization === 'CHART' ? 'X Variable' : 'Administrative Level'}
                        </Typography>
                        <Select size="small" renderValue={renderValue} variant="outlined" {...field}>
                            {X_OPTIONS.map(({ isGroupHeader, value }: { isGroupHeader: boolean; value: string }) => {
                                return isGroupHeader ? (
                                    <ListSubheader
                                        muiSkipListHighlight
                                        sx={{
                                            color: 'primary.dark',
                                            fontWeight: 'bold',
                                            fontSize: 16,
                                        }}
                                    >
                                        {value}
                                    </ListSubheader>
                                ) : (
                                    <MenuItem
                                        key={value}
                                        value={value}
                                        sx={{
                                            pl: 4,
                                            color: 'text.secondary',
                                            fontSize: 14,
                                        }}
                                    >
                                        {renderValue(value)}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                )}
            />
            <Controller
                name="y"
                control={control}
                render={({ field }) => (
                    <FormControl>
                        <Typography variant="overline" color="#9e9e9e">
                            {visualization === 'CHART' ? 'Y Variable' : 'Variable'}
                        </Typography>
                        <Select size="small" renderValue={renderValue} variant="outlined" {...field}>
                            {Y_OPTIONS.map((option: string) => (
                                <MenuItem key={option} value={option}>
                                    {renderValue(option)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
            />

            {!selectedY.endsWith('_RATE') && (
                <FormControl>
                    <Typography variant="overline" color="#9e9e9e">
                        Operation
                    </Typography>
                    <Controller
                        name="operation"
                        control={control}
                        render={({ field }) => (
                            <FormControl required>
                                <Select
                                    size="small"
                                    renderValue={renderValue}
                                    variant="outlined"
                                    {...field}
                                    disabled={!selectedY}
                                >
                                    {OPERATION_OPTIONS.map((op: string) => (
                                        <MenuItem key={op} value={op}>
                                            {renderValue(op)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    />
                </FormControl>
            )}
        </>
    );
}
