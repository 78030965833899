import { Permission } from '@c4ads/c4auth';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAuth } from '../../api/auth';
import { HeaderContext } from '../../components/Header';
import useWidth from '../../hooks/useWidth';

export default function useSummaryTables() {
    const { user } = useAuth();
    const { table } = useParams();
    const [tables, setTables] = useState<string[]>([]);

    const width = useWidth();

    const { setHide } = useContext(HeaderContext);
    const [shrinkTitle, setShrinkTitle] = useState<boolean>(false);

    const description = useMemo(() => {
        switch (table) {
            case 'court-cases':
                return 'Court cases involving trafficked wildlife.';
            case 'defendants':
                return 'Defendants who were arrested in wildlife seizure cases.';
            case 'seized-commodities':
                return 'Seized commodities grouped by species and origin.';
            default:
                return '';
        }
    }, [table]);

    useEffect(() => {
        const permittedTables: string[] = [];
        if (user) {
            const permission_codenames =
                user.permissions?.map(({ codename }: Permission) => {
                    return codename;
                }) || [];
            if (permission_codenames.includes('view_courtcases')) {
                permittedTables.push('court-cases');
            }
            if (permission_codenames.includes('view_defendants')) {
                permittedTables.push('defendants');
            }
            if (permission_codenames.includes('view_seizures')) {
                permittedTables.push('seized_commodities');
            }
        }
        setTables(permittedTables);
    }, [user]);

    const hideHeaders = useCallback(() => {
        if (width !== 'xl') {
            setHide(true);
            setShrinkTitle(true);
        }
    }, [setHide, width]);

    const showHeaders = () => {
        setHide(false);
        setShrinkTitle(false);
    };

    return {
        tables,
        description,
        shrinkTitle,
        hideHeaders,
        showHeaders,
    };
}
