import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';

import styles from './AddButton.module.css';

interface AddButtonProps {
    buttonText?: string;
    onClick?: () => void;
}

export const AddButton = ({ buttonText, onClick }: AddButtonProps) => {
    return (
        <Button color="secondary" className={styles.btn} onClick={onClick}>
            <AddIcon className={styles.btnIcon} sx={{ color: '#ffffff' }} />
            <span>{buttonText}</span>
        </Button>
    );
};
