import { Typography } from '@mui/material';
import cn from 'classnames';
import { parseISO, format } from 'date-fns';

import styles from './FormItem.module.css';

interface ValueProps {
    content: string;
    className?: string;
}

const DATE_FORMAT = 'LLLL d, yyyy';

function parseValue(content: string) {
    const formatted = content.replace('_', ' ');
    try {
        const parsedDate = parseISO(formatted);
        return format(parsedDate, DATE_FORMAT);
    } catch {
        return formatted;
    }
}

export const Value = ({ content, className }: ValueProps) => {
    const value = parseValue(content);
    return <Typography className={cn(styles.value, className)}>{value}</Typography>;
};
