import { Button } from '@c4ads/c4blocks';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseIcon from '@mui/icons-material/Close';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { Box, Typography } from '@mui/material';
import cn from 'classnames';
import { useMemo, useState } from 'react';

import { useAuth } from '../../../../../../api/auth';
import { CaseFormStatuses } from '../../../../../../constants/local';
import { ReactComponent as EditIcon } from '../../../../../../static/icons/svg/filled/edit.svg';
import { ERROR_CLASSNAME } from '../../constants';
import { useFormContext } from '../../context/FormsContext';
import { useFormsStore } from '../../store';
import styles from './FormsHeader.module.css';
import { RemoveCaseFormModal } from './RemoveCaseFormModal';

export const FormsHeader = () => {
    const { user } = useAuth();
    const caseForm = useFormsStore((state) => state.caseForm);
    const { removeCaseForm } = useFormContext();
    const [removeCaseFormModal, setRemoveCaseFormModal] = useState(false);
    const [removing, setRemoving] = useState(false);

    const permissions = useMemo(() => {
        return user?.permissions || [];
    }, [user]);

    const isEditor = useMemo(() => {
        return permissions.map((permission) => permission.codename).includes('case_form_editor');
    }, [caseForm, user]);

    const isReviewer = useMemo(() => {
        return permissions.map((permission) => permission.codename).includes('case_form_reviewer');
    }, [caseForm, permissions]);

    const isAdmin = useMemo(() => {
        return user?.isAdmin || user?.isSuperuser;
    }, [user]);

    const openRemoveCaseFormModal = () => {
        setRemoveCaseFormModal(true);
    };

    const closeRemoveCaseFormModal = () => {
        setRemoveCaseFormModal(false);
    };

    const onRemove = async () => {
        setRemoving(true);
        await removeCaseForm();
        setRemoving(false);
    };

    return (
        <Box display="flex" alignItems="center" justifyContent="space-between">
            <RemoveCaseFormModal
                open={removeCaseFormModal}
                loading={removing}
                caseFormName={caseForm?.title}
                onClose={closeRemoveCaseFormModal}
                onRemove={onRemove}
            />
            <Typography className={styles.title}>Court Case Record</Typography>
            <Box className={styles.buttons} display="flex" alignItems="center" justifyContent="space-between">
                {isEditor && <Editor />}
                {isReviewer && <Reviewer />}
                {[
                    CaseFormStatuses.Review_Requested,
                    CaseFormStatuses.Changes_Requested,
                    CaseFormStatuses.Approved,
                ].includes(caseForm?.status || '') && (
                    <Box display="flex" alignItems="center" className={styles.infoContainer}>
                        <div className={styles.verticalDivider} />
                        {caseForm?.status === CaseFormStatuses.Review_Requested && (
                            <Box display="flex" alignItems="center" className={styles.reviewRequested}>
                                <WarningAmberRoundedIcon />
                                <Typography>Review Requested</Typography>
                            </Box>
                        )}
                        {caseForm?.status === CaseFormStatuses.Changes_Requested && (
                            <Box display="flex" alignItems="center" className={styles.changesRequested}>
                                <WarningAmberRoundedIcon />
                                <Typography>Changes Requested</Typography>
                            </Box>
                        )}
                        {caseForm?.status === CaseFormStatuses.Approved && (
                            <Box display="flex" alignItems="center" className={styles.approved}>
                                <CheckRoundedIcon />
                                <Typography>Approved</Typography>
                            </Box>
                        )}
                    </Box>
                )}
                {isAdmin && (
                    <Button
                        startIcon={<CloseIcon />}
                        className={styles.button}
                        onClick={openRemoveCaseFormModal}
                        color="secondary"
                        variant="contained"
                    >
                        Remove
                    </Button>
                )}
            </Box>
        </Box>
    );
};

const Reviewer = () => {
    const { approve, requestChanges } = useFormContext();
    const caseForm = useFormsStore((state) => state.caseForm);

    return (
        <>
            {caseForm?.status === CaseFormStatuses.Review_Requested && (
                <>
                    <Button
                        className={cn(styles.button, styles.approveButton)}
                        onClick={approve}
                        color="success"
                        variant="contained"
                    >
                        Approve
                    </Button>
                    <Button
                        endIcon={<EditIcon className={styles.requestChangesIcon} />}
                        className={cn(styles.button, styles.requestChangesButton)}
                        onClick={requestChanges}
                        color="secondary"
                        variant="contained"
                    >
                        Request Changes
                    </Button>
                </>
            )}
        </>
    );
};

const Editor = () => {
    const { edit, save, requestReview } = useFormContext();
    const caseForm = useFormsStore((state) => state.caseForm);
    const editMode = useFormsStore((state) => state.editMode);
    const updateSectionFormsStatusState = useFormsStore((state) => state.updateSectionFormsStatusState);

    const onSave = () => {
        save()
            .then(() => {
                updateSectionFormsStatusState();
            })
            .then(() => {
                document.querySelector(`.${ERROR_CLASSNAME}`)?.scrollIntoView();
            });
    };

    return (
        <>
            {['sync', 'saved'].includes(editMode) && (
                <>
                    <Button
                        startIcon={<EditIcon />}
                        className={cn(styles.button, styles.editButton)}
                        onClick={edit}
                        variant="contained"
                    >
                        Edit
                    </Button>
                    {[CaseFormStatuses.Draft, CaseFormStatuses.Changes_Requested].includes(caseForm?.status || '') && (
                        <Button className={styles.button} onClick={requestReview} color="secondary" variant="contained">
                            Request Review
                        </Button>
                    )}
                </>
            )}
            {['edit'].includes(editMode) && (
                <Button className={styles.button} onClick={onSave} color="secondary" variant="contained">
                    Save
                </Button>
            )}
        </>
    );
};
