import { gql } from '@apollo/client';

const GET_SEIZURES = gql`
    query seizures {
        seizures {
            id
            date
            locationName
            courtCases {
                id
                docket
            }

            administrativeLevel1 {
                id
                name
            }
            officers {
                id
                firstName
                lastName
            }
            seizedCommodities {
                id
                species {
                    id
                    commonName
                }
                unit
                originName
                protectionLevel {
                    label
                }
                count
                weightGrams
                value
                valueCurrency
            }
        }
    }
`;

export default GET_SEIZURES;
