import { MenuItem, Select } from '@mui/material';
import { ReactNode, useEffect } from 'react';

import { useUUID } from '../../../../../../hooks/useUUID';

interface Option {
    value: string;
    label: ReactNode;
}

interface FormSelectProps {
    label: ReactNode;
    value?: string;
    options: Option[];
    onChange?: (value?: string) => void;
    onBlur?: () => void;
    onFocus?: () => void;
    firstIsDefault?: boolean;
    className?: string;
}

export const FormSelect = ({
    label,
    options,
    onChange: propOnChange,
    onFocus,
    onBlur,
    firstIsDefault = true,
    value = firstIsDefault ? options?.[0]?.value : undefined,
    className,
}: FormSelectProps) => {
    const [keys, setCount] = useUUID(options.length);

    useEffect(() => {
        propOnChange?.(value);
    }, []);

    useEffect(() => {
        setCount(options.length);
    }, [options]);

    const onChange = (newValue: string) => {
        propOnChange?.(newValue);
    };

    return (
        <Select
            onBlur={onBlur}
            onFocus={onFocus}
            value={value}
            label={label}
            onChange={(e) => {
                onChange(e.target.value);
            }}
            className={className}
        >
            {options.map((option, index) => (
                <MenuItem key={keys[index]} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </Select>
    );
};
