import { useQuery } from '@apollo/client';
import { GridEventListener, useGridApiRef, useKeepGroupedColumnsHidden } from '@mui/x-data-grid-premium';
import { useCallback, useEffect, useState } from 'react';

import GET_SEIZURES from '../../../../api/apollo/query/GetSeizures';
import { useAuth } from '../../../../api/auth';
import { CustomDataGrid } from '../../../../components/CustomDataGrid';
import TableToolbar from '../../../../components/Table/TableToolbar';
import { DefaultTableProps } from '../../../../components/Table/types';
import { Officer, Seizure } from '../../../../types';
import columns from './columns';

export interface SeizureDataGrid {
    id: string;
    rowId: string;
    docket: string | null;
    date: string | null;
    locationName: string | null;
    administrativeLevel1: string | null;
    arrestingOfficers?: Officer[];
    seizedCommodityId: string;
    seizedCommodityCommodity?: string;
    seizedCommodityProtectionLevel?: string | null;
    seizedCommodityCount: number | null;
    seizedCommodityWeightGrams: number | null;
    seizedCommodityValue: number | null;
    seizedCommodityValueCurrency: string | null;
    origin: string | null;
}

// const getLocationString = (value: Location | null) => {
//     let result = '';
//     if (value?.name) {
//         result += value?.name;
//     }

//     if (value?.administrativeLevel1) {
//         result += result.length > 0 ? `, ${value?.administrativeLevel1.name}` : value?.administrativeLevel1.name;
//     }
//     return result;
// };

const prepareSeizures = (seizures: Seizure[]) => {
    const result: SeizureDataGrid[] = [];

    seizures.forEach((item) => {
        item.seizedCommodities.forEach((commodity) => {
            result.push({
                id: item.id,
                rowId: `${item.id} - ${commodity.id}`,
                docket: item.courtCases ? item.courtCases[0]?.docket : null,
                date: item.date,
                locationName: item.locationName || null,
                administrativeLevel1: item.administrativeLevel1 || null,
                arrestingOfficers: item.officers,
                seizedCommodityId: commodity.id,
                seizedCommodityCommodity: commodity.species.commonName + ' ' + commodity.unit,
                seizedCommodityProtectionLevel: commodity.protectionLevel?.label ?? null,
                seizedCommodityCount: commodity.count,
                seizedCommodityWeightGrams: commodity.weightGrams,
                seizedCommodityValue: commodity.value,
                seizedCommodityValueCurrency: commodity.valueCurrency,
                origin: commodity.originName,
            });
        });
    });
    return result;
};

export const Seizures = ({ shrinkTitle, hideHeaders, showHeaders }: DefaultTableProps) => {
    const { user } = useAuth();
    const apiRef = useGridApiRef();
    const [rows, setRows] = useState<SeizureDataGrid[]>([]);

    const { loading } = useQuery(GET_SEIZURES, {
        onCompleted: ({ seizures }: { seizures: Seizure[] }) => setRows(prepareSeizures(seizures)),
    });

    useEffect(() => {
        const handleEvent: GridEventListener<'rowsScroll'> = (params) => {
            params.top > 5 ? hideHeaders() : showHeaders();
        };

        apiRef.current.subscribeEvent('rowsScroll', handleEvent);
    }, [apiRef, hideHeaders, showHeaders]);

    const getAggregationPosition = useCallback((groupNode) => (groupNode == null ? 'footer' : 'inline'), []);

    const initialState = useKeepGroupedColumnsHidden({
        apiRef,
        initialState: {
            aggregation: {
                model: {
                    seizedCommodityCount: 'sum',
                    seizedCommodityWeightKg: 'sum',
                    seizedCommodityValue: 'sum',
                },
            },
        },
    });

    return (
        <CustomDataGrid
            apiRef={apiRef}
            getRowId={(row) => row.rowId}
            columns={columns}
            rows={rows}
            checkboxSelection={false}
            aggregationRowsScope="all"
            getAggregationPosition={getAggregationPosition}
            rowGroupingColumnMode="single"
            loading={loading}
            hideFooterSelectedRowCount
            hideFooterPagination
            components={{ Toolbar: TableToolbar }}
            componentsProps={{
                toolbar: {
                    title: 'Seizures',
                    showTitle: shrinkTitle,
                    csvExportEnabled:
                        !loading && user?.permissions?.map(({ codename }) => codename).includes('can_export_tables'),
                    tableApi: apiRef.current,
                },
            }}
            initialState={initialState}
            experimentalFeatures={{ aggregation: true }}
        />
    );
};

export default Seizures;
