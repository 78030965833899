import SortIcon from '@mui/icons-material/Sort';
import cn from 'classnames';

import styles from './ColumnHeaderSortIcon.module.css';

interface ColumnHeaderSortIconProps {
    direction: 'asc' | 'desc';
}

export const ColumnHeaderSortIcon = ({ direction }: ColumnHeaderSortIconProps) => {
    return <SortIcon className={cn(styles.sortIcon, direction === 'asc' ? styles.asc : styles.desc)} />;
};
