import { Stack } from '@mui/material';
import { AxisScaleOutput } from '@visx/axis';
import { ScaleConfig } from '@visx/scale';
import { Text } from '@visx/text';
import { defaultStyles } from '@visx/tooltip';
import { Grid, Tooltip, XYChart as VxXYChart, XYChartTheme } from '@visx/xychart';
import { includes, indexOf, without } from 'lodash';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import AreaStack from '../AreaStack';
import Axes from '../Axes';
import Bar from '../Bar';
import BarGroup from '../BarGroup';
import BarStack from '../BarStack';
import ChartTooltipContent from '../ChartTooltipContent';
import { ChartWrapper } from '../ChartWrapper';
// import Grid from '../Grid';
import Gradients from '../Gradients';
import Legend, { SeriesLabel } from '../Legend';
import Line from '../Line';
import { LineAnnotation } from '../LineAnnotation';
import Scatter from '../Scatter';
import { CHART_CONTENT_CLASSNAME, MAX_CHART_LABEL_LENGTH } from '../constants';
import { AxesProps, Palette } from '../types';
import useColorAccessor from '../useColorAccessor';

type XYChartProps = {
    id: string;
    width: number;
    height: number;
    title?: string;
    description?: string;
    data: any;
    palette?: Palette;
    axes?: AxesProps;
    theme: XYChartTheme;
    activeMark: string;
    domainY?: [number, number];
    legendValues?: string[];
    formatLegendLabel?: (label: string) => string;
    groupAccessor?: (key: string) => string;
    xLines?: { value: number; color: string }[];
    yLines?: { value: number; color: string }[];
};

const defaultPalette: Palette = {
    background: 'transparent',
    text: '#757575',
    grid: '#ddd',
    axes: '#757575',
    marks: {
        default: '#122945',
        blur: '#e9edf2',
        hover: '#f8d6d5',
        active: ['#122945', '#f75151', '#3bbc97', '#a0b9d0', '#414042'],
    },
};

const bottomBarLabelFontSize = 11;
const bottomBarLabelAngle = 45;

const XYChart = ({
    id,
    width,
    height,
    title,
    description,
    data,
    palette = defaultPalette,
    axes,
    activeMark,
    theme,
    domainY,
    legendValues,
    formatLegendLabel,
    groupAccessor = (key: string) => key,
    xLines = [],
    yLines = [],
}: XYChartProps) => {
    const [selectedSeries, setSelectedSeries] = useState<string[]>([]);
    const [hoverSeries, setHoverSeries] = useState<string | null>(null);
    const legendRef = useRef<HTMLDivElement>(null);
    const svgRef = useRef<SVGSVGElement>(null);
    const innerSvg = useRef<SVGSVGElement>(null);
    const [legendComponent, setLegendComponent] = useState<ReactNode | undefined>(undefined);
    const [{ tooltipTop, tooltipLeft }, setTooltipPosition] = useState<{ tooltipTop: number; tooltipLeft: number }>({
        tooltipTop: 0,
        tooltipLeft: 0,
    });

    useEffect(() => {
        if (!legendRef.current || !svgRef.current) {
            return;
        }

        const boxes = legendRef.current.querySelectorAll('.selectedSeriesBox');
        const svgBounds = svgRef.current.getBoundingClientRect();
        const components = Array.from(boxes).map((box) => {
            // @ts-ignore
            const [markerBlock, textBlock] = box.children;
            const markerBounds = markerBlock.getBoundingClientRect();
            const textBounds = textBlock.getBoundingClientRect();

            const { backgroundColor: markerBoundsBgColor, borderColor: markerBorderColor } =
                getComputedStyle(markerBlock);
            const { color } = getComputedStyle(textBlock);

            return (
                <>
                    <rect
                        x={markerBounds.x - svgBounds.x}
                        y={markerBounds.y - svgBounds.y}
                        width={markerBounds.width}
                        height={markerBounds.height}
                        rx={4}
                        ry={4}
                        fill={markerBoundsBgColor}
                        stroke={markerBorderColor}
                    />
                    <Text
                        x={textBounds.x - svgBounds.x}
                        y={textBounds.y - svgBounds.y + 6}
                        verticalAnchor="start"
                        fontSize={12}
                        fontWeight="light"
                        fill={color}
                    >
                        {textBlock.innerText}
                    </Text>
                </>
            );
        });

        setLegendComponent(<>{components}</>);
    }, [legendRef.current, selectedSeries, width]);

    const colorAccessor = useColorAccessor({ chartId: id, hoverSeries, selectedSeries, groupAccessor });
    const pointerEventProps = useMemo(() => {
        if (activeMark === 'BarGroup') {
            return {};
        }

        if (data.length === 1) {
            return {};
        }

        const onPointerMove = ({ key }) => {
            //highlight a series on mouseover
            const group = groupAccessor(key);
            if (group !== hoverSeries) {
                setHoverSeries(group);
            }
        };

        const onPointerOut = () => {
            //clear the highlight on mouseout
            if (hoverSeries) {
                setHoverSeries(null);
            }
        };

        const onPointerUp = ({ key }) => {
            //select a series on click
            const group = groupAccessor(key);
            setSelectedSeries((prev) => {
                if (prev.length < palette.marks.active.length) {
                    return includes(prev, group) ? without(prev, group) : [...prev, group];
                } else {
                    return includes(prev, group) ? without(prev, group) : prev;
                }
            });
        };

        return {
            onPointerMove,
            onPointerOut,
            onPointerUp,
        };
    }, [data, selectedSeries, hoverSeries, palette, activeMark, groupAccessor]);

    const dateFormat = useMemo(() => {
        switch (axes?.bottom?.interval) {
            case 'quarter':
                return 'q yyyy';
            case 'month':
                return 'LLL y';
            case 'year':
                return 'y';
            default:
                return 'D';
        }
    }, [axes?.bottom]);

    const { chartWidth, legendWidth } = useMemo(() => {
        const legendWidth = 180;
        const chartWidth = data.length > 1 ? Math.max(width - 180, 0) : Math.max(width, 0);
        return { chartWidth, legendWidth };
    }, [data, width]);

    const legendHeight = useMemo(() => {
        return Math.max(height - 98, 100);
    }, [height]);

    const margin = useMemo(() => {
        const monospaceCorrelationCoef = 0.65;
        const bottomLabels = data
            .map((item) => {
                return item.data.map((innerItem) => {
                    return innerItem.label;
                });
            })
            .flat(1)
            .filter(Boolean);

        let longestLabelLength =
            Math.max(...bottomLabels.map((label) => (typeof label === 'string' ? label.length : 8))) || 0;
        longestLabelLength = longestLabelLength > MAX_CHART_LABEL_LENGTH ? MAX_CHART_LABEL_LENGTH : longestLabelLength;

        const letterWidth = bottomBarLabelFontSize * monospaceCorrelationCoef;
        const rad = (Math.PI / 180) * bottomBarLabelAngle;
        const longestLabelWidth = Math.cos(rad) * (letterWidth * longestLabelLength);
        const result = longestLabelWidth + 8; //add extra bottom padding

        const bottom = isNaN(result) ? 32 : result;

        return {
            top: 8,
            right: 40,
            bottom,
            left: 100,
        };
    }, [data]);

    const xScale: ScaleConfig<AxisScaleOutput, any, any> = useMemo(() => {
        if (['Bar', 'BarGroup', 'BarStack', 'MultiBar'].includes(activeMark)) {
            return { type: 'band', padding: 0.2 };
        } else {
            return { type: 'band' };
        }
    }, [activeMark]);

    const yScale: ScaleConfig<AxisScaleOutput, any, any> = useMemo(() => {
        return domainY
            ? {
                  type: 'linear',
                  domain: domainY,
              }
            : { type: 'linear' };
    }, [domainY]);

    const seriesLabels = useMemo(() => {
        return legendValues ? legendValues : data.map(({ label }) => label);
    }, [data, axes?.bottom, legendValues]);

    if (width === 0 || height === 0) {
        return null;
    }

    return (
        <div style={{ position: 'relative' }}>
            <ChartWrapper ref={svgRef} width={width} height={height} chartLabel={{ title, description }}>
                {({ chartTop, chartHeight }) => {
                    return (
                        <>
                            {legendComponent}
                            <foreignObject
                                className={CHART_CONTENT_CLASSNAME}
                                y={chartTop}
                                x={0}
                                width={chartWidth}
                                height={chartHeight < 0 ? 0 : chartHeight}
                                style={{ position: 'relative' }}
                                ref={innerSvg}
                                onMouseMove={(e) => {
                                    const { x, y } = innerSvg.current
                                        ? innerSvg.current.getBoundingClientRect()
                                        : { x: 0, y: 0 };
                                    setTooltipPosition({ tooltipTop: e.clientY - y, tooltipLeft: e.clientX - x });
                                }}
                            >
                                <VxXYChart
                                    theme={theme}
                                    width={chartWidth}
                                    height={chartHeight < 0 ? 0 : chartHeight}
                                    margin={margin}
                                    xScale={xScale}
                                    yScale={yScale}
                                    pointerEventsDataKey="nearest"
                                    {...pointerEventProps}
                                >
                                    <Gradients chartId={id} palette={palette} />
                                    <Grid columns={false} stroke="#bdbdbd" strokeDasharray="1,3" />
                                    <Axes axes={axes} data={data} />
                                    {activeMark === 'AreaStack' && (
                                        <AreaStack data={data} colorAccessor={colorAccessor} />
                                    )}
                                    {activeMark === 'Line' && (
                                        <Line
                                            data={data}
                                            colorAccessor={colorAccessor}
                                            selectedSeries={selectedSeries}
                                        />
                                    )}
                                    {activeMark === 'Scatter' && <Scatter data={data} colorAccessor={colorAccessor} />}
                                    {activeMark === 'Bar' && <Bar data={data} colorAccessor={colorAccessor} />}
                                    {activeMark === 'BarStack' && (
                                        <BarStack
                                            data={data}
                                            colorAccessor={colorAccessor}
                                            selectedSeries={selectedSeries}
                                        />
                                    )}
                                    {activeMark === 'BarGroup' && (
                                        <BarGroup data={data} colorAccessor={colorAccessor} />
                                    )}
                                    <LineAnnotation xLines={xLines} yLines={yLines} />
                                    <Tooltip
                                        zIndex={300}
                                        style={{
                                            ...defaultStyles,
                                            padding: 0,
                                            zIndex: 3,
                                        }}
                                        // @ts-ignore
                                        top={tooltipTop}
                                        left={tooltipLeft}
                                        renderTooltip={({ tooltipData }) => (
                                            <ChartTooltipContent
                                                label={tooltipData?.nearestDatum?.key || ''}
                                                datum={tooltipData?.nearestDatum?.datum}
                                                dateFormat={dateFormat}
                                            />
                                        )}
                                    />
                                </VxXYChart>
                            </foreignObject>
                            {typeof palette.marks.active !== 'string' && data.length > 1 && (
                                <foreignObject x={chartWidth} y={64} width={legendWidth} height={legendHeight}>
                                    <div
                                        ref={legendRef}
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            zIndex: -1,
                                            opacity: 0,
                                            left: 0,
                                        }}
                                    >
                                        <Stack
                                            spacing={1}
                                            sx={{
                                                overflow: 'auto',
                                                px: 2,
                                            }}
                                        >
                                            {selectedSeries.map((label) => {
                                                const activeIndex = indexOf(selectedSeries, label);

                                                const bgColor =
                                                    activeIndex > -1
                                                        ? palette.marks.active[activeIndex]
                                                        : 'transparent';
                                                const borderColor =
                                                    activeIndex > -1 ? palette.marks.active[activeIndex] : '#e9edf2';

                                                return (
                                                    <SeriesLabel
                                                        key={label}
                                                        bgColor={bgColor}
                                                        borderColor={borderColor}
                                                        label={label}
                                                        formatLegendLabel={formatLegendLabel}
                                                        className="selectedSeriesBox"
                                                    />
                                                );
                                            })}
                                        </Stack>
                                    </div>
                                    <Legend
                                        seriesLabels={seriesLabels}
                                        hoverSeries={hoverSeries}
                                        setHoverSeries={setHoverSeries}
                                        selectedSeries={selectedSeries}
                                        setSelectedSeries={setSelectedSeries}
                                        palette={palette.marks.active}
                                        height={legendHeight}
                                        width={legendWidth}
                                        formatLegendLabel={formatLegendLabel}
                                    />
                                </foreignObject>
                            )}
                        </>
                    );
                }}
            </ChartWrapper>
        </div>
    );
};

export default XYChart;
