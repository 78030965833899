import AccountRoute from './Account';
import CaseFormsRoute from './CaseForms';
import CaseFormRoute from './CaseForms/CaseForm';

/*
import AboutUsRoute from './AboutUs';
*/
import DashboardRoute from './Dashboards';
import HomeRoute from './Home';
import Login from './Login';
import RegionalTrendsRoute from './RegionalTrends';
import ResetPassword from './ResetPassword';
import SummaryTablesRoute from './SummaryTables';

const routes = [
    HomeRoute,
    DashboardRoute,
    SummaryTablesRoute,
    RegionalTrendsRoute,
    AccountRoute,
    CaseFormsRoute,
    CaseFormRoute,
];

export { routes, Login, ResetPassword };
