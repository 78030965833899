import { useMutation } from '@apollo/client';
import { Button } from '@c4ads/c4blocks';
import { zodResolver } from '@hookform/resolvers/zod';
import DoneIcon from '@mui/icons-material/Done';
import PersonIcon from '@mui/icons-material/Person';
import TuneIcon from '@mui/icons-material/Tune';
import {
    Avatar,
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    FormHelperText,
    Grid,
    TextField,
    Typography,
    styled,
} from '@mui/material';
import cn from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useOutletContext } from 'react-router';
import { z } from 'zod';

import { UPDATE_USER } from '../../../../../api/apollo/mutation';
import { useAuth } from '../../../../../api/auth';
import { FullScreenLoader } from '../../../../../components/Loader/FullScreenLoader';
import { openSnackBar } from '../../../../../components/Snackbar';
import { AccountContext, AccountContextInterface } from '../../../AccountContext';
import { AccountOutletContextInterface } from '../../../index';
import FieldGrid from '../../FieldGrid';
import { FieldItemProps } from '../../FieldItem';
import fieldStyles from '../../FieldItem/FieldItem.module.css';
import profileStyles from '../Profile.module.css';
import { getUserRole } from '../index';
import styles from './EditProfile.module.css';

const UserAvatar = styled(Avatar)(({ theme }) => ({
    height: 80,
    width: 80,
    backgroundColor: theme.palette.primary.main,
}));

const StyledForm = styled('form')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: 360,
    gap: theme.spacing(2),
    padding: theme.spacing(4),
    margin: '0 auto',
}));

const schema = z.object({
    firstName: z.string().min(2, {
        message: 'First name must be at least 2 characters.',
    }),
    lastName: z.string().min(2, {
        message: 'Last name must be at least 2 characters.',
    }),
    title: z.string(),
    email: z.string().email({
        message: 'Invalid email.',
    }),
    phoneNumber: z.string().optional(),
});

export const EditProfile = () => {
    const { user } = useOutletContext<AccountOutletContextInterface>();
    const { setTitle } = useContext<AccountContextInterface>(AccountContext);
    const auth = useAuth();
    const navigate = useNavigate();
    const [updateUser] = useMutation(UPDATE_USER);
    const {
        control,
        formState: { errors },
        handleSubmit,
    } = useForm<z.infer<typeof schema>>({
        resolver: zodResolver(schema),
        defaultValues: {
            firstName: user.firstName,
            lastName: user.lastName,
            title: '', // user.title
            email: user.email,
            phoneNumber: '',
        },
    });
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setTitle('My Profile');
    }, [user]);

    let fields: FieldItemProps[] = [];
    if (user) {
        fields = [
            {
                render: (
                    <Grid className={styles.editProfileTitleContainer} container spacing={2}>
                        <Grid item sm={6} xs={12} md={6} xl={6} sx={{ alignItems: 'flex-start' }}>
                            <div className={styles.editProfileItem}>
                                <Typography variant="subtitle1" className={fieldStyles.editableFieldLabel}>
                                    First name:
                                </Typography>
                                <Controller
                                    name="firstName"
                                    control={control}
                                    rules={{
                                        required: 'First name is required.',
                                        minLength: {
                                            value: 3,
                                            message: 'First name must have at least 3 characters',
                                        },
                                    }}
                                    render={({ field }) => (
                                        <TextField {...field} className={cn(styles.input, fieldStyles.editableField)} />
                                    )}
                                />
                            </div>
                            {errors?.firstName?.message && (
                                <FormHelperText color="error">{errors.firstName.message as string}</FormHelperText>
                            )}
                        </Grid>
                        <Grid item sm={6} xs={12} md={6} xl={6}>
                            <div className={styles.editProfileItem}>
                                <Typography variant="subtitle1" className={fieldStyles.editableFieldLabel}>
                                    Last name:{' '}
                                </Typography>
                                <Controller
                                    name="lastName"
                                    control={control}
                                    rules={{
                                        required: 'Last name is required.',
                                        minLength: {
                                            value: 3,
                                            message: 'Last name  must have at least 3 characters',
                                        },
                                    }}
                                    render={({ field }) => (
                                        <TextField {...field} className={cn(styles.input, fieldStyles.editableField)} />
                                    )}
                                />
                            </div>
                            {errors?.lastName?.message && (
                                <FormHelperText color="error">{errors.lastName.message as string}</FormHelperText>
                            )}
                        </Grid>
                    </Grid>
                ),
            },
            {
                label: 'Email',
                name: 'email',
                value: user.email || '',
                editable: false,
                control,
            },
            {
                render: <ResetPassword />,
            },
            { label: 'Organization', name: 'organization', value: user.organization.name, editable: false },
            {
                label: 'Role',
                name: 'userType',
                value: getUserRole(user),
                editable: false,
            },
        ];
    }

    const onSubmit = async (data) => {
        const { firstName, lastName } = data;

        setLoading(true);
        updateUser({ variables: { user: { firstName, lastName } } })
            .then(async () => {
                await auth.updateUser();
                openSnackBar({ message: 'User profile updated successfully', type: 'success' });
                navigate('/account', { replace: true });
            })
            .catch(() => {
                openSnackBar({ message: 'Something went wrong, try again', type: 'error' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            {loading && (
                <div className={styles.loaderWrapper}>
                    <FullScreenLoader />
                </div>
            )}

            <StyledForm className={profileStyles.form} onSubmit={handleSubmit(onSubmit)}>
                <Grid item className={profileStyles.avatar}>
                    <UserAvatar />
                    <div className={profileStyles.userName}>
                        <Typography variant="h6">{user.firstName}</Typography>
                        <Typography variant="h6">{user.lastName}</Typography>
                    </div>
                </Grid>
                <div className={profileStyles.accountInfoBlock}>
                    <div className={profileStyles.accountInfo}>
                        <PersonIcon />
                        <Typography variant="subtitle2">Account info</Typography>
                    </div>
                </div>
                <div className={profileStyles.fieldsGrid}>
                    <FieldGrid fields={fields} />
                </div>

                <Box className={styles.editProfileButtonsContainer}>
                    <div className={profileStyles.profileButtonsContainer}>
                        <Button type="submit" className={cn(profileStyles.profileButton, profileStyles.activeButton)}>
                            Save
                        </Button>
                    </div>
                </Box>
            </StyledForm>
        </>
    );
};

const ResetPassword = () => {
    const { resetPassword, user } = useAuth();

    const [openChangePassword, setOpenChangePassword] = useState<boolean>(false);
    const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const handleResetPassword = () => {
        setOpenChangePassword(true);
    };

    const handleCloseChangePasswordDialog = () => {
        setOpenChangePassword(false);
    };

    const handleChangePassword = async () => {
        if (user) {
            setLoading(true);
            await resetPassword(user.email);
            setLoading(false);
        }
        setOpenSuccessModal(true);
        setOpenChangePassword(false);
    };

    return (
        <>
            {loading && (
                <div className={styles.loaderWrapper}>
                    <FullScreenLoader />
                </div>
            )}
            <div className={fieldStyles.fieldContainer}>
                <Typography variant="subtitle1" className={fieldStyles.editableFieldLabel}>
                    Password:
                </Typography>
                <TextField
                    type="password"
                    InputProps={{
                        endAdornment: (
                            <Typography onClick={handleResetPassword} className={styles.resetPassword}>
                                Reset password ?
                            </Typography>
                        ),
                    }}
                    className={cn(fieldStyles.editableField, styles.passwordField)}
                    value="Password"
                    disabled={true}
                />
            </div>

            <Dialog open={openChangePassword} onClose={handleCloseChangePasswordDialog} fullWidth maxWidth="xs">
                <div className={styles.changePasswordTitle}>
                    <TuneIcon />
                    <DialogTitle className={styles.changePasswordTitleText}>Change password</DialogTitle>
                </div>

                <DialogContent>
                    <Typography variant="body1">Are you sure you want to change your password?</Typography>
                    <div className={styles.changePasswordDialogButtons}>
                        <Button onClick={handleCloseChangePasswordDialog}>No</Button>
                        <Button
                            className={styles.changePasswordDialogYes}
                            color="primary"
                            onClick={handleChangePassword}
                        >
                            Yes
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog
                open={openSuccessModal}
                onClose={() => {
                    setOpenSuccessModal(false);
                }}
            >
                <DialogContent>
                    <DoneIcon className={styles.resetPasswordSentIcon} />
                    <Typography className={styles.resetPasswordSentText} variant="body1">
                        A password reset email has been sent!
                    </Typography>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default EditProfile;
