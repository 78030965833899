import { gql } from '@apollo/client';

const GET_DEFENDANTS = gql`
    query defendants {
        defendants {
            id
            arrestDate
            courtCase {
                id
                docket
            }
            firstName
            lastName
            birthdate
            sex
            streetAddress
            addressAdministrativeLevel2 {
                id
                name
            }
            addressAdministrativeLevel1 {
                id
                name
            }
            addressRegion {
                id
                name
            }
            addressCountry {
                id
                name
            }
            nationality {
                id
                name
            }
            bailEvents {
                id
                date
                bailGranted
                bondAmount
            }
            charges {
                verdict
            }
            pleaBargain
            outcomeDate
            outcome
            fine
            fineCurrency
            custodialMonths
            suspendedFine
            suspendedMonths
            outcomeFactors {
                impact
                factor
            }
        }
    }
`;

export default GET_DEFENDANTS;
