import { ReactNode, forwardRef } from 'react';

import { ChartLabel } from './ChartLabel';

interface ChartWrapperProps {
    width: number;
    height: number;
    chartLabel?: {
        title?: string;
        description?: string;
    };
    children: ({ chartTop, chartHeight }: { chartTop: number; chartHeight: number }) => ReactNode;
}

const labelMargin = {
    left: 32,
    top: 32,
};

const chartMargin = {
    top: 12,
};

const chartLabelContainerHeight = 42;

export const ChartWrapper = forwardRef<SVGSVGElement, ChartWrapperProps>(
    ({ width, height, chartLabel, children }, ref) => {
        const chartTop = chartLabelContainerHeight + labelMargin.top + chartMargin.top;
        let chartHeight = height - chartTop;
        chartHeight = chartHeight < 0 ? 0 : chartHeight;

        return (
            <svg width={width} height={height} ref={ref}>
                {chartLabel && (
                    <ChartLabel
                        label={chartLabel.title}
                        description={chartLabel.description}
                        x={labelMargin.left}
                        y={labelMargin.top}
                        width={width - labelMargin.left}
                        height={chartLabelContainerHeight}
                    />
                )}
                {children({ chartTop, chartHeight })}
            </svg>
        );
    }
);
