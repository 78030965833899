import { useMutation, useQuery } from '@apollo/client';
import { CircularProgress, Typography } from '@mui/material';
import { usePostHog } from 'posthog-js/react';
import { ReactNode, useEffect, useRef, useState } from 'react';

import { DELETE_DASHBOARD_CONFIG } from '../../api/apollo/mutation';
import { GET_DASHBOARDS } from '../../api/apollo/query';
import { useAuth } from '../../api/auth';
import { DashboardCreateForm, DashboardCreateFormRef } from '../../components/DashboardCreateForm';
import { DashboardManagerDialog, DashboardManagerRef } from '../../components/DashboardsManagerDialog';
import { FullScreenLoader } from '../../components/Loader/FullScreenLoader';
import { useQueue } from '../../hooks/useQueue';
import { captureCreateDashboard, captureRemoveDashboard } from '../../posthog';
import { DashboardItem, DashboardTabsRefInterface, TabDashboardItem } from '../../types/dashboards';
import { Dashboard } from './Dashboard';
import styles from './Dashboards.module.css';
import { DashboardsContext } from './DashboardsContext';
import { DashboardTabs } from './components/DashboardTabs';
import {
    deleteDashboardFromLocalStorage,
    getDashboardsFromLocalStorage,
    getLastOpenedDashboardTab,
} from './dashboardsLocalStorageManager';

const Dashboards = () => {
    const { user } = useAuth();
    const posthog = usePostHog();
    const [deleteDashboard] = useMutation(DELETE_DASHBOARD_CONFIG);
    const { data: dashboardsData, loading: dashboardsLoading, refetch } = useQuery(GET_DASHBOARDS);
    const tabsRef = useRef<DashboardTabsRefInterface>(null);
    const dashboardCreateFormRef = useRef<DashboardCreateFormRef>(null);
    const dashboardManagerRef = useRef<DashboardManagerRef>(null);

    const [loading, setLoading] = useState<boolean>(false);
    const [dashboardsAutoSaving, setDashboardsAutoSaving] = useState<boolean>(false);

    const queue = useQueue(
        () => {
            setDashboardsAutoSaving(true);
        },
        () => {
            setDashboardsAutoSaving(false);
        }
    );

    const [dashboards, setDashboards] = useState<
        Record<
            string,
            {
                el: ReactNode;
                item: DashboardItem;
            }
        >
    >({});
    const [visibleDashboard, setVisibleDashboard] = useState<string>();
    const [remoteDashboardItems, setRemoteDashboardItems] = useState<DashboardItem[]>([]);

    useEffect(() => {
        if (!dashboardsLoading && dashboardsData) {
            setRemoteDashboardItems(dashboardsData.user.dashboardConfigs as DashboardItem[]);
        }
    }, [dashboardsData, dashboardsLoading]);

    useEffect(() => {
        setLoading(dashboardsLoading);
    }, [dashboardsLoading]);

    useEffect(() => {
        const handler = (e) => {
            const confirmationMessage = 'Please wait, the autosave process has not finished yet';

            e.returnValue = confirmationMessage;
            return confirmationMessage;
        };
        if (dashboardsAutoSaving) {
            window.addEventListener('beforeunload', handler);
        }

        return () => {
            window.removeEventListener('beforeunload', handler);
        };
    }, [dashboardsAutoSaving]);

    useEffect(() => {
        if (!user) {
            return;
        }
        const dashboards = getDashboardsFromLocalStorage(user.id);

        const tabIndex = getLastOpenedDashboardTab();
        if (dashboards.length - 1 >= tabIndex) {
            setVisibleDashboard(dashboards[tabIndex].id);
        }

        loadDashboards(dashboards, false);
    }, [user]);

    const onChange = (id: string) => {
        setVisibleDashboard(id);
    };

    const loadDashboards = (newDashboards: DashboardItem[], updateVisibleDashboard: boolean = true) => {
        const cloneDashboards = { ...dashboards };

        newDashboards.forEach((item) => {
            cloneDashboards[item.id] = {
                el: (
                    <Dashboard
                        title={item.title}
                        id={item.id}
                        onAbortFunction={(abort) => {
                            tabsRef.current &&
                                tabsRef.current.addDashboardTab({ ...item, abort, loading: true, saved: false });
                        }}
                    />
                ),
                item,
            };
        });

        if (visibleDashboard === undefined && newDashboards.length > 0 && updateVisibleDashboard) {
            setVisibleDashboard(newDashboards[0].id);
        }
        setDashboards(cloneDashboards);
    };

    const setIsLoading = (id: string, loading: boolean) => {
        tabsRef.current && tabsRef.current.setLoading(id, loading);
    };

    const closeTab = (id: string) => {
        tabsRef.current && tabsRef.current.closeTab(id);
    };

    const onAddTab = async (callback: (tab?: TabDashboardItem) => void) => {
        if (dashboardCreateFormRef.current) {
            const tabData = await dashboardCreateFormRef.current.open();

            if (!tabData) {
                return;
            }
            const { eventName, payload } = captureCreateDashboard(tabData.id, tabData.title);
            posthog?.capture(eventName, payload);

            await refetch();
            setVisibleDashboard(tabData.id);
            setDashboards({
                ...dashboards,
                [tabData.id]: {
                    el: (
                        <Dashboard
                            id={tabData.id}
                            title={tabData.title}
                            onAbortFunction={(abort) => {
                                callback({
                                    ...tabData,
                                    abort,
                                    loading: true,
                                    saved: false,
                                });
                            }}
                        />
                    ),
                    item: tabData,
                },
            });
        }
    };

    const onClose = (id: string) => {
        queue.addTask(async () => {
            if (user) {
                deleteDashboardFromLocalStorage(user.id, id);
            }
        });

        setDashboards((dashboards) => {
            const cloneDashboards = { ...dashboards };
            delete cloneDashboards[id];
            return cloneDashboards;
        });
    };

    const openDashboardManager = () => {
        dashboardManagerRef.current &&
            dashboardManagerRef.current.open(loadDashboards, async (id: string) => {
                setLoading(true);
                await deleteDashboard({
                    variables: {
                        dashboardConfig: {
                            id,
                        },
                    },
                });

                const { eventName, payload } = captureRemoveDashboard(id);
                posthog?.capture(eventName, payload);
                await refetch();
                onClose(id);

                const index = remoteDashboardItems.findIndex((item) => item.id === id);

                if (index !== -1) {
                    setRemoteDashboardItems((prev) => {
                        const clonePrev = [...prev];
                        clonePrev.splice(index, 1);
                        return clonePrev;
                    });
                }
                setLoading(false);
            });
    };

    const getDashboards = (includeRemote: boolean = false) => {
        let result: DashboardItem[] = Object.keys(dashboards).map((id) => dashboards[id].item);
        if (includeRemote) {
            result = [...result, ...remoteDashboardItems];
        }
        return result;
    };

    const onCleanDashboards = () => {
        setVisibleDashboard(undefined);
    };

    const setSavedStatus = (id: string, status: boolean) => {
        tabsRef.current && tabsRef.current.setSavedStatus(id, status);
    };

    return (
        <DashboardsContext.Provider
            value={{
                loadDashboards,
                setIsLoading,
                openDashboardManager,
                getDashboards,
                setSavedStatus,
                addLocalStorageTask: queue.addTask,
                closeTab,
            }}
        >
            {dashboardsAutoSaving && <CircularProgress size={32} className={styles.autoSaving} />}
            {loading && <FullScreenLoader />}
            <DashboardTabs
                ref={tabsRef}
                onChange={onChange}
                onCleanDashboards={onCleanDashboards}
                onAddTab={onAddTab}
                onClose={onClose}
            />
            <div className={styles.dashboardContainer}>
                {Object.keys(dashboards).map((id) => (
                    <div key={id} style={{ display: id !== visibleDashboard ? 'none' : 'block' }}>
                        {dashboards[id].el}
                    </div>
                ))}
                {visibleDashboard === undefined && (
                    <Typography className={styles.createDashboardText} variant="h6" color="primary">
                        Click on "+" button to create a dashboard
                    </Typography>
                )}
            </div>
            <DashboardCreateForm ref={dashboardCreateFormRef} />
            <DashboardManagerDialog
                dashboards={remoteDashboardItems.filter((item) => !Object.keys(dashboards).includes(item.id))}
                ref={dashboardManagerRef}
            />
        </DashboardsContext.Provider>
    );
};

const DashboardRoute = {
    routeProps: {
        path: '/dashboards',
        element: <Dashboards />,
    },
    name: 'Dashboards',
};

export default DashboardRoute;
