import { Button } from '@c4ads/c4blocks';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';

import superUserStyles from '../../SuperUser.module.css';
import styles from './DeleteDialog.module.css';

interface DeleteDialogInterface {
    open: boolean;
    setOpen: (open: boolean) => void;
    onDelete: () => void;
    title: string;
    buttonTitle: string;
    description: string;
}

export const DeleteDialog = ({ open, setOpen, onDelete, title, buttonTitle, description }: DeleteDialogInterface) => {
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
            <IconButton className={superUserStyles.closeButton} onClick={handleClose}>
                <HighlightOffIcon className={superUserStyles.closeIcon} />
            </IconButton>
            <div className={styles.titleContainer}>
                <div className={styles.deleteImageContainer}>
                    <DescriptionOutlinedIcon className={styles.documentIcon} />
                    <HighlightOffOutlinedIcon className={styles.highlightOffIcon} />
                </div>
                <DialogTitle color="textPrimary" className={styles.dialogTitle}>
                    {title}
                </DialogTitle>
            </div>
            <DialogContent className={styles.dialogContent}>
                <Typography variant="subtitle1" color="textSecondary">
                    {description}
                </Typography>
                <Button className={styles.deleteButton} onClick={onDelete}>
                    {buttonTitle}
                </Button>
            </DialogContent>
        </Dialog>
    );
};
