// @ts-nocheck
import { Button, Fade, Typography } from '@mui/material';
import {
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExportContainer,
    GridToolbarQuickFilter,
} from '@mui/x-data-grid';

import styles from '../../SeizedCommodities.module.css';

export const CommoditiesTableToolBar = ({
    title,
    showTitle = false,
    onExportCsv,
    onExportExcel,
}: {
    title?: string;
    showTitle?: boolean;
    onExportCsv: () => void;
    onExportExcel: () => void;
}) => {
    return (
        <GridToolbarContainer sx={{ border: 'none', justifyContent: 'space-between', px: 2 }}>
            <GridToolbarQuickFilter />
            <Fade in={showTitle} timeout={{ enter: 150, exit: 0 }}>
                <Typography variant="subtitle1" sx={{ color: 'var(--color-primary)' }}>
                    {title}
                </Typography>
            </Fade>
            <div>
                <GridToolbarDensitySelector placeholder="" sx={{ color: 'var(--color-primary)' }} />
                <GridToolbarColumnsButton placeholder="" sx={{ color: 'var(--color-primary)' }} />
                <GridToolbarExportContainer placeholder="" sx={{ color: 'var(--color-primary)' }}>
                    <Button className={styles.toolbarExportButton} onClick={onExportCsv}>
                        Download as CSV
                    </Button>
                    <Button className={styles.toolbarExportButton} onClick={onExportExcel}>
                        Download as Excel
                    </Button>
                </GridToolbarExportContainer>
            </div>
        </GridToolbarContainer>
    );
};
