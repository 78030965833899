import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { omit } from 'lodash';
import { useContext, useEffect, useState } from 'react';

import { AdvancedFilterContext } from '../../AdvancedFilterContext';
import { FilterComponentGeneralInterface } from '../FilterComponent';
import { Option } from './SingleSelectInput';

interface AutocompleteInputProps<T, V> extends FilterComponentGeneralInterface {
    getValue: (item: T) => V;
    getLabel: (item: T) => string;
    options: T[];
    multiple: boolean;
}

export const AutocompleteInput = <T, V>(props: AutocompleteInputProps<T, V>) => {
    return props.multiple ? <MultipleAutocomplete<T, V> {...props} /> : <SingleAutocomplete<T, V> {...props} />;
};

const MultipleAutocomplete = <T, V>({
    getLabel,
    getValue,
    onFilterFunction,
    field,
    options: rawOptions,
}: AutocompleteInputProps<T, V>) => {
    const { defaultFiltersValue } = useContext(AdvancedFilterContext);
    const [options, setOptions] = useState<Option<V>[]>(
        rawOptions.map((item) => ({ label: getLabel(item), value: getValue(item) }))
    );
    let defaultOptions = (defaultFiltersValue[field] as T[]) || [];
    const [selectedOptions, setSelectedOptions] = useState<Option<V>[]>(
        defaultOptions.map((item) => ({ label: getLabel(item), value: getValue(item) }))
    );

    useEffect(() => {
        setOptions(rawOptions.map((item) => ({ label: getLabel(item), value: getValue(item) })));
        setSelectedOptions(defaultOptions.map((item) => ({ label: getLabel(item), value: getValue(item) })));
    }, [JSON.stringify(rawOptions)]);

    useEffect(() => {
        onFilterFunction(field, field, () => {
            let options = selectedOptions.filter(({ value }) => value !== undefined);

            if (options.length > 0 && typeof options[0].value !== 'string') {
                return {
                    [field]: options.map(({ value }) => omit(value || {}, '__typename')),
                };
            }
            return {
                [field]: options.map(({ value }) => value),
            };
        });
    }, [selectedOptions]);

    return (
        <>
            <FormControl size="small">
                <Select value="in">
                    <MenuItem value="in">in</MenuItem>
                </Select>
            </FormControl>
            <FormControl size="small">
                <Autocomplete
                    multiple={true}
                    onChange={(e, newValue) => {
                        setSelectedOptions(newValue);
                    }}
                    options={options}
                    getOptionLabel={(option) => option.label}
                    defaultValue={selectedOptions}
                    value={selectedOptions}
                    renderInput={(params) => <TextField {...params} label={field} />}
                />
            </FormControl>
        </>
    );
};

const SingleAutocomplete = <T, V>({
    getLabel,
    getValue,
    onFilterFunction,
    field,
    options: rawOptions,
}: AutocompleteInputProps<T, V>) => {
    const { defaultFiltersValue } = useContext(AdvancedFilterContext);
    const [options, setOptions] = useState<Option<V>[]>(
        rawOptions.map((item) => ({ label: getLabel(item), value: getValue(item) }))
    );
    let defaultOption = (defaultFiltersValue[field] as T) || null;
    const [selectedOption, setSelectedOption] = useState<Option<V> | null>(
        defaultOption ? { label: getLabel(defaultOption), value: getValue(defaultOption) } : null
    );

    useEffect(() => {
        setOptions(rawOptions.map((item) => ({ label: getLabel(item), value: getValue(item) })));
        setSelectedOption(defaultOption ? { label: getLabel(defaultOption), value: getValue(defaultOption) } : null);
    }, [JSON.stringify(rawOptions)]);

    useEffect(() => {
        onFilterFunction(field, field, () => {
            return {
                [field]: selectedOption?.value
                    ? typeof selectedOption.value === 'string'
                        ? selectedOption.value
                        : omit(selectedOption?.value, '__typename')
                    : undefined,
            };
        });
    }, [selectedOption]);

    return (
        <>
            <FormControl size="small">
                <InputLabel>Operator</InputLabel>
                <Select value="in" label="Operator">
                    <MenuItem value="in">in</MenuItem>
                </Select>
            </FormControl>
            <FormControl size="small">
                <Autocomplete
                    multiple={false}
                    onChange={(e, newValue) => {
                        setSelectedOption(newValue);
                    }}
                    options={options}
                    value={selectedOption}
                    getOptionLabel={(option) => option.label}
                    defaultValue={selectedOption}
                    renderInput={(params) => <TextField {...params} label={field} />}
                />
            </FormControl>
        </>
    );
};
