import { Stack } from '@mui/material';
import { AxisBottom, AxisLeft, AxisTop, TickFormatter } from '@visx/axis';
import { GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear } from '@visx/scale';
import { Bar } from '@visx/shape';
import { Text } from '@visx/text';
import { indexOf, lowerCase, maxBy } from 'lodash';
import { DateTime } from 'luxon';
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ChartWrapper } from '../ChartWrapper';
import Gradients from '../Gradients';
import Legend, { SeriesLabel } from '../Legend';
import { CHART_CONTENT_CLASSNAME } from '../constants';
import { AxesProps, Palette } from '../types';
import useColorAccessor from '../useColorAccessor';

export type MultiBarProps = {
    id: string;
    width: number;
    height: number;
    title?: string;
    description?: string;
    data: any;
    palette?: Palette;
    axes?: AxesProps;
    domainY?: [number, number];
    legendValues?: string[];
    formatLegendLabel?: (label: string) => string;
    groupAccessor?: (key: string) => string;
    xLines?: { value: number; color: string }[];
    yLines?: { value: number; color: string }[];
};

const defaultPalette: Palette = {
    background: 'transparent',
    text: '#757575',
    grid: '#ddd',
    axes: '#757575',
    marks: {
        default: '#122945',
        blur: '#e9edf2',
        hover: '#f8d6d5',
        active: ['#122945', '#f75151', '#3bbc97', '#a0b9d0', '#414042'],
    },
};

export default function MultiBar({
    id,
    width,
    height,
    title,
    description,
    data,
    axes,
    formatLegendLabel,
    domainY,
    groupAccessor,
    xLines,
    yLines,
}: MultiBarProps) {
    const [selectedSeries, setSelectedSeries] = useState<string[]>([]);
    const [hoverSeries, setHoverSeries] = useState<string | null>(null);
    const legendRef = useRef<HTMLDivElement>(null);
    const svgRef = useRef<SVGSVGElement>(null);
    const innerSvg = useRef<SVGSVGElement>(null);
    const [legendComponent, setLegendComponent] = useState<ReactNode | undefined>(undefined);
    const colorAccessor = useColorAccessor({ chartId: id, hoverSeries, selectedSeries, groupAccessor });
    const { chartWidth, legendWidth } = useMemo(() => {
        const legendWidth = 180;
        const chartWidth = data.length > 1 ? Math.max(width - 180, 0) : Math.max(width, 0);
        return { chartWidth, legendWidth };
    }, [data, width]);

    // eslint-disable-next-line
    const [{ tooltipTop, tooltipLeft }, setTooltipPosition] = useState<{ tooltipTop: number; tooltipLeft: number }>({
        tooltipTop: 0,
        tooltipLeft: 0,
    });

    const dateFormat = useMemo(() => {
        const interval = axes?.bottom?.interval || '';
        if (typeof interval === 'string') {
            switch (lowerCase(interval)) {
                case 'month':
                    return 'LLL';
                case 'quarter':
                    return 'q';
                case 'year':
                    return 'y';
                default:
                    return 'D';
            }
        }
        return 'D';
    }, [axes?.bottom]);

    const tickFormatBottom: TickFormatter<any> = useCallback(
        (d: number | string) => {
            const formatDateFromMillis = (d: number, format: string) => {
                const prefix = axes?.bottom?.interval === 'quarter' ? 'Q' : '';
                return prefix + DateTime.fromMillis(d).toFormat(format);
            };

            if (axes?.bottom?.isDate && typeof d === 'number') {
                return formatDateFromMillis(d, dateFormat);
            }

            if (typeof d === 'string') {
                return d;
            }
        },
        [axes?.bottom, dateFormat]
    );

    useEffect(() => {
        if (!legendRef.current || !svgRef.current) {
            return;
        }

        const boxes = legendRef.current.querySelectorAll('.selectedSeriesBox');
        const svgBounds = svgRef.current.getBoundingClientRect();
        const components = Array.from(boxes).map((box) => {
            // @ts-ignore
            const [markerBlock, textBlock] = box.children;
            const markerBounds = markerBlock.getBoundingClientRect();
            const textBounds = textBlock.getBoundingClientRect();

            const { backgroundColor: markerBoundsBgColor, borderColor: markerBorderColor } =
                getComputedStyle(markerBlock);
            const { color } = getComputedStyle(textBlock);

            return (
                <>
                    <rect
                        x={markerBounds.x - svgBounds.x}
                        y={markerBounds.y - svgBounds.y}
                        width={markerBounds.width}
                        height={markerBounds.height}
                        rx={4}
                        ry={4}
                        fill={markerBoundsBgColor}
                        stroke={markerBorderColor}
                    />
                    <Text
                        x={textBounds.x - svgBounds.x}
                        y={textBounds.y - svgBounds.y + 6}
                        verticalAnchor="start"
                        fontSize={12}
                        fontWeight="light"
                        fill={color}
                    >
                        {textBlock.innerText}
                    </Text>
                </>
            );
        });

        setLegendComponent(<>{components}</>);
    }, [legendRef.current, selectedSeries, width]);

    const legendHeight = useMemo(() => {
        return Math.max(height - 98, 100);
    }, [height]);

    const bottomBarLabelFontSize = 11;
    const bottomBarLabelAngle = 45;

    const margin = useMemo(() => {
        const monospaceCorrelationCoef = 0.8;
        const bottomLabels = data
            .map((item) => {
                return item.data.map((innerItem) => {
                    return axes?.bottom?.isDate
                        ? DateTime.fromMillis(innerItem.label).toFormat(dateFormat)
                        : innerItem.label;
                });
            })
            .flat(1)
            .filter(Boolean);

        const longestLabelLength = Math.max(...bottomLabels.map((label) => label.length)) || 0;
        const letterWidth = bottomBarLabelFontSize * monospaceCorrelationCoef;
        const rad = (Math.PI / 180) * bottomBarLabelAngle;
        const longestLabelWidth = Math.cos(rad) * (letterWidth * longestLabelLength);
        const result = longestLabelWidth;

        const bottom = isNaN(result) ? 32 : Math.max(result, 32);

        return {
            top: 24,
            right: 40,
            bottom,
            left: 100,
        };
    }, [data, bottomBarLabelAngle, bottomBarLabelFontSize]);

    if (height < 10) {
        return null;
    }

    const xMax = chartWidth - margin.left - margin.right;

    const x0Scale = scaleBand<string>({
        domain: data.map((d) => d.label),
        padding: 0.2,
    });

    x0Scale.rangeRound([0, xMax]);

    const x1MaxDomain = maxBy(data, (d: any) => d.data.length).data.map((d) => d.label) || [];

    const x1MaxScale = scaleBand<string>({
        domain: x1MaxDomain,
        padding: 0.1,
    });

    x1MaxScale.rangeRound([0, x0Scale.bandwidth()]);

    const barWidth = x1MaxScale.bandwidth();

    const yScale = scaleLinear<number>({
        domain: domainY,
    });

    return (
        <div style={{ position: 'relative' }}>
            <ChartWrapper width={width} height={height} chartLabel={{ title, description }}>
                {({ chartTop, chartHeight }) => {
                    const yMax = chartHeight - margin.bottom - margin.top;
                    yScale.rangeRound([yMax, 0]);

                    return (
                        <>
                            <foreignObject y={chartTop} x={0} width={width} height={chartHeight}>
                                {legendComponent}
                                <svg
                                    className={CHART_CONTENT_CLASSNAME}
                                    style={{ position: 'relative' }}
                                    width={width}
                                    height={chartHeight}
                                    ref={innerSvg}
                                    onMouseMove={(e) => {
                                        const { x, y } = innerSvg.current
                                            ? innerSvg.current.getBoundingClientRect()
                                            : { x: 0, y: 0 };
                                        setTooltipPosition({ tooltipTop: e.clientY - y, tooltipLeft: e.clientX - x });
                                    }}
                                >
                                    <Gradients chartId={id} palette={defaultPalette} />
                                    <GridRows
                                        top={margin.top}
                                        left={margin.left}
                                        scale={yScale}
                                        width={xMax}
                                        height={yMax}
                                        stroke="#bdbdbd"
                                        strokeDasharray="1,3"
                                    />
                                    <Group top={margin.top} left={margin.left}>
                                        {data.map(({ key, label: seriesLabel, data }) => {
                                            const keys = data.map(({ label }) => label);
                                            const x1Width = barWidth * data.length + barWidth * 0.1 * (data.length - 1);
                                            const x1RangeMin = (x0Scale.bandwidth() - x1Width) / 2;
                                            const x1RangeMax = x1RangeMin + x1Width;
                                            const x1Scale = scaleBand<string>({
                                                domain: keys,
                                                range: [x1RangeMin, x1RangeMax],
                                                padding: 0.1,
                                            });

                                            return (
                                                <Group left={x0Scale(seriesLabel)}>
                                                    <Group>
                                                        {data.map(({ key, label, data }) => {
                                                            const barHeight = yMax - (yScale(data) ?? 0);
                                                            const barX = x1Scale(label);
                                                            const barY = yMax - barHeight;

                                                            return (
                                                                <Bar
                                                                    key={`bar-${key}`}
                                                                    x={barX}
                                                                    y={barY}
                                                                    width={barWidth}
                                                                    height={barHeight}
                                                                    fill={colorAccessor(seriesLabel)}
                                                                    onClick={() =>
                                                                        setSelectedSeries((prev) => {
                                                                            return prev.includes(seriesLabel)
                                                                                ? prev.filter((p) => p !== seriesLabel)
                                                                                : [...prev, seriesLabel];
                                                                        })
                                                                    }
                                                                    onMouseEnter={() => setHoverSeries(seriesLabel)}
                                                                    onMouseLeave={() => setHoverSeries(null)}
                                                                />
                                                            );
                                                        })}
                                                    </Group>
                                                    <AxisBottom
                                                        top={yMax}
                                                        hideAxisLine
                                                        tickFormat={tickFormatBottom}
                                                        scale={x1Scale}
                                                        numTicks={data.length}
                                                        tickLength={6}
                                                        tickStroke="#bdbdbd"
                                                        tickLabelProps={() => ({
                                                            fill: '#757575',
                                                            fontSize: bottomBarLabelFontSize,
                                                            textAnchor: 'end',
                                                            angle: -bottomBarLabelAngle,
                                                        })}
                                                    />
                                                </Group>
                                            );
                                        })}
                                        {xLines && (
                                            <Group>
                                                {xLines.map(({ value, color }) => (
                                                    <line
                                                        x1={0}
                                                        y1={yScale(value) as number}
                                                        x2={(width || 140) - 140}
                                                        y2={yScale(value) as number}
                                                        stroke={color}
                                                    />
                                                ))}
                                            </Group>
                                        )}
                                        <AxisLeft
                                            stroke="#bdbdbd"
                                            label={axes?.left?.label}
                                            labelProps={{
                                                fill: '#757575',
                                                fontSize: 11,
                                                textAnchor: 'middle',
                                            }}
                                            scale={yScale}
                                            tickStroke="#bdbdbd"
                                            tickLabelProps={() => ({
                                                fill: '#757575',
                                                fontSize: 11,
                                                textAnchor: 'end',
                                                verticalAnchor: 'middle',
                                            })}
                                        />
                                        <AxisTop
                                            hideAxisLine
                                            hideTicks
                                            tickFormat={formatLegendLabel}
                                            scale={x0Scale}
                                            stroke="#757575"
                                            tickLabelProps={() => ({
                                                fill: '#757575',
                                                fontSize: 11,
                                                textAnchor: 'middle',
                                            })}
                                        />
                                    </Group>
                                </svg>
                            </foreignObject>
                            <foreignObject y={chartTop} x={chartWidth} width={legendWidth} height={legendHeight}>
                                <div
                                    ref={legendRef}
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        zIndex: -1,
                                        opacity: 0,
                                        left: 0,
                                    }}
                                >
                                    <Stack
                                        spacing={1}
                                        sx={{
                                            overflow: 'auto',
                                            px: 2,
                                        }}
                                    >
                                        {selectedSeries.map((label) => {
                                            const activeIndex = indexOf(selectedSeries, label);
                                            const bgColor =
                                                activeIndex > -1
                                                    ? defaultPalette.marks.active[activeIndex]
                                                    : 'transparent';
                                            const borderColor =
                                                activeIndex > -1 ? defaultPalette.marks.active[activeIndex] : '#e9edf2';

                                            return (
                                                <SeriesLabel
                                                    key={label}
                                                    bgColor={bgColor}
                                                    borderColor={borderColor}
                                                    label={label}
                                                    formatLegendLabel={formatLegendLabel}
                                                    className="selectedSeriesBox"
                                                />
                                            );
                                        })}
                                    </Stack>
                                </div>
                                <Legend
                                    seriesLabels={data.map(({ label }) => label)}
                                    hoverSeries={hoverSeries}
                                    setHoverSeries={setHoverSeries}
                                    selectedSeries={selectedSeries}
                                    setSelectedSeries={setSelectedSeries}
                                    palette={defaultPalette.marks.active as string[]}
                                    height={legendHeight}
                                    width={legendWidth}
                                    formatLegendLabel={formatLegendLabel}
                                />
                            </foreignObject>
                        </>
                    );
                }}
            </ChartWrapper>
        </div>
    );
}
