import { Grid } from '@mui/material';
import { Navigate, Outlet } from 'react-router-dom';

import { Loader } from '../Loader';

export interface ProtectedRouteProps {
    isAuthenticated: boolean;
    isLoading: boolean;
    // redirectTo: string;
    // children?: React.ReactElement;
}

/*
export default function ProtectedRoute({ isAuthenticated, redirectTo = '/', children }: ProtectedRouteProps) {
    if (!isAuthenticated) {
        console.log('navigating');
        return <Navigate to={redirectTo} replace />;
    }

    return children ? children : <Outlet />;
}
*/

export default function ProtectedRoute({ isAuthenticated, isLoading }: ProtectedRouteProps) {
    return isLoading ? (
        <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
            <Grid item>
                <Loader />
            </Grid>
        </Grid>
    ) : isAuthenticated ? (
        <Outlet />
    ) : (
        <Navigate to="/login" replace />
    );
}
