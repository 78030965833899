import { Box, Grid, ListItemText, Typography } from '@mui/material';
import cn from 'classnames';
import { some } from 'lodash';

import { CourtCase, DefenseAttorney, Indictment } from '../../../../types';
import styles from '../../CourtCaseDialog.module.css';
import { listItemTextProps } from '../../index';
import { BailComponent } from './BailEventComponent';
import { IndictmentComponent } from './IndictmentComponent';
import { SentenceComponent } from './SentenceComponent';

interface DefendantsProps {
    courtCase: CourtCase | null;
}

export const DefendantsComponent = ({ courtCase }: DefendantsProps) => {
    return (
        <>
            <Typography variant="h6" color="primary" className={cn(styles.title, styles.boxHeader)}>
                Defendants
                <div className={styles.boxHeaderUnderLineInnerContainer}>
                    <Typography className={styles.boxHeaderTextUnderline} variant="h6" color="primary">
                        Defendants
                    </Typography>
                    <div className={styles.boxHeaderUnderline} />
                </div>
            </Typography>
            {courtCase?.indictments?.map(
                ({
                    id,
                    defendant,
                    arrestDate,
                    outcomeDate,
                    indictmentCharges,
                    baileventSet,
                    sentenceSet,
                    pleaBargain,
                    aggravatingFactors,
                    mitigatingFactors,
                }: Indictment) => {
                    const { firstName, lastName, birthdate, sex, nationality, streetAddress, defendantattorneySet } =
                        defendant;
                    const absconded = some(baileventSet, { absconded: true });
                    return (
                        <Box key={id} className={styles.item}>
                            <Grid container className={styles.itemTitleBlock}>
                                <Grid container>
                                    <Grid container>
                                        <Grid item xs={3} className={styles.defendantNameBlock}>
                                            <ListItemText
                                                className={cn(styles.subtitle, styles.defendantName)}
                                                primary={`${firstName} ${lastName} ${sex ? ` (${sex})` : ''}`.trim()}
                                                {...listItemTextProps}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <ListItemText
                                                className={styles.subtitle}
                                                primary={`Birthdate: ${birthdate || '--'}`}
                                                {...listItemTextProps}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <ListItemText
                                                className={styles.subtitle}
                                                primary={`Nationality: ${
                                                    nationality && nationality.map(({ name }) => name).join(', ')
                                                }`}
                                                {...listItemTextProps}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <ListItemText
                                                className={styles.subtitle}
                                                primary={`Address: ${streetAddress || '--'}`}
                                                {...listItemTextProps}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <ListItemText
                                                className={styles.subtitle}
                                                primary={`Arrest Date: ${arrestDate || '--'}`}
                                                {...listItemTextProps}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <ListItemText
                                                className={styles.subtitle}
                                                primary={`Outcome Date: ${outcomeDate || '--'}`}
                                                {...listItemTextProps}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <ListItemText
                                                className={styles.subtitle}
                                                primary={`Lawyers: ${
                                                    defendantattorneySet && defendantattorneySet.length > 0
                                                        ? defendantattorneySet.map(
                                                              ({
                                                                  defenseAttorney,
                                                                  lawFirm,
                                                                  isPrivate,
                                                              }: DefenseAttorney) => {
                                                                  const firm =
                                                                      lawFirm || (isPrivate && 'Private') || '';
                                                                  return `${defenseAttorney.firstName || ''} ${
                                                                      defenseAttorney.lastName || ''
                                                                  }` + firm
                                                                      ? ` (${firm})`
                                                                      : '';
                                                              }
                                                          )
                                                        : '--'
                                                }
                                                `}
                                                {...listItemTextProps}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container className={styles.defendantInfoContainer}>
                                    {absconded && (
                                        <Grid item xs>
                                            <ListItemText
                                                className={cn(styles.subtitle, styles.defendantInfo)}
                                                primary="Absconded"
                                                {...listItemTextProps}
                                            />
                                        </Grid>
                                    )}
                                    {pleaBargain && (
                                        <Grid item xs>
                                            <ListItemText
                                                className={cn(styles.subtitle, styles.defendantInfo)}
                                                primary="Plea Bargin"
                                                {...listItemTextProps}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                            <IndictmentComponent indictmentCharges={indictmentCharges} />
                            <BailComponent baileventSet={baileventSet} />
                            <SentenceComponent sentenceSet={sentenceSet} />
                            <Box className={styles.defendantsFooter}>
                                <Box className={styles.defendantsFooterBlock}>
                                    <Typography className={cn(styles.tableText, styles.defendantsFooterTitle)}>
                                        Aggravating Factor(s)
                                    </Typography>
                                    <Typography className={cn(styles.tableTextValue, styles.additionalText)}>
                                        {aggravatingFactors}
                                    </Typography>
                                </Box>
                                <Box className={styles.defendantsFooterBlock}>
                                    <Typography className={cn(styles.tableText, styles.defendantsFooterTitle)}>
                                        Mitigating Factor(s)
                                    </Typography>
                                    <Typography className={cn(styles.tableTextValue, styles.additionalText)}>
                                        {mitigatingFactors}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    );
                }
            )}
        </>
    );
};
