import { gql } from '@apollo/client';

const CREATE_FILTER_CONFIG = gql`
    mutation createFilterConfig($filterConfig: FilterConfigInput!) {
        createFilterConfig(filterConfig: $filterConfig) {
            filterConfig {
                id
                title
            }
        }
    }
`;

export default CREATE_FILTER_CONFIG;
