import { styled } from '@mui/material/styles';

const ChartRoot = styled('div')(({ theme }) => ({
    // padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 4,
    boxShadow: theme.shadows[2],
}));

export default ChartRoot;
