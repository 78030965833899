import { Button } from '@c4ads/c4blocks';
import { Box, Typography } from '@mui/material';

import { CustomDialog } from '../../../../../../components/CustomDialog';
import styles from './FormsHeader.module.css';

interface RemoveCaseFormModalProps {
    caseFormName?: string;
    open: boolean;
    onClose: () => void;
    loading: boolean;
    onRemove: () => void;
}

export const RemoveCaseFormModal = ({ caseFormName, open, loading, onClose, onRemove }: RemoveCaseFormModalProps) => {
    return (
        <CustomDialog
            maxWidth="xs"
            open={open}
            onClose={onClose}
            title={`Delete Case Form: ${caseFormName}`}
            loading={loading}
        >
            <Box display="flex" flexDirection="column" className={styles.removeContainer}>
                <Typography>
                    Deleting this case form will also delete any court cases, seizures, or other related data created
                    from this case form.
                </Typography>
                <Button color="secondary" variant="contained" onClick={onRemove}>
                    Remove
                </Button>
            </Box>
        </CustomDialog>
    );
};
