import { LoaderProps } from '..';
import { LoaderWrapper } from '../Loader';

interface FullScreenLoaderProps extends LoaderProps {
    className?: string;
}

export const FullScreenLoader = ({ className }: FullScreenLoaderProps) => {
    return <LoaderWrapper className={className} />;
};
