import { Box, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { z } from 'zod';

import { useFormContext } from '../../context/FormsContext';
import { useFormsStore } from '../../store';
import styles from '../Defendants/Defendants.module.css';
import { Seizure, schema } from './Seizure';

interface SeizuresFormProps {
    seizureFormKey?: string;
}

interface SeizureForm {
    key: string;
    payload?: z.infer<typeof schema>;
}

const preparePayload = (payload: any) => {
    if (!payload) {
        return [];
    }

    const currentPayload = payload as Record<string, z.infer<typeof schema>>;

    return Object.entries(currentPayload).map(([key, payload]) => {
        return {
            key,
            payload,
        };
    });
};

export const Seizures = ({ seizureFormKey }: SeizuresFormProps) => {
    const { setSectionStatus } = useFormContext();
    const payload = useFormsStore((state) => state.sectionForms.seizures?.payload);
    const setSectionForm = useFormsStore((state) => state.setSectionForm);
    const setSectionFormToRemove = useFormsStore((state) => state.setSectionFormToRemove);
    const [seizureForms, setSeizureForms] = useState<SeizureForm[]>(preparePayload(payload));
    const [errors, setErrors] = useState<Record<string, boolean>>({});
    const currentPayload = useRef(payload);

    useEffect(() => {
        const errorsCount = Object.values(errors).filter(Boolean).length;
        setSectionStatus('seizures', errorsCount === 0);
    }, [errors]);

    useEffect(() => {
        const keys = seizureForms.map((form) => form.key);

        setErrors((prev) => {
            const cloned = { ...prev };
            Object.keys(cloned).forEach((key) => {
                if (!keys.includes(key)) {
                    delete cloned[key];
                }
            });

            return cloned;
        });
    }, [seizureForms]);

    useEffect(() => {
        setSeizureForms(preparePayload(payload));
        currentPayload.current = payload;
    }, [payload]);

    useEffect(() => {
        if (seizureFormKey) {
            setSectionForm('seizures', { ...payload, [seizureFormKey]: {} });
        }
    }, [seizureFormKey]);

    const onChange = (key: string, result?: z.infer<typeof schema>) => {
        setSectionForm('seizures', { ...currentPayload.current, [key]: result });
    };

    const onDelete = (key: string) => {
        const clone = { ...currentPayload.current };
        if (clone?.id) {
            setSectionFormToRemove('seizures', clone.id);
        }

        delete clone[key];

        setSectionForm('seizures', clone);
    };

    return (
        <Box display="flex" flexDirection="column" className={styles.wrapper}>
            {!seizureForms.length && <Typography className={styles.noText}>No Seizures</Typography>}
            {seizureForms.map((item) => (
                <Seizure
                    key={item.key}
                    defaultValues={item.payload}
                    onChange={(value) => {
                        onChange(item.key, value);
                    }}
                    onDelete={() => {
                        onDelete(item.key);
                    }}
                    onValid={(isValid) => {
                        setErrors((prev) => {
                            return { ...prev, [item.key]: !isValid };
                        });
                    }}
                />
            ))}
        </Box>
    );
};
