import { gql } from '@apollo/client';

const GET_HEARINGS = gql`
    query hearings {
        hearings {
            id
            date
            happened
            stage
            courtCase {
                docket
            }
            court {
                name
                tier
                administrativeLevel1 {
                    id
                    name
                }
                region {
                    id
                    name
                }
            }
            judge {
                id
                firstName
                lastName
            }
            courtClerk {
                id
                firstName
                lastName
            }
            prosecutorsPresent {
                id
                firstName
                lastName
            }
            defenseAttorneysPresent {
                id
                firstName
                lastName
            }
            defendantsPresent {
                id
                firstName
                lastName
            }
            notes
        }
    }
`;

export default GET_HEARINGS;
