import { useQuery } from '@apollo/client';
import { TextField } from '@mui/material';
import {
    GridEventListener,
    GridFilterInputValueProps,
    GridFilterItem,
    GridFilterOperator,
    GridRowParams,
    useGridApiRef,
} from '@mui/x-data-grid-premium';
import { chain } from 'lodash';
import { usePostHog } from 'posthog-js/react';
import { ChangeEvent, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import GET_COURT_CASES from '../../../../api/apollo/query/GetCourtCases';
import { useAuth } from '../../../../api/auth';
import CourtCaseDialog from '../../../../components/CourtCaseDialog';
import { CustomDataGrid } from '../../../../components/CustomDataGrid';
import TableToolbar from '../../../../components/Table/TableToolbar';
import { DefaultTableProps } from '../../../../components/Table/types';
import { captureViewCourtCase } from '../../../../posthog';
import { CourtCaseFilters } from '../../../../types/CourtCaseFilters';
import { AdvancedFilterComponent } from '../AdvancedFilter';
import columns from './columns';

const CommoditiesFilterInput = (props: GridFilterInputValueProps) => {
    const { item, applyValue } = props;

    const handleOnChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        applyValue({ ...item, value: e.target.value });
    };

    return (
        <TextField
            InputLabelProps={{ shrink: true }}
            label="Value"
            placeholder="Filter value"
            autoFocus={true}
            variant="standard"
            onChange={handleOnChange}
        />
    );
};

const commoditiesFilters: GridFilterOperator[] = [
    {
        label: 'contains',
        value: 'contains',
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
                return null;
            }

            return (params): boolean => {
                return (
                    params.value.filter(
                        (item) => item.name.toLowerCase().indexOf(filterItem.value.toLowerCase()) !== -1
                    ).length > 0
                );
            };
        },
        InputComponent: CommoditiesFilterInput,
        getValueAsString: (value: number) => `${value} Stars`,
    },
];

const CourtCases = ({ shrinkTitle, hideHeaders, showHeaders }: DefaultTableProps) => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const posthog = usePostHog();
    const [rows, setRows] = useState<any[]>([]);
    const [advancedFilterOpen, setAdvancedFilterOpen] = useState<boolean>(false);
    const [advancedFilterActive, setAdvancedFilterActive] = useState<boolean>(false);

    const [openCourtCaseId, setOpenCourtCaseId] = useState<string | null>(null);

    const [filters, setFilters] = useState<CourtCaseFilters>({});

    const { loading } = useQuery(GET_COURT_CASES, {
        variables: { filters: filters },
        onCompleted: ({ courtCases }) => setRows(courtCases),
    });

    useEffect(() => {
        if (openCourtCaseId) {
            const { eventName, payload } = captureViewCourtCase(openCourtCaseId);
            posthog?.capture(eventName, payload);
        }
    }, [openCourtCaseId, posthog]);

    const apiRef = useGridApiRef();

    const girdColumns = useMemo(
        () =>
            columns.map((col) =>
                col.field === 'commodities'
                    ? {
                          ...col,
                          filterOperators: commoditiesFilters,
                      }
                    : col
            ),
        [columns]
    );

    useEffect(() => {
        setAdvancedFilterActive(Object.keys(filters).length !== 0);
    }, [filters]);

    useEffect(() => {
        const handleEvent: GridEventListener<'rowsScroll'> = (params) => {
            params.top > 5 ? hideHeaders() : showHeaders();
        };

        apiRef.current.subscribeEvent('rowsScroll', handleEvent);
    }, [apiRef, hideHeaders, showHeaders]);

    const onAdvancedFilterClick = () => {
        setAdvancedFilterOpen(true);
    };

    const onRowClick = useCallback(
        (params: GridRowParams) => {
            const canViewDetails = user?.permissions?.map(({ codename }) => codename).includes('view_details');
            const latestApprovedCaseForm = chain(params.row.caseForms)
                .filter(({ status }) => status === 'Approved')
                .maxBy('version')
                .value();

            if (canViewDetails && latestApprovedCaseForm) {
                navigate(`/case-forms/${latestApprovedCaseForm.id}`);
                // setOpenCourtCaseId(params.row.id);
            }
        },
        [user]
    );

    return (
        <Fragment>
            <CustomDataGrid
                apiRef={apiRef}
                columns={girdColumns}
                rows={rows}
                onRowClick={onRowClick}
                checkboxSelection={false}
                loading={loading}
                hideFooterSelectedRowCount
                hideFooterPagination
                components={{ Toolbar: TableToolbar }}
                componentsProps={{
                    toolbar: {
                        title: 'Court Cases',
                        showTitle: shrinkTitle,
                        onAdvancedFilterClick,
                        advancedFilterActive,
                        csvExportEnabled:
                            !loading &&
                            user?.permissions?.map(({ codename }) => codename).includes('can_export_tables'),
                        tableApi: apiRef.current,
                    },
                }}
            />
            <AdvancedFilterComponent
                onFilters={setFilters}
                open={advancedFilterOpen}
                onOpenChange={setAdvancedFilterOpen}
            />
            <CourtCaseDialog openCourtCaseId={openCourtCaseId} setOpenCourtCaseId={setOpenCourtCaseId} />
        </Fragment>
    );
};

export default CourtCases;
