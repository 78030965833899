import { Box } from '@mui/material';
import cn from 'classnames';
import { PropsWithChildren, ReactNode, useEffect } from 'react';

import { useUUID } from '../../../../../../../hooks/useUUID';
import { DeleteButton } from '../../DeleteButton';
import { FormSeparator } from '../../FormSeparator';
import { FormRow } from '../FormRow';
import styles from './MultiFormTemplate.module.css';

interface MultiFormTemplateProps {
    header: ReactNode;
    headerButtonIsAbsolute?: boolean;
    formBlocks: ReactNode[];
    notes?: ReactNode;
    editMode?: boolean;
    onDelete?: () => void;
    hideDeleteButton?: boolean;
    hideNotesSeparator?: boolean;
    FormBlockWrapper?: (props: PropsWithChildren) => JSX.Element;
    applyFormBlockWrapperTo?: number[]; // indexes of form blocks, works only with FormBlockWrapper
}

export const MultiFormTemplate = ({
    header,
    headerButtonIsAbsolute,
    formBlocks,
    notes,
    editMode,
    hideDeleteButton,
    onDelete,
    hideNotesSeparator,
    FormBlockWrapper,
    applyFormBlockWrapperTo,
}: MultiFormTemplateProps) => {
    const [formBlockKeys, setFormBlocksCount] = useUUID(formBlocks.length);

    useEffect(() => {
        setFormBlocksCount(formBlocks.length);
    }, [formBlocks.length]);

    return (
        <>
            <Box display="flex" flexDirection="column" className={styles.headerWrapper}>
                <Box display="flex" justifyContent="space-between" className={styles.headerContainer}>
                    <Box display="flex" flexWrap="wrap" className={styles.headerContainerInner}>
                        {header}
                    </Box>
                    {editMode && !hideDeleteButton && (
                        <DeleteButton
                            className={cn(headerButtonIsAbsolute && styles.absoluteDeleteButton)}
                            onClick={onDelete}
                        />
                    )}
                </Box>
                <FormSeparator />
            </Box>
            <Box display="flex" flexDirection="column" className={styles.wrapper}>
                {formBlocks.map((formBlock, index) => {
                    if (FormBlockWrapper) {
                        if (applyFormBlockWrapperTo) {
                            return applyFormBlockWrapperTo.includes(index) ? (
                                <FormBlockWrapper key={formBlockKeys[index]}>{formBlock}</FormBlockWrapper>
                            ) : (
                                <FormRow key={formBlockKeys[index]}>{formBlock}</FormRow>
                            );
                        }

                        return <FormBlockWrapper key={formBlockKeys[index]}>{formBlock}</FormBlockWrapper>;
                    }

                    return <FormRow key={formBlockKeys[index]}>{formBlock}</FormRow>;
                })}
                {notes && (
                    <>
                        {!hideNotesSeparator && <FormSeparator />}
                        <Box className={styles.formBlock} display="flex">
                            <Box className={styles.notesWrapper}>{notes}</Box>
                        </Box>
                    </>
                )}
            </Box>
            <FormSeparator bold />
        </>
    );
};
