import { ApolloClient, InMemoryCache, createHttpLink, from, fromPromise } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { Permission, requestAccessToken } from '@c4ads/c4auth';
import { NavigationRouteProps } from '@c4ads/c4blocks/src/types/NavigationRouteProps';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GavelIcon from '@mui/icons-material/Gavel';
import ListAltIcon from '@mui/icons-material/ListAlt';
import TableViewIcon from '@mui/icons-material/TableView';

import { TOKEN } from '../../constants/localStorageConstants';
import ArrestsIcon from '../../static/icons/component/ArrestsIcon';
import RhinoHornIcon from '../../static/icons/component/RhinoHornIcon';
import { User } from '../../types/user';

const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URI || '/graphql',
});

const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem(TOKEN);
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        },
    };
});

const permissionCheck = onError(({ graphQLErrors, operation, forward }) => {
    if (operation.operationName === 'user') {
        return forward(operation);
    }

    if (!graphQLErrors) {
        return forward(operation);
    }

    if (!graphQLErrors.find((item) => item.message.toLowerCase().indexOf('permission denied') !== -1)) {
        return forward(operation);
    }

    const logout = () => {
        localStorage.removeItem(TOKEN);
        document.location.href = '/login';
    };

    return fromPromise(
        requestAccessToken(process.env.REACT_APP_REQUEST_ACCESS_TOKEN_URI)
            .then((accessToken) => {
                accessToken ? localStorage.setItem(TOKEN, accessToken) : logout();

                return accessToken;
            })
            .catch(logout)
    )
        .filter((value) => Boolean(value))
        .flatMap(() => {
            return forward(operation);
        });
});

const cache = new InMemoryCache({
    // addTypename: false,
});

const resolvers = {
    User: {
        availableRoutes: (user: User) => {
            const TABLE_VIEWS = {
                //matches the permission id from Django models
                view_courtcase: {
                    label: 'Court Cases',
                    icon: <GavelIcon />,
                    href: '/summary-tables/court-cases',
                    disabled: false,
                },
                view_defendant: {
                    label: 'Defendants',
                    icon: <ArrestsIcon />,
                    href: '/summary-tables/defendants',
                    disabled: false,
                },
                view_seizedcommodity: {
                    label: 'Seized Commodities',
                    icon: <RhinoHornIcon />,
                    href: '/summary-tables/seized-commodities',
                    disabled: false,
                },
                view_seizure: {
                    label: 'Seizures',
                    icon: <CrisisAlertIcon />,
                    href: '/summary-tables/seizures',
                    disabled: false,
                },
                view_hearing: {
                    label: 'Hearings',
                    icon: <CrisisAlertIcon />,
                    href: '/summary-tables/hearings',
                    disabled: false,
                },
                view_indictmentcharge: {
                    label: 'Charges',
                    icon: <CrisisAlertIcon />,
                    href: '/summary-tables/charges',
                    disabled: false,
                },
            };

            const routePermissions = [
                'view_courtcase',
                'view_defendant',
                'view_seizedcommodity',
                'view_seizure',
                'view_hearing',
                'view_indictmentcharge',
            ];

            const summaryToolRoutes = user.permissions
                ?.filter(({ codename }) => routePermissions.includes(codename))
                .map(({ codename }: Permission) => {
                    return TABLE_VIEWS[codename];
                });

            return [
                {
                    label: 'Dashboards',
                    icon: <DashboardIcon />,
                    href: '/dashboards',
                    disabled: false,
                    routes: [],
                    menuTrigger: 'click',
                },
                {
                    label: 'Summary Tables',
                    icon: <TableViewIcon />,
                    href: '/summary-tables/:table',
                    routes: summaryToolRoutes,
                    disabled: false,
                    menuTrigger: 'click',
                },
                user.permissions?.map((permission) => permission.codename).includes('case_form_editor') && {
                    label: 'Case Forms',
                    icon: <ListAltIcon />,
                    href: '/case-forms/:id',
                    routes: [],
                    disabled: false,
                    menuTrigger: 'click',
                },
            ].filter(Boolean) as NavigationRouteProps[];
        },
    },
};

const apolloClient = new ApolloClient({
    link: from([authLink, permissionCheck, httpLink]),
    cache: cache,
    resolvers: resolvers,
});

export default apolloClient;
