import * as _ from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';

import { DataSeries } from './types';

const isDate = (d: any): boolean => {
    if (_.isDate(d)) {
        return true;
    }
    const parsed = Date.parse(d);
    return !isNaN(parsed);
};

const parseDate = (d: any) => {
    return isDate(d) ? DateTime.fromISO(d).toMillis() : d;
};

export default function useChartData(rawData: any | any[], activeMark: string, groupAccessor: (key: string) => string) {
    const [keys, setKeys] = useState<number[] | string[]>([]);
    const [chartData, setChartData] = useState<DataSeries | null>(null);

    useEffect(() => {
        const newKeys = rawData.map((d: any) => d.key);
        const newData = rawData.map((d: any) => ({
            ...d,
            data: d.data.map((dd: any) => ({ ...dd, group: groupAccessor(dd.key), label: parseDate(dd.label) })),
        }));
        setKeys(newKeys);
        setChartData(newData);
    }, [rawData, activeMark, setKeys, setChartData]);

    return {
        keys,
        chartData,
    };
}
