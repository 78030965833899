import { Font, StyleSheet } from '@react-pdf/renderer';

// @ts-ignore
import InterBold from '../../../static/fonts/Inter-Bold.ttf';
// @ts-ignore
import Inter from '../../../static/fonts/Inter-Regular.ttf';

Font.register({
    family: 'Inter',
    src: Inter,
});
Font.register({
    family: 'InterBold',
    src: InterBold,
});

export const styles = StyleSheet.create({
    page: {
        paddingHorizontal: 64,
        paddingVertical: 32,
    },

    header: {
        paddingVertical: 32,
        marginBottom: 24,
    },

    headerText: {
        margin: 0,
        fontWeight: 700,
        fontFamily: 'InterBold',
        fontSize: 32,
        lineHeight: 1.334,
        color: '#122945',
    },

    itemWrapperWithMargin: {
        marginBottom: 8,
    },

    itemWrapper: {
        padding: 4,
        border: '1px solid #e0e0e0',
        borderRadius: 8,
    },

    itemFooter: {
        borderRadius: 8,
        border: '1px solid #e0e0e0',
    },

    itemAdditionalInfo: {
        display: 'flex',
        padding: 4,
        gap: 4,
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
    },

    itemAdditionalInfoWithoutBorder: {
        borderBottom: 'none',
    },

    itemContainer: {
        marginBottom: 32,
    },

    itemHeader: {
        margin: 0,
        fontWeight: 700,
        fontFamily: 'InterBold',
        fontSize: 24,
        lineHeight: 1.6,
        color: '#122945',
    },

    itemHeaderWrapper: {
        marginBottom: 16,
    },

    itemHeaderContainer: {
        position: 'relative',
    },

    boxHeaderUnderLineInnerContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
    },

    boxHeaderTextUnderline: {
        backgroundColor: '#f75151',
        color: '#f75151',
        lineHeight: 0.2,
        overflow: 'hidden',
    },

    boxHeaderUnderline: {
        height: '1px',
        flex: 1,
        backgroundColor: '#e9edf2',
    },

    defendantItem: {
        display: 'flex',
        flexDirection: 'column',
    },
    itemContent: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 0,
        gap: 4,
    },
    itemContentCaseDetails: {
        paddingVertical: 0,
        width: '25%',
        flexWrap: 'nowrap',
    },
    itemContentCol: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        paddingVertical: 8,
        paddingHorizontal: 4,
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
    },

    table: {
        marginBottom: 0,
    },

    seizureTable: {
        marginBottom: 0,
    },

    tableHead: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        borderBottom: '1px solid #e0e0e0',
    },
    tableBody: {
        display: 'flex',
        flexDirection: 'column',
    },
    tableRow: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        borderBottom: '1px solid #e0e0e0',
    },
    tableRowWithoutBorder: {
        borderBottom: 'none',
    },
    tableCell: {
        paddingVertical: 8,
    },

    cellText: {
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: 18,
        lineHeight: 1.5,
        textAlign: 'left',
        padding: 8,
        paddingLeft: 16,
    },
    cellTextCaseDetails: {
        paddingLeft: 0,
    },

    contentHeaderText: {
        color: '#525F72',
        textTransform: 'uppercase',
        fontSize: 16,
        fontFamily: 'Inter',
        fontWeight: 500,
        lineHeight: 1.2,
        textAlign: 'left',
        paddingHorizontal: 16,
        paddingVertical: 4,
    },
    contentHeaderTextCaseDetails: {
        paddingHorizontal: 0,
    },

    itemBlock: {
        borderRadius: '8px',
        border: '1px solid #e9edf2',
        overflow: 'hidden',
        marginBottom: 4,
    },

    seizureItemContentCol: {
        justifyContent: 'flex-start',
        paddingLeft: 16,
    },

    itemTitleBlock: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#e6ecef',
        width: '100%',
    },

    subtitle: {
        fontWeight: 700,
        color: '#122945',
        paddingHorizontal: 0,
    },

    defendantInfo: {
        padding: 4,
        lineHeight: 1,
        backgroundColor: '#ff9797',
        borderRadius: 4,
    },

    defendantInfoContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        gap: 8,
        padding: 4,
    },

    defendantItemText: {
        paddingBottom: 8,
        paddingLeft: 8,
    },

    defendantsHeaderLine: {
        width: '100%',
        height: 1,
        backgroundColor: 'rgba(224, 224, 224, 1)',
    },

    defendantsItem: {
        marginHorizontal: 14,
    },

    defendantsTableText: {
        fontWeight: 900,
    },
    defendantsTableTextBlock: {
        maxWidth: 100,
    },

    defendantsHeader: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: 4,
        paddingTop: 8,
    },

    rowsBlockWrapper: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        marginTop: 8,
    },

    rowsBlockWrapperWithoutBorder: {
        borderBottom: 'none',
    },

    cellItem: {
        display: 'flex',
        flexDirection: 'column',
    },

    cellTextValue: {
        color: 'black',
    },

    defendantFooter: {},
    defendantFooterBlock: {
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        padding: '8px 2px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 4,
        marginLeft: 8,
    },
    defendantFooterText: {
        paddingHorizontal: 4,
    },
});
