import { DataGrid } from '@c4ads/c4blocks';
import { GridColDef, GridSortModel, GridValidRowModel } from '@mui/x-data-grid-premium';
import { useEffect, useState } from 'react';

import { ColumnHeaderSortIcon } from './ColumnHeaderSortIcon';
import { Pagination, TablePagination } from './Pagination';
import styles from './Table.module.css';

interface CustomTableProps<T> {
    columns: GridColDef[];
    rows: T[];
    className?: string;
    loading?: boolean;
    pagination: {
        pageSize: number;
        page?: number;
        onPage?: (currentPage: number) => void;
        onPageSize?: (pageSize: number) => void;
    };
    onRowClick?: (e: T) => void;
    tablePagination?: boolean;
    sortModel?: GridSortModel;
    onSortModelChange?: (sortModel: GridSortModel) => void;
}

export const CustomTable = <T,>({
    columns,
    rows,
    loading,
    pagination,
    onRowClick,
    tablePagination,
    sortModel,
    onSortModelChange,
}: CustomTableProps<T>) => {
    const [page, setPage] = useState(pagination.page);
    const [pageSize, setPageSize] = useState(pagination.pageSize);

    useEffect(() => {
        if (pagination.page !== undefined) {
            setPage(pagination.page);
        }
    }, [pagination]);

    return (
        <DataGrid
            autoHeight
            onRowClick={(e) => {
                onRowClick?.(e.row);
            }}
            pageSize={pageSize}
            page={page}
            onPageChange={(newPage) => {
                pagination?.onPage?.(newPage);
                setPage(newPage);
            }}
            onPageSizeChange={(newPageSize) => {
                pagination?.onPageSize?.(newPageSize);
                setPageSize(newPageSize);
            }}
            pagination
            paginationMode="client"
            className={styles.table}
            columns={columns}
            rowHeight={42}
            rows={rows as GridValidRowModel[]}
            checkboxSelection={false}
            loading={loading}
            hideFooterSelectedRowCount
            disableVirtualization
            components={{
                ColumnSortedAscendingIcon: () => <ColumnHeaderSortIcon direction="asc" />,
                ColumnSortedDescendingIcon: () => <ColumnHeaderSortIcon direction="desc" />,
                Pagination: tablePagination ? TablePagination : Pagination,
            }}
            sortModel={sortModel}
            onSortModelChange={onSortModelChange}
        />
    );
};
